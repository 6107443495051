import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { getPermLevel, setAccessToken } from "../accessToken";
import { useLoginMutation } from "../generated/graphql";
import { useSnackbar } from "notistack";
import { setHeight } from "../setHeight";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("username Required"),
  password: Yup.string().min(2, "Password too short!").required("Password Required"),
});

export default function Login() {
  const [openPage, setOpenPage] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [doLogin] = useLoginMutation({ errorPolicy: "all" });
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, actions) => {
      try {
        const response = await doLogin({
          variables: {
            username: values.username,
            password: values.password,
          },
        });
        if (response.errors) {
          enqueueSnackbar(response.errors[0].message, { variant: "error" });
          actions.setSubmitting(false);
        } else {
          if (response && response.data) {
            setAccessToken(response.data.login.accessToken);
          }
          actions.setSubmitting(false);
          enqueueSnackbar("Login successful", { variant: "success" });
          setOpenPage(false);
          const permLevel = getPermLevel();
          if (permLevel === false) {
            navigate("/login");
          } else {
            setShowProgress(true);
            if (permLevel === 1) setTimeout(() => navigate("/su/trainer/class/manage"), 500);
            if (permLevel === 2) setTimeout(() => navigate("/su/admin/class/manage"), 500);
          }
        }
      } catch {
        actions.resetForm();
      }
    },
  });
  const permLevel = getPermLevel();

  useEffect(() => {
    setHeight();
    const permLevel = getPermLevel();
    if (permLevel !== false) {
      if (permLevel === 1) window.location.href = "/su/trainer/class/manage";
      if (permLevel === 2) window.location.href = "/su/admin/class/manage";
    }
  }, []);

  return (
    <>
      {permLevel === false || permLevel === 0 ? (
        <>
          <Box
            className='fullHeightContainerBox'
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
            }}
          >
            <Zoom in={openPage}>
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Container maxWidth='sm'>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item xs={12} md={8}>
                      <Card sx={{ marginBottom: "40px", borderRadius: "15px" }}>
                        <CardContent>
                          <Typography
                            variant='h4'
                            sx={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}
                            gutterBottom
                          >
                            Admin Log in
                          </Typography>
                          <form onSubmit={formik.handleSubmit}>
                            <Grid
                              container
                              spacing={3}
                              sx={{ paddingRight: "10px", paddingLeft: "10px" }}
                            >
                              <Grid item xs={12}>
                                <TextField
                                  required
                                  fullWidth
                                  id='username'
                                  name='username'
                                  label='Username'
                                  value={formik.values.username}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={formik.touched.username && Boolean(formik.errors.username)}
                                  helperText={formik.touched.username && formik.errors.username}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  required
                                  fullWidth
                                  id='password'
                                  name='password'
                                  type='password'
                                  label='Password'
                                  value={formik.values.password}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={formik.touched.password && Boolean(formik.errors.password)}
                                  helperText={formik.touched.password && formik.errors.password}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant='contained'
                                  type='submit'
                                  color='primary'
                                  sx={{ width: "100%" }}
                                >
                                  Login
                                </Button>
                              </Grid>
                              <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <Link component={RouterLink} to='/'>
                                  <Typography>Forgot password?</Typography>
                                </Link>
                              </Grid>
                            </Grid>
                          </form>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs></Grid>
                  </Grid>
                </Container>
              </Box>
            </Zoom>
          </Box>

          <Box
            className='fullHeightContainerBox'
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              zIndex: -100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {showProgress ? <LinearProgress sx={{ width: "90vw" }} /> : <></>}
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
