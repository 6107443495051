import { Box, Button, CircularProgress, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {
  useGetOneClassQuery,
  useGetRankingsQuery,
  Exact,
  GetGameStageQuery,
  usePrevStageMutation,
} from "../../generated/graphql";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSnackbar } from "notistack";
import { QueryResult } from "@apollo/client/react/types/types";
//import ListAltIcon from "@mui/icons-material/ListAlt";
import { motion } from "framer-motion";

export default function EndScreenTrainer({
  classId,
  getGameStage,
}: {
  classId: number;
  getGameStage: QueryResult<
    GetGameStageQuery,
    Exact<{
      getGameStageId: number;
    }>
  >;
}) {
  const getClassQuery = useGetOneClassQuery({
    errorPolicy: "all",
    variables: { getClassId: classId },
  });
  const getRankings = useGetRankingsQuery({
    errorPolicy: "all",
    variables: { classId },
  });
  const [doPrevStage] = usePrevStageMutation();
  const { enqueueSnackbar } = useSnackbar();

  const RenderRankings = () => {
    if (getRankings.data && getRankings.data.getRankings) {
      let result: JSX.Element[] = [];
      const userData = getRankings.data.getRankings;
      for (let i = 0; i < userData.length; i++) {
        const user = userData[i];
        let scale = 0.9;
        let pb = "0";
        let y = 0;
        if (i === 0) {
          scale = 1;
          pb = "10px";
        }
        y = i * -20;
        result.push(
          <motion.div
            variants={{
              hidden: { scale, y, x: -100, opacity: 0 },
              shown: { x: 0, scale, y, opacity: 1 },
            }}
            key={i}
            style={{ paddingBottom: pb }}
          >
            <Grid container sx={{ width: "100%" }} spacing={3}>
              <Grid item xs={2}>
                <Box
                  sx={{
                    border: "1px solid",
                    borderRadius: "20px",
                    borderColor: "secondary.main",
                    width: "100%",
                    aspectRatio: "1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant='h1' fontWeight={400}>
                    {i + 1}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={10}>
                <motion.div
                  style={{ height: "100%", width: "100%" }}
                  variants={{
                    hidden: { scale: 0 },
                    shown: { scale: 1, transition: { delay: i * 3 + 4 } },
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "20px",
                      backgroundColor: "secondary.main",
                      color: "secondary.contrastText",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingX: "30px",
                      }}
                    >
                      <Stack direction='column' sx={{ width: "100%" }} spacing={1}>
                        <Stack
                          direction='row'
                          justifyContent='flex-start'
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography variant='h3' fontWeight='500'>
                            {user.username}
                          </Typography>
                          {/* 
                          <Box>
                            <Button
                              variant='outlined'
                              size='small'
                              sx={{ marginRight: "60px", paddingX: "10px" }}
                              endIcon={<ListAltIcon />}
                            >
                              View Stats
                            </Button>
                          </Box> */}
                        </Stack>
                        <Grid container sx={{ width: "100%" }}>
                          <Grid item xs={4}>
                            <Stack direction='column'>
                              <Typography variant='h6'>Credit Card Balance</Typography>
                              <Typography variant='h4'>${user.finalCCBal}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={4}>
                            <Stack direction='column'>
                              <Typography variant='h6'>Savings Balance</Typography>
                              <Typography variant='h4'>${user.finalSavings}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant='h6'>Total Interest</Typography>
                            <Typography variant='h4'>${user.totalInterest}</Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Box>
                  </Box>
                </motion.div>
              </Grid>
            </Grid>
          </motion.div>
        );
      }
      return result;
    }
    return <></>;
  };

  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        minHeight: "Calc(100vh - 64px);",
      }}
    >
      {getClassQuery && getClassQuery.data ? (
        <>
          <Container>
            <motion.div
              initial='hidden'
              animate='shown'
              variants={{
                hidden: { y: -100, opacity: 0 },
                shown: { y: 0, opacity: 1 },
              }}
              transition={{ staggerChildren: 3, delayChildren: 2 }}
            >
              <Stack direction='column' spacing={3}>
                <Typography variant='h2' sx={{ fontWeight: 400 }} align='center'>
                  Leaderboard
                </Typography>
                {RenderRankings()}
              </Stack>
            </motion.div>
          </Container>
          <Box sx={{ position: "fixed", bottom: "0", left: "0" }}>
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              startIcon={<ArrowBackIosNewIcon />}
              sx={{ margin: "0px 0px 25px 30px" }}
              onClick={async () => {
                const response = await doPrevStage({
                  variables: { prevStageId: classId },
                });
                if (response.data && response.data.prevStage) {
                  enqueueSnackbar("Successfully changed stage", {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar("Error changing stage", {
                    variant: "error",
                  });
                }
                getGameStage.refetch();
              }}
            >
              Back
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100vw",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      )}
    </Box>
  );
}
