import React from "react";
import { useImReadyMutation } from "../../generated/graphql";
import { motion } from "framer-motion";
import { Box, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import HeightIcon from "@mui/icons-material/Height";

export default function S01Sam() {
  const [imReady] = useImReadyMutation();
  const year = new Date().getFullYear();

  const [samOpacity, setsamOpacity] = React.useState(1);
  const [scrollable, setScrollable] = React.useState(false);

  const checkOverlap = () => {
    const scrollableDiv = document.getElementsByClassName("textDivMain")[0];
    const samImage = document.getElementsByClassName("samImageMain")[0];
    const container = document.getElementsByClassName("containerBoxMain")[0];

    if (scrollableDiv.getBoundingClientRect().bottom > samImage.getBoundingClientRect().top) {
      setsamOpacity(0.2);
    }
    if (scrollableDiv.scrollHeight > container.getBoundingClientRect().height) {
      setScrollable(true);
    }
  };

  const scrollToBottom = () => {
    const scrollableDiv = document.getElementsByClassName("textDivMain")[0];
    scrollableDiv.scrollTo({ top: scrollableDiv.scrollHeight, behavior: "smooth" });
    checkOverlap();
  };

  const itemVariants = {
    visible: { opacity: 1, y: 0, display: "block" },
    hidden: { opacity: 0, y: 50, display: "none" },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
      className='containerBoxMain'
    >
      <Container
        sx={{ paddingTop: "20px", paddingBottom: "55px", overflow: "scroll", maxHeight: "100%" }}
        className='textDivMain'
      >
        <motion.div
          animate={{ scale: [0, 1.5, 1.5, 1] }}
          transition={{ duration: 2, times: [0, 0.125, 0.75, 1] }}
        >
          <Typography variant='h2' align='center' gutterBottom>
            This is sam
          </Typography>
        </motion.div>
        <Divider />
        <motion.div
          initial='hidden'
          animate='visible'
          transition={{ delayChildren: 2, staggerChildren: 2.5 }}
        >
          <Stack direction='column' sx={{ marginTop: "15px" }} spacing={2}>
            <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
              <Typography variant='h4' align='left'>
                It is now end November {year}.
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
              <Typography variant='h4' align='left'>
                Sam has just started working at the start of November.
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
              <Box sx={{ aspectRatio: "1.6", backgroundColor: "#ddd", borderRadius: "20px" }}>
                <Box
                  sx={{
                    padding: "20px 30px 20px 30px",
                    display: "flex",
                    alignItems: "center",
                    height: "calc(100% - 40px)",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='h6' fontWeight='500'>
                        MickeySoft Pte. Ltd.
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <img
                        src='/game/1samHead.png'
                        alt=''
                        style={{ width: "100%", paddingTop: "20px" }}
                      />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={6}>
                      <Typography variant='h4' sx={{ paddingTop: "20px" }}>
                        Sam Tan
                      </Typography>
                      <Typography variant='h6' sx={{ paddingTop: "10px" }}>
                        Staff ID:
                      </Typography>
                      <Typography variant='h5'>{Math.floor(Math.random() * 9999)}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </motion.div>

            <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
              <Typography variant='h4' align='left'>
                He is earning a gross income of $2,500 a month.
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
              <Typography variant='h4' align='left'>
                His take-home income is only $2,000 a month.
              </Typography>
            </motion.div>

            <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
              <Typography variant='h4' align='left'>
                He has the following regular monthly expenses:
              </Typography>
            </motion.div>

            <motion.div
              variants={itemVariants}
              transition={{ staggerChildren: 0.5 }}
              onAnimationComplete={scrollToBottom}
            >
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  padding: "10px 15px 10px 15px",
                  boxShadow: "3",
                  borderRadius: "10px",
                }}
              >
                <Stack direction='column' spacing={1}>
                  <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
                    <Typography variant='h5' align='left'>
                      Handphone bill - $50
                    </Typography>
                  </motion.div>
                  <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
                    <Typography variant='h5' align='left'>
                      Transport - $200
                    </Typography>
                  </motion.div>
                  <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
                    <Typography variant='h5' align='left'>
                      Food - $450
                    </Typography>
                  </motion.div>
                  <motion.div variants={itemVariants} onAnimationComplete={scrollToBottom}>
                    <Typography variant='h5' align='left'>
                      Parent's allowance - $200 (fixed)
                    </Typography>
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <Typography variant='h5' align='left'>
                      University study loan to pay off per month - $800 (fixed)
                    </Typography>
                  </motion.div>
                  <motion.div
                    variants={itemVariants}
                    onAnimationComplete={() => {
                      scrollToBottom();
                      imReady();
                    }}
                  >
                    <Typography variant='h5' align='left'>
                      Total - $1700
                    </Typography>
                  </motion.div>
                </Stack>
              </Box>
            </motion.div>
          </Stack>
        </motion.div>
      </Container>
      <motion.div animate={{ opacity: samOpacity }}>
        <motion.img
          className='samImageMain'
          src='/game/1sam.png'
          style={{
            zIndex: "-100",
            position: "absolute",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
          }}
          animate={{
            bottom: [-540, -5, -5, -200],
            right: [0, 0, 0, -250],
            scale: [1, 1, 1, 0.4],
          }}
          transition={{ duration: 2, times: [0, 0.125, 0.75, 1] }}
        />
      </motion.div>
      {scrollable ? (
        <Box
          sx={{
            borderRadius: "10px",
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            padding: "5px 5px 5px 10px",
            margin: "0 0 5px 5px",
            position: "fixed",
            bottom: "0",
            left: "0",
            opacity: "0.6",
          }}
        >
          <Stack direction='row' alignItems='center'>
            <Typography variant='h6' sx={{ width: "auto" }}>
              Scroll
            </Typography>
            <motion.div animate={{ y: [2, -2, 2] }} transition={{ duration: 2, repeat: Infinity }}>
              <HeightIcon fontSize='small' sx={{ color: "primary.contrastText" }} />
            </motion.div>
          </Stack>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
