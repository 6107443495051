import {
  Box,
  Card,
  Container,
  Grid,
  CardContent,
  Typography,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { getPermLevel, setAccessToken } from "../accessToken";
import {
  useCheckClassQuery,
  useGetMyClassIdMutation,
  useLogoutMutation,
  useMeQuery,
  useRegisterStudentMutation,
} from "../generated/graphql";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setClassId } from "../classId";
import { setHeight } from "../setHeight";

export default function Home() {
  const [logout, { client }] = useLogoutMutation();
  const { enqueueSnackbar } = useSnackbar();
  const checkClassQuery = useCheckClassQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: { code: "-" },
  });
  const meQuery = useMeQuery({ pollInterval: 1000 });
  const [doRegisterStudent] = useRegisterStudentMutation({ errorPolicy: "all" });
  const [classCode, setclassCode] = React.useState("-");
  const [codeCorrect, setcodeCorrect] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const navigate = useNavigate();
  const [getMyClassId] = useGetMyClassIdMutation({ errorPolicy: "all" });
  const [searchParams] = useSearchParams();
  const parsed = React.useRef(false);

  React.useEffect(() => {
    setHeight();
    setisLoading(false);
    try {
      const permLevel = getPermLevel();
      if (permLevel !== false && permLevel > 0) {
        logout();
        setAccessToken("");
        setClassId(-1);
        client.resetStore();
      }
    } catch {
      console.log("error logging out");
    }
  }, [client, logout]);

  React.useEffect(() => {
    (async () => {
      if (meQuery.data !== undefined && meQuery.data.me !== null) {
        const response = await getMyClassId();
        if (response.data && response.data.myClassId && response.data.myClassId !== -1) {
          setClassId(response.data.myClassId);
          navigate("/play");
        }
      }
    })();
  }, [meQuery, navigate, getMyClassId]);

  const CodeSchema = Yup.object().shape({
    code: Yup.string().required("Code Required").length(5, "The class code is 5 letters long"),
  });

  React.useEffect(() => {
    if (!parsed.current) {
      (async () => {
        parsed.current = true;
        const cc = searchParams.get("i");
        if (cc !== null) {
          try {
            const upperCode = cc.toUpperCase();
            const response = await checkClassQuery.refetch({ code: upperCode });
            if (response.data.checkClass) {
              setclassCode(upperCode);
              setcodeCorrect(true);
              enqueueSnackbar("Set your username!", { variant: "success" });
            } else {
              enqueueSnackbar("Please type in the class code!", { variant: "error" });
            }
          } catch (err) {
            console.log("err");
          }
        }
      })();
    }
  }, [checkClassQuery, codeCorrect, enqueueSnackbar, searchParams]);

  const formikCode = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: CodeSchema,
    onSubmit: async (values, actions) => {
      try {
        const upperCode = values.code.toUpperCase();
        const response = await checkClassQuery.refetch({ code: upperCode });
        if (response.data.checkClass) {
          setclassCode(upperCode);
          setcodeCorrect(true);
          enqueueSnackbar("Success, now set your username!", { variant: "success" });
        } else {
          enqueueSnackbar("Sorry, wrong code!", { variant: "error" });
        }
        actions.setSubmitting(false);
      } catch (err) {
        actions.resetForm();
      }
    },
  });

  const RegisterSchema = Yup.object().shape({
    username: Yup.string().required("Username Required"),
  });

  const formikRegister = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, actions) => {
      try {
        const response = await doRegisterStudent({
          variables: { code: classCode, username: values.username },
        });
        if (response.errors) {
          enqueueSnackbar(response.errors[0].message, { variant: "error" });
          setcodeCorrect(false);
          setclassCode("-");
          actions.resetForm();
        } else {
          if (response && response.data) {
            setAccessToken(response.data.registerStudent.accessToken);
            const classIdResponse = await getMyClassId();
            if (
              classIdResponse.data &&
              classIdResponse.data.myClassId &&
              classIdResponse.data.myClassId !== -1
            ) {
              setClassId(classIdResponse.data.myClassId);
            }
          }
          enqueueSnackbar("Joined Class!", { variant: "success" });
          setisLoading(true);
        }
        actions.setSubmitting(false);
      } catch (err) {
        actions.resetForm();
      }
    },
  });

  return (
    <Box
      className='fullHeightContainerBox'
      sx={{
        backgroundColor: "primary.main",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {codeCorrect ? (
        <Container maxWidth='sm'>
          <Grid container>
            <Grid item xs></Grid>
            <Grid
              item
              xs={12}
              md={8}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Card sx={{ marginBottom: "40px", borderRadius: "15px" }}>
                <CardContent>
                  <Typography
                    variant='h4'
                    sx={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}
                    gutterBottom
                  >
                    Set Username
                  </Typography>
                  <form onSubmit={formikRegister.handleSubmit}>
                    <Grid container spacing={3} sx={{ paddingRight: "10px", paddingLeft: "10px" }}>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete='off'
                          required
                          fullWidth
                          id='username'
                          name='username'
                          label='Username'
                          value={formikRegister.values.username}
                          onChange={formikRegister.handleChange}
                          onBlur={formikRegister.handleBlur}
                          error={
                            formikRegister.touched.username &&
                            Boolean(formikRegister.errors.username)
                          }
                          helperText={
                            formikRegister.touched.username && formikRegister.errors.username
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          variant='contained'
                          type='submit'
                          color='secondary'
                          size='large'
                          fullWidth
                        >
                          Go!
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress />
          </Backdrop>
        </Container>
      ) : (
        <Container maxWidth='sm'>
          <Grid container>
            <Grid item xs></Grid>
            <Grid
              item
              xs={12}
              md={8}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Card sx={{ marginBottom: "40px", borderRadius: "15px" }}>
                <CardContent>
                  <Typography
                    variant='h4'
                    sx={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}
                    gutterBottom
                  >
                    Join Class
                  </Typography>
                  <form onSubmit={formikCode.handleSubmit}>
                    <Grid container spacing={3} sx={{ paddingRight: "10px", paddingLeft: "10px" }}>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete='off'
                          required
                          fullWidth
                          id='code'
                          name='code'
                          label='Class Code'
                          value={formikCode.values.code}
                          onChange={formikCode.handleChange}
                          onBlur={formikCode.handleBlur}
                          error={formikCode.touched.code && Boolean(formikCode.errors.code)}
                          helperText={formikCode.touched.code && formikCode.errors.code}
                          sx={{ "& input": { textTransform: "uppercase" } }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          variant='contained'
                          type='submit'
                          color='secondary'
                          size='large'
                          fullWidth
                        >
                          Join!
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
}
