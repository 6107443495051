import {
  Backdrop,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridSortModel,
  GridActionsCellItem,
  GridRowId,
  GridRowParams,
  GridCellParams,
  GridPagination,
  GridSelectedRowCount,
  GridCellEditCommitParams,
} from "@mui/x-data-grid";
import { useDeleteUsersMutation, useEditUserMutation, UsersQuery } from "../generated/graphql";
import { getUserId } from "../accessToken";
import { useSnackbar } from "notistack";
import CustomLoadingOverlay from "../components/CustomLoadingOverlay";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

export function ManageUsersTableUnmemo(props: {
  setaddUserOpen: Function;
  data: UsersQuery | undefined;
  refetch: Function;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [editUser] = useEditUserMutation();
  const [deleteUsers] = useDeleteUsersMutation();
  const myUserId = useRef(-1);

  useEffect(() => {
    myUserId.current = getUserId() as number;
  }, []);

  const renderUserType = (params: GridRenderCellParams<number>) => {
    switch (params.value) {
      case 2:
        return (
          <Box sx={{ backgroundColor: "error.main", borderRadius: "1000px" }}>
            <Typography variant='body2' display='block' sx={{ padding: "5px 10px 5px 10px" }}>
              Admin
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ backgroundColor: "success.main", borderRadius: "1000px" }}>
            <Typography variant='body2' display='block' sx={{ padding: "5px 10px 5px 10px" }}>
              Trainer
            </Typography>
          </Box>
        );
      case 0:
        return (
          <Box sx={{ backgroundColor: "info.main", borderRadius: "1000px" }}>
            <Typography variant='body2' display='block' sx={{ padding: "5px 10px 5px 10px" }}>
              Student
            </Typography>
          </Box>
        );
    }
  };

  const renderEditUserType = (params: GridRenderEditCellParams) => {
    const { id, value, api, field } = params;

    const handleAccountTypeChange = async (event: SelectChangeEvent<number>) => {
      api.setEditCellValue({ id, field, value: Number(event.target.value) }, event);
      await api.commitCellChange({ id, field });
      api.setCellMode(id, field, "view");
      const response = await editUser({
        variables: {
          editUserId: id,
          permLevel: Number(event.target.value),
        },
      });
      if (response.data && response.data.editUser) {
        enqueueSnackbar("Account type changed.", { variant: "success" });
      } else {
        enqueueSnackbar("Failed to change account type.", { variant: "error" });
        api.setEditCellValue({ id, field, value: value }, event);
        await api.commitCellChange({ id, field });
      }
      api.setCellMode(id, field, "view");
    };

    return (
      <Select
        value={value as number}
        onChange={handleAccountTypeChange}
        sx={{ borderWidth: 0 }}
        fullWidth
      >
        <MenuItem value={2}>Admin</MenuItem>
        <MenuItem value={1}>Trainer</MenuItem>
        <MenuItem value={0}>Student</MenuItem>
      </Select>
    );
  };

  const handleCellEditCommit = async (params: GridCellEditCommitParams) => {
    const { id, value, field } = params;
    if (field === "permLevel") return;
    if (field === "username") {
      const response = await editUser({
        variables: {
          editUserId: Number(id),
          username: String(value),
        },
      });
      if (response.data && response.data.editUser) {
        enqueueSnackbar("Edit success!", { variant: "success" });
      } else {
        enqueueSnackbar("Error editing!", { variant: "error" });
        setTimeout(() => (window.location.href = "/su/admin/users/manage"), 500);
      }
    }
  };

  const gridColumns = [
    { field: "username", headerName: "Username", minWidth: 150, editable: true },
    {
      field: "permLevel",
      headerName: "Account Type",
      minWidth: 150,
      renderCell: renderUserType,
      renderEditCell: renderEditUserType,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Delete",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Delete'
          onClick={deleteUser(params.id)}
        />,
      ],
    },
  ];

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "username",
      sort: "asc",
    },
  ]);

  const deleteUser = (id: GridRowId) => async () => {
    if (Number(id) === getUserId()) {
      enqueueSnackbar("You cannot delete your own account.", { variant: "error" });
      return;
    }
    const response = await deleteUsers({ variables: { ids: [Number(id)] } });
    if (response.data && response.data.deleteUsers) {
      enqueueSnackbar("Delete successful!", { variant: "success" });
    } else {
      enqueueSnackbar("Error deleting!", { variant: "error" });
    }
    props.refetch();
  };

  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);

  const handleSelection = (ids: GridRowId[]) => {
    setSelectedIds(ids);
  };

  const deleteSelected = async () => {
    handleConfirmDeleteClose();
    const selectedIntIds = selectedIds.map((row) => Number(row));
    const response = await deleteUsers({ variables: { ids: selectedIntIds } });
    if (response.data && response.data.deleteUsers) {
      enqueueSnackbar("Delete successful!", { variant: "success" });
    } else {
      enqueueSnackbar("Error deleting!", { variant: "error" });
    }
    props.refetch();
  };

  const [confirmDeleteOpen, setconfirmDeleteOpen] = useState(false);

  const handleConfirmDeleteClose = () => setconfirmDeleteOpen(false);

  const CustomFooter = () => {
    return (
      <Grid container alignItems='center'>
        <Grid item xs>
          <Stack direction='row'>
            {selectedIds.length !== 0 ? (
              <>
                <GridSelectedRowCount selectedRowCount={selectedIds!.length} />
                <Button
                  variant='outlined'
                  color='error'
                  size='small'
                  onClick={() => setconfirmDeleteOpen(true)}
                >
                  Delete selected
                </Button>
              </>
            ) : (
              <></>
            )}

            <Button
              variant='outlined'
              color='success'
              size='small'
              onClick={() => props.setaddUserOpen(true)}
              sx={{ margin: "0px 16px" }}
              startIcon={<AddIcon />}
            >
              Create User
            </Button>
          </Stack>
        </Grid>
        <Grid item xs>
          <GridPagination />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Container sx={{ marginTop: "20px" }}>
        <Stack
          spacing={2}
          justifyContent='center'
          alignItems='center'
          direction='column'
          sx={{ width: "100%" }}
        >
          <Typography variant='h3' component='div'>
            Manage Users
          </Typography>
          <Box sx={{ width: "100%", height: "80vh" }}>
            <DataGrid
              checkboxSelection
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              components={{ LoadingOverlay: CustomLoadingOverlay, Footer: CustomFooter }}
              rows={props.data ? (props.data.users as GridRowsProp) : []}
              columns={gridColumns}
              isCellEditable={(params: GridCellParams) => params.id !== myUserId.current}
              isRowSelectable={(params: GridRowParams) => params.id !== myUserId.current}
              loading={!props.data ? true : false}
              sx={{ boxShadow: 3 }}
              onSelectionModelChange={handleSelection}
              onCellEditCommit={handleCellEditCommit}
            />
          </Box>
        </Stack>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={confirmDeleteOpen}
          onClick={handleConfirmDeleteClose}
        >
          <Box
            sx={{
              backgroundColor: "white",
              color: "black",
              padding: "50px 100px 50px 100px",
              borderRadius: "10px",
            }}
          >
            <Typography variant='h4' sx={{ paddingBottom: "30px" }}>
              Are you sure you want to delete selected users?
            </Typography>
            <Stack direction='row' justifyContent='center' spacing={5}>
              <Button
                color='error'
                variant='outlined'
                startIcon={<CloseIcon />}
                onClick={handleConfirmDeleteClose}
              >
                Cancel
              </Button>
              <Button
                color='error'
                variant='contained'
                startIcon={<CheckIcon />}
                onClick={deleteSelected}
              >
                Delete
              </Button>
            </Stack>
          </Box>
        </Backdrop>
      </Container>
    </>
  );
}

export const ManageUsersTable = React.memo(ManageUsersTableUnmemo);
