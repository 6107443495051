import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import StartIcon from "@mui/icons-material/Start";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

export default function ClassCard({
  name,
  nGroups,
  status,
  created,
  id,
  startEdit,
}: {
  name: string;
  nGroups: number;
  status: number;
  created: string;
  id: number;
  startEdit: Function;
}) {
  const renderStatus = (status: number) => {
    switch (status) {
      case 0:
        return "Ready";
      case 1:
        return "In Progress";
      case 2:
        return "Ended";
    }
  };

  const renderStatusColour = (status: number) => {
    switch (status) {
      case 0:
        return "success.dark";
      case 1:
        return "warning.dark";
      case 2:
        return "error.dark";
    }
  };

  const card = (
    <>
      <CardContent>
        <Typography variant='h4' sx={{ mb: 3 }}>
          {name}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Typography variant='body1'>Groups</Typography>
            <Typography variant='h6' component='div'>
              {nGroups}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant='body1'>Status</Typography>
            <Typography variant='h6' component='div' color={renderStatusColour(status)}>
              {renderStatus(status)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant='body1'>Created</Typography>
            <Typography variant='h6' component='div'>
              {created}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{ display: "flex", justifyContent: "right", borderTop: "1px #dddddd solid" }}
      >
        <Button variant='outlined' startIcon={<EditIcon />} onClick={() => startEdit(id)}>
          Edit
        </Button>
        <Button
          variant='outlined'
          startIcon={<StartIcon />}
          component={Link}
          to={`/su/class/view/${id}`}
          sx={{ ml: 1 }}
        >
          Start
        </Button>
      </CardActions>
    </>
  );
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant='outlined'>{card}</Card>
    </Box>
  );
}
