import { Box, AppBar, Toolbar, Button, Typography } from "@mui/material";
import { useLogoutMutation } from "../generated/graphql";
import MainDrawer from "./MainDrawer";
import { getPermLevel, setAccessToken } from "../accessToken";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import React from "react";
import { setClassId } from "../classId";

export function MainAppBarUnmemo() {
  const [logout, { client }] = useLogoutMutation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const permLevel = getPermLevel();
  if (permLevel === false) {
    window.location.href = "/login";
    return <div></div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' color='primary'>
        <Toolbar>
          <MainDrawer />
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            All About Credit Cards
          </Typography>
          <Button
            variant='outlined'
            color='inherit'
            onClick={async () => {
              await logout();
              setAccessToken("");
              setClassId(-1);
              await client!.resetStore();
              navigate("/login");
              enqueueSnackbar("Logout successful", { variant: "success" });
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const MainAppBar = React.memo(MainAppBarUnmemo);

export default MainAppBar;
