import React from "react";
import { useNavigate } from "react-router-dom";
import { setAccessToken } from "../accessToken";
import { getClassId, setClassId } from "../classId";
import JoinedStage from "../components/StudentGame/JoinedStage";
import { useGetGameStageQuery, useMeQuery } from "../generated/graphql";
import BottomDrawer from "../components/StudentGame/BottomDrawer";
import { motion, AnimatePresence } from "framer-motion";
import S00Intro from "../components/StudentGame/S00Intro";
import S01Sam from "../components/StudentGame/S01Sam";
import S02CreditCardInfo from "../components/StudentGame/S02CreditCardInfo";
import S03SelectCreditCard from "../components/StudentGame/S03SelectCreditCard";
import S04EndNovember from "../components/StudentGame/S04EndNovember";
import S05December from "../components/StudentGame/S05December";
import S06SelectCake from "../components/StudentGame/S06SelectCake";
import S07SelectDinner from "../components/StudentGame/S07SelectDinner";
import S08SelectFlowers from "../components/StudentGame/S08SelectFlowers";
import S09EndDecember from "../components/StudentGame/S09EndDecember";
import S10January from "../components/StudentGame/S10January";
import S11EndJanuary from "../components/StudentGame/S11EndJanuary";
import S12February from "../components/StudentGame/S12February";
import S13RetrenchmentCards from "../components/StudentGame/S13RetrenchmentCards";
import S14EndFebruary from "../components/StudentGame/S14EndFebruary";
import S15March from "../components/StudentGame/S15March";
import S16EndMarch from "../components/StudentGame/S16EndMarch";
import S17April from "../components/StudentGame/S17April";
import S18EmploymentCards from "../components/StudentGame/S18EmploymentCards";
import S19EndApril from "../components/StudentGame/S19EndApril";
import { Box } from "@mui/material";
import { setHeight } from "../setHeight";

export default function StudentPlay({ isTrainerView }: { isTrainerView?: boolean }) {
  let isTrainer = false;
  if (isTrainerView !== undefined) isTrainer = true;

  const classId = getClassId();
  const navigate = useNavigate();
  const getGameStage = useGetGameStageQuery({
    errorPolicy: "all",
    variables: { getGameStageId: classId },
    pollInterval: 500,
  });

  const prevGameStage = React.useRef(-1);
  const [screenWidth, setscreenWidth] = React.useState(400);

  const meQuery = useMeQuery({ pollInterval: 2500 });
  React.useEffect(() => {
    if (!isTrainer) {
      setHeight();
      if (meQuery.data !== undefined && meQuery.data.me == null) {
        setAccessToken("");
        setClassId(-1);
        navigate("/");
      }
      if (classId === -1) navigate("/");
    }
  });

  React.useEffect(() => {
    const container = document.getElementsByClassName("mainBoxContainerMain")[0];
    setscreenWidth(container.clientWidth);
  }, []);

  const GameScreen = () => {
    if (getGameStage.data && getGameStage.data.getGameStage !== undefined) {
      const gameStage = getGameStage.data.getGameStage;
      if (gameStage !== prevGameStage.current) {
        prevGameStage.current = gameStage;
      }
      switch (gameStage) {
        case 0:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='0'
              style={{ width: "100%", height: "100%" }}
            >
              <S00Intro />
            </motion.div>
          );
        case 1:
          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ x: -screenWidth }}
              key='1'
              style={{ width: "100%", height: "100%" }}
            >
              <S01Sam />
            </motion.div>
          );
        case 2:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='2'
              style={{ width: "100%", height: "100%" }}
            >
              <S02CreditCardInfo />
            </motion.div>
          );
        case 3:
          return (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ x: -screenWidth }}
              key='3'
              style={{ width: "100%", height: "100%" }}
            >
              <S03SelectCreditCard gameId={classId} />
            </motion.div>
          );
        case 4:
          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ x: -screenWidth }}
              key='4'
              style={{ width: "100%", height: "100%" }}
            >
              <S04EndNovember />
            </motion.div>
          );
        case 5:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='5'
              style={{ width: "100%", height: "100%" }}
            >
              <S05December isTrainer={isTrainer} />
            </motion.div>
          );
        case 6:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='6'
              style={{ width: "100%", height: "100%" }}
            >
              <S06SelectCake />
            </motion.div>
          );
        case 7:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='7'
              style={{ width: "100%", height: "100%" }}
            >
              <S07SelectDinner />
            </motion.div>
          );
        case 8:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='8'
              style={{ width: "100%", height: "100%" }}
            >
              <S08SelectFlowers />
            </motion.div>
          );
        case 9:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='9'
              style={{ width: "100%", height: "100%" }}
            >
              <S09EndDecember />
            </motion.div>
          );
        case 10:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='10'
              style={{ width: "100%", height: "100%" }}
            >
              <S10January />
            </motion.div>
          );
        case 11:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='11'
              style={{ width: "100%", height: "100%" }}
            >
              <S11EndJanuary />
            </motion.div>
          );
        case 12:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='12'
              style={{ width: "100%", height: "100%" }}
            >
              <S12February />
            </motion.div>
          );
        case 13:
          return (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ x: -screenWidth }}
              key='13'
              style={{ width: "100%", height: "100%" }}
            >
              <S13RetrenchmentCards gameId={classId} />
            </motion.div>
          );
        case 14:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='14'
              style={{ width: "100%", height: "100%" }}
            >
              <S14EndFebruary />
            </motion.div>
          );
        case 15:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='15'
              style={{ width: "100%", height: "100%" }}
            >
              <S15March />
            </motion.div>
          );
        case 16:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='16'
              style={{ width: "100%", height: "100%" }}
            >
              <S16EndMarch />
            </motion.div>
          );
        case 17:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='17'
              style={{ width: "100%", height: "100%" }}
            >
              <S17April />
            </motion.div>
          );
        case 18:
          return (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ x: -screenWidth }}
              key='18'
              style={{ width: "100%", height: "100%" }}
            >
              <S18EmploymentCards gameId={classId} />
            </motion.div>
          );
        case 19:
          return (
            <motion.div
              initial={{ x: screenWidth }}
              animate={{ x: 0 }}
              exit={{ x: -screenWidth }}
              key='19'
              style={{ width: "100%", height: "100%" }}
            >
              <S19EndApril />
            </motion.div>
          );
      }
    }
    return <JoinedStage />;
  };

  const DrawerWrapper = () => {
    const [drawerOpen, setdrawerOpen] = React.useState(false);

    if (getGameStage.data && getGameStage.data.getGameStage !== undefined) {
      const gameStage = getGameStage.data.getGameStage;
      if (gameStage !== prevGameStage.current) {
        prevGameStage.current = gameStage;
        setdrawerOpen(false);
      }
    }
    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {getGameStage.data &&
        getGameStage.data.getGameStage !== undefined &&
        getGameStage.data.getGameStage >= 4 ? (
          <BottomDrawer
            highlight={
              getGameStage.data.getGameStage === 4 ||
              getGameStage.data.getGameStage === 9 ||
              getGameStage.data.getGameStage === 11 ||
              getGameStage.data.getGameStage === 14 ||
              getGameStage.data.getGameStage === 16 ||
              getGameStage.data.getGameStage === 19
            }
            open={drawerOpen}
            setOpen={setdrawerOpen}
          />
        ) : (
          <></>
        )}
      </motion.div>
    );
  };

  return (
    <Box
      sx={{
        width: isTrainer ? "100%" : "100vw",
        height: "100%",
        overflow: "hidden",
      }}
      className='mainBoxContainerMain fullHeightContainerBox'
    >
      <AnimatePresence>
        <GameScreen key='1' />
        {!isTrainer && <DrawerWrapper key='2' />}
      </AnimatePresence>
    </Box>
  );
}
