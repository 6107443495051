import {
  Box,
  Stack,
  Typography,
  Container,
  Grid,
  Button,
  IconButton,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React from "react";
import {
  Exact,
  GetGameStageQuery,
  useGetOneClassQuery,
  useGetStudentsQuery,
  useNextStageMutation,
  usePrevStageMutation,
} from "../../generated/graphql";
import MainManageStudentsTable from "./components/MainManageStudentsTable";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSnackbar } from "notistack";
import { QueryResult } from "@apollo/client/react/types/types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getViewNext, toggleViewNext } from "../../viewNext";
import StudentPlay from "../../pages/StudentPlay";

const StudentPlayMemo = React.memo(StudentPlay);

let theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
    h1: { fontSize: "3.2rem" },
    h2: {
      fontSize: "2rem",
    },
    h3: { fontSize: "1.5rem" },
    h4: { fontSize: "1.6rem" },
    h5: { fontSize: "0.8rem" },
    h6: { fontSize: "0.67rem" },
    subtitle1: { fontSize: "0.53rem" },
    subtitle2: { fontSize: "0.47rem" },
    body1: { fontSize: "0.875rem" },
    body2: { fontSize: "0.47rem" },
    button: { fontSize: "0.47rem" },
    caption: { fontSize: "0.4rem" },
    overline: { fontSize: "0.4rem" },
  },
  palette: {
    primary: {
      main: "#0e143f",
      light: "#3b396a",
      dark: "#00001b",
    },
    secondary: {
      main: "#f7ca23",
      light: "#fffd5d",
      dark: "#c09a00",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
    },
    warning: {
      main: "#ffa726",
      light: "#ffb74d",
      dark: "#f57c00",
    },
    info: {
      main: "#29b6f6",
      light: "#4fc3f7",
      dark: "#0288d1",
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
    },
  },
});

const gameSteps = [
  "Waiting to begin",
  "About Sam",
  "View credit card options",
  "Select credit card options",
  "End November",
  "December",
  "Select Cake",
  "Select Dinner",
  "Select Flowers",
  "End December",
  "January (Watch)",
  "End January",
  "February (Pay Cut/Retrench)",
  "Draw Cards for Retrenchment",
  "End February",
  "March (Loan deferment)",
  "End March",
  "April",
  "Draw Cards for Job",
  "End April",
  "Rankings",
];

export default function MainManage({
  classId,
  getGameStage,
}: {
  classId: number;
  getGameStage: QueryResult<
    GetGameStageQuery,
    Exact<{
      getGameStageId: number;
    }>
  >;
}) {
  const getClassQuery = useGetOneClassQuery({
    errorPolicy: "all",
    variables: { getClassId: classId },
  });
  const getStudentsQuery = useGetStudentsQuery({
    errorPolicy: "all",
    variables: { getClassId: classId },
    pollInterval: 500,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [doNextStage] = useNextStageMutation();
  const [doPrevStage] = usePrevStageMutation();

  const getStatusBoxColour = (actual: number | undefined, available: number | undefined) => {
    if (actual === undefined) actual = 0;
    if (available === undefined) available = 0;
    if (actual === available) return "success.main";
    return "warning.main";
  };

  const [viewNext, setviewNext] = React.useState(getViewNext());

  return (
    <Box sx={{ height: "Calc(100vh - 64px)" }}>
      <Stack direction='row' sx={{ height: "100%" }}>
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{ height: "100%", padding: "0px 50px 0px 50px", borderRight: "1px solid black" }}
        >
          <Typography variant='h5' gutterBottom>
            Student's view
          </Typography>
          <Box
            sx={{
              aspectRatio: "9/19.5",
              backgroundColor: "#fff",
              height: "Calc(100vh - 64px - 40px - 70px)",
              width: "auto",
              margin: "5px 0 20px 0",
              border: "2px solid black",
              borderRadius: "10px",
              position: "relative",
            }}
            className='teacherViewOfStudent'
          >
            <ThemeProvider theme={theme}>
              <StudentPlayMemo isTrainerView={true} />
            </ThemeProvider>
          </Box>
        </Stack>

        <Container sx={{ marginTop: "20px" }}>
          <Stack direction='column' spacing={4}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Box
                  sx={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "15px",
                    height: "100%",
                    boxShadow: 3,
                  }}
                >
                  <Box
                    sx={{
                      padding: "10px 20px 10px 20px",
                    }}
                  >
                    <Typography variant='h4' align='center' gutterBottom>
                      Groups Status
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {getClassQuery.data && getClassQuery.data.getClass ? (
                          <Box
                            sx={{
                              padding: "10px 20px 10px 20px",
                              border: "1px solid #e0e0e0",
                              borderRadius: "10px",
                              backgroundColor: getStatusBoxColour(
                                getClassQuery.data.getClass.students?.length,
                                getClassQuery.data.getClass.nGroups
                              ),
                            }}
                          >
                            <Typography variant='body1'>Joined</Typography>
                            <Stack direction='row' alignItems='flex-end'>
                              <Typography variant='h4' component='div'>
                                {getClassQuery.data.getClass.students?.length}
                              </Typography>
                              <Typography
                                variant='body1'
                                component='div'
                                sx={{ marginBottom: "2px" }}
                              >
                                /{getClassQuery.data.getClass.nGroups}
                              </Typography>
                            </Stack>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {getStudentsQuery.data &&
                        getStudentsQuery.data.getClass &&
                        getClassQuery.data &&
                        getClassQuery.data.getClass ? (
                          <Box
                            sx={{
                              padding: "10px 20px 10px 20px",
                              border: "1px solid #e0e0e0",
                              borderRadius: "10px",
                              backgroundColor: getStatusBoxColour(
                                getStudentsQuery.data.getClass.students?.filter(
                                  (student) => student.isReady
                                ).length,
                                getClassQuery.data.getClass.students?.length
                              ),
                            }}
                          >
                            <Typography variant='body1'>Ready</Typography>
                            <Stack direction='row' alignItems='flex-end'>
                              <Typography variant='h4' component='div'>
                                {getStudentsQuery.data.getClass.students
                                  ? getStudentsQuery.data.getClass.students.filter(
                                      (student) => student.isReady
                                    ).length
                                  : 0}
                              </Typography>
                              <Typography
                                variant='body1'
                                component='div'
                                sx={{ marginBottom: "2px" }}
                              >
                                /{getClassQuery.data.getClass.students?.length}
                              </Typography>
                            </Stack>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box
                  sx={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "15px",
                    height: "100%",
                    boxShadow: 3,
                  }}
                >
                  <Box sx={{ padding: "10px 20px 10px 20px" }}>
                    <Stack direction='column'>
                      <Typography variant='h4' align='center' gutterBottom>
                        Game Controls
                      </Typography>
                      <Stack direction='row' alignItems='center'>
                        <Box
                          sx={{
                            padding: "10px 20px 10px 20px",
                            border: "1px solid #e0e0e0",
                            borderRadius: "10px",
                            flex: "1",
                          }}
                        >
                          <Typography variant='body1' color='primary.light'>
                            Now
                          </Typography>
                          {getGameStage.data && getGameStage.data.getGameStage !== undefined ? (
                            <Typography variant='h6'>
                              {gameSteps[getGameStage.data.getGameStage]}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Box>
                        <Box sx={{ marginX: "5px" }}>
                          <ArrowForwardIcon fontSize='medium' sx={{ color: "primary.light" }} />
                        </Box>

                        <Box
                          sx={{
                            padding: "10px 20px 10px 20px",
                            border: "1px solid #e0e0e0",
                            borderRadius: "10px",
                            flex: "1",
                            position: "relative",
                          }}
                        >
                          <Box
                            sx={{ position: "absolute", top: "5px", right: "10px", zIndex: "200" }}
                          >
                            <IconButton
                              sx={{ color: "#333" }}
                              onClick={() => {
                                toggleViewNext();
                                setviewNext(!viewNext);
                              }}
                            >
                              {viewNext ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </Box>
                          <Box
                            sx={{
                              filter: "opacity(40%)",
                            }}
                          >
                            <Typography variant='body1' color='primary.light'>
                              Next
                            </Typography>
                            <Typography variant='h6'>
                              {getGameStage.data && getGameStage.data.getGameStage !== undefined ? (
                                <Typography variant='h6' component='div'>
                                  {viewNext ? gameSteps[getGameStage.data.getGameStage + 1] : "???"}
                                </Typography>
                              ) : (
                                <></>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        justifyContent='center'
                        sx={{ marginTop: "20px", marginBottom: "10px" }}
                      >
                        <Button
                          disabled={
                            getStudentsQuery.data &&
                            getStudentsQuery.data.getClass &&
                            getClassQuery.data &&
                            getClassQuery.data.getClass &&
                            getStudentsQuery.data.getClass.students?.filter(
                              (student) => student.isReady
                            ).length === getClassQuery.data.getClass.students?.length
                              ? false
                              : true
                          }
                          variant='outlined'
                          startIcon={<ArrowBackIosNewIcon />}
                          sx={{ flex: "1" }}
                          onClick={async () => {
                            const response = await doPrevStage({
                              variables: { prevStageId: classId },
                            });
                            if (response.data && response.data.prevStage) {
                              getGameStage.refetch();
                              enqueueSnackbar("Successfully gone to previous stage", {
                                variant: "success",
                              });
                            } else {
                              enqueueSnackbar("Error going to previous stage", {
                                variant: "error",
                              });
                            }
                          }}
                        >
                          Previous stage
                        </Button>
                        <Button
                          disabled={
                            getStudentsQuery.data &&
                            getStudentsQuery.data.getClass &&
                            getClassQuery.data &&
                            getClassQuery.data.getClass &&
                            getStudentsQuery.data.getClass.students?.filter(
                              (student) => student.isReady
                            ).length === getClassQuery.data.getClass.students?.length
                              ? false
                              : true
                          }
                          variant='contained'
                          color='success'
                          endIcon={<ArrowForwardIosIcon />}
                          sx={{ flex: "1" }}
                          onClick={async () => {
                            const response = await doNextStage({
                              variables: { nextStageId: classId },
                            });
                            if (response.data && response.data.nextStage) {
                              getGameStage.refetch();
                              enqueueSnackbar("Successfully gone to next stage", {
                                variant: "success",
                              });
                            } else {
                              enqueueSnackbar("Error going to next stage", {
                                variant: "error",
                              });
                            }
                          }}
                        >
                          Next stage
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <MainManageStudentsTable
              data={getStudentsQuery.data}
              refetch={getStudentsQuery.refetch}
            />
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
}
