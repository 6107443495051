import React from "react";
import { motion } from "framer-motion";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useSelectFlowersMutation } from "../../generated/graphql";
import { useSnackbar } from "notistack";

export default function S08SelectFlowers() {
  const [selectOption] = useSelectFlowersMutation();
  const [selectSuccess, setselectSuccess] = React.useState(false);
  const [selectedOption, setselectedOption] = React.useState(0);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Container
        sx={{
          paddingTop: "20px",
          paddingBottom: "55px",
          overflow: "scroll",
          maxHeight: "100%",
        }}
      >
        {selectSuccess ? (
          <>
            <Typography variant='h2' align='center' sx={{ marginX: "10px" }} gutterBottom>
              Selection made!
            </Typography>
            <motion.div initial={{ x: -400 }} animate={{ x: 0 }} transition={{ delay: 0.3 }}>
              <motion.div
                animate={{
                  scale:
                    1 + 0.02 * Number(selectedOption === 1) - 0.1 * Number(selectedOption === 2),
                }}
              >
                <Stack direction='row' sx={{ width: "100%" }} justifyContent='space-between'>
                  <Typography variant='h5' sx={{ marginLeft: "5px" }}>
                    {selectedOption === 1 ? "Bouquet of 99 roses!" : "No bouquet of 99 roses"}
                  </Typography>
                  <Typography variant='h5' sx={{ marginRight: "5px" }}></Typography>
                </Stack>
                <img
                  src='/game/8flowers.png'
                  style={{
                    width: "100%",
                    filter: `drop-shadow(4px 4px 5px rgba(153, 153, 153, ${Number(
                      selectedOption === 1
                    )})) brightness(${1 - 0.5 * Number(selectedOption === 2)})`,
                    transitionDuration: ".2s",
                  }}
                  alt='option 1'
                />
              </motion.div>
            </motion.div>
          </>
        ) : (
          <>
            <Typography variant='h2' align='center' sx={{ marginX: "10px" }}>
              Finally, decide whether to buy flowers for her!
            </Typography>
            <Stack
              direction='column'
              spacing={4}
              sx={{
                paddingTop: "20px",
                paddingBottom: "75px",
                "@media (min-width: 600px)": {
                  paddingX: "15%",
                },
              }}
            >
              <motion.div initial={{ x: -400 }} animate={{ x: 0 }} transition={{ delay: 0.3 }}>
                <motion.div
                  animate={{
                    scale:
                      1 + 0.02 * Number(selectedOption === 1) - 0.1 * Number(selectedOption === 2),
                  }}
                >
                  <Stack direction='row' sx={{ width: "100%" }} justifyContent='space-between'>
                    <Typography variant='h5' sx={{ marginLeft: "5px" }}>
                      Bouquet of 99 roses
                    </Typography>
                    <Typography variant='h5' sx={{ marginRight: "5px" }}>
                      $100
                    </Typography>
                  </Stack>
                  <img
                    src='/game/8flowers.png'
                    style={{
                      width: "100%",
                      filter: `drop-shadow(4px 4px 5px rgba(153, 153, 153, ${Number(
                        selectedOption === 1
                      )})) brightness(${1 - 0.5 * Number(selectedOption === 2)})`,
                      transitionDuration: ".2s",
                    }}
                    alt='option 1'
                  />
                </motion.div>
              </motion.div>
              <motion.div animate={{ opacity: Number(selectedOption === 2) }}>
                <Typography variant='h5' align='center' fontWeight='500' color='error.main'>
                  Are you sure? She might get angry!
                </Typography>
              </motion.div>
              <Grid container sx={{ width: "100%" }}>
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    animate={{ scale: 1 + Number(selectedOption === 1) * 0.1 }}
                    style={{
                      width: "75%",
                      aspectRatio: "1",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "success.main",
                        borderRadius: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: Number(selectedOption === 1) * 10,
                      }}
                      onClick={() => {
                        setselectedOption(1);
                      }}
                    >
                      <Typography variant='h3'>Yes!</Typography>
                    </Box>
                  </motion.div>
                </Grid>
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    animate={{ scale: 1 + Number(selectedOption === 2) * 0.1 }}
                    style={{
                      width: "75%",
                      aspectRatio: "1",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "error.main",
                        borderRadius: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: Number(selectedOption === 2) * 10,
                      }}
                      onClick={() => {
                        setselectedOption(2);
                      }}
                    >
                      <Typography variant='h3'>No</Typography>
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
            </Stack>

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "90%", marginBottom: "75px" }}>
                <Button
                  variant='contained'
                  fullWidth
                  sx={{ boxShadow: 3 }}
                  disabled={selectedOption === 0}
                  onClick={async () => {
                    const response = await selectOption({
                      variables: { optionNumber: selectedOption === 1 },
                    });
                    if (response.data && response.data.selectFlowers) {
                      setselectSuccess(true);
                    } else {
                      enqueueSnackbar("Error!", { variant: "error" });
                    }
                  }}
                >
                  Confirm Selection
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
}
