import React from "react";
import { useImReadyMutation } from "../../generated/graphql";
import { motion } from "framer-motion";
import { Box, Container, Stack, Typography } from "@mui/material";
import Particles from "react-tsparticles";

function S05DecemberUnmemo({ isTrainer }: { isTrainer: boolean }) {
  const [imReady] = useImReadyMutation();
  const year = new Date().getFullYear();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Container
          sx={{ paddingTop: "20px", paddingBottom: "10px", overflow: "scroll", maxHeight: "100%" }}
        >
          <Stack
            direction='column'
            spacing={2}
            alignItems='center'
            sx={{ marginX: "15px", textAlign: "center" }}
          >
            <Typography variant='h2'>December {year}</Typography>
            <motion.div
              initial={{ opacity: 0, x: -400 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <Typography variant='h5'>
                Sam has received his credit card at the start of the month.
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -400 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 2 }}
            >
              <Typography variant='h5'>
                He has his regular monthly expenses ($1,700) to pay for this month.
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -400 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 4 }}
            >
              <Typography variant='h5'>
                It is also sam's girlfriend's birthday this month!
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -400 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 5 }}
            >
              <Typography variant='h5'>
                He intends to spend some money on a good birthday treat for her.
              </Typography>
            </motion.div>
          </Stack>
        </Container>

        <motion.img
          src='/game/5hbd.png'
          style={{ width: "100%" }}
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 5.2 }}
        />
      </Box>
      <motion.div initial={{ opacity: 1 }} animate={{ opacity: 0 }} transition={{ delay: 45 }}>
        {!isTrainer && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 4.5 }}
            onAnimationComplete={() => {
              imReady();
            }}
          >
            <Particles
              id='tsparticles'
              options={{
                autoPlay: true,
                background: {
                  color: {
                    value: "#000000",
                  },
                  opacity: 0,
                },
                fullScreen: {
                  zIndex: -1,
                },
                particles: {
                  bounce: {
                    horizontal: {
                      value: 0,
                    },
                    vertical: {
                      value: 0,
                    },
                  },
                  color: {
                    value: ["#1E00FF", "#FF0061", "#E1FF00", "#00FF9E"],
                    animation: {
                      h: {
                        enable: true,
                        speed: 30,
                      },
                    },
                  },
                  move: {
                    decay: 0.1,
                    direction: "top",
                    enable: true,
                    gravity: {
                      enable: true,
                      maxSpeed: 200,
                    },
                    path: {},
                    outModes: {
                      default: "destroy",
                      bottom: "destroy",
                      left: "destroy",
                      right: "destroy",
                      top: "none",
                    },
                    speed: {
                      min: 50,
                      max: 150,
                    },
                    spin: {},
                  },
                  number: {
                    limit: 200,
                    value: 0,
                  },
                  opacity: {
                    animation: {
                      speed: 0.3,
                      sync: true,
                      destroy: "min",
                      startValue: "max",
                    },
                  },
                  roll: {
                    darken: {
                      enable: true,
                      value: 30,
                    },
                    enable: true,
                    enlighten: {
                      enable: true,
                      value: 30,
                    },
                    speed: {
                      min: 15,
                      max: 25,
                    },
                  },
                  rotate: {
                    value: {
                      min: 0,
                      max: 360,
                    },
                    animation: {
                      enable: true,
                      speed: 60,
                    },
                    direction: "random",
                  },
                  shape: {
                    options: {
                      polygon: [
                        {
                          sides: 5,
                        },
                        {
                          sides: 6,
                        },
                      ],
                      character: [
                        {
                          value: ["💩", "🤡", "🍀", "🍙"],
                        },
                      ],
                      char: [
                        {
                          value: ["💩", "🤡", "🍀", "🍙"],
                        },
                      ],
                      star: [
                        {
                          sides: 5,
                        },
                        {
                          sides: 6,
                        },
                      ],
                    },
                    type: ["circle", "square", "polygon", "character", "character", "character"],
                  },
                  size: {
                    animation: {},
                  },
                  tilt: {
                    value: {
                      min: 0,
                      max: 360,
                    },
                    animation: {
                      enable: true,
                      speed: 60,
                    },
                    direction: "random",
                    enable: true,
                  },
                  wobble: {
                    distance: 30,
                    enable: true,
                    speed: {
                      min: -15,
                      max: 15,
                    },
                  },
                },
                emitters: [
                  {
                    autoPlay: true,
                    fill: true,
                    life: {
                      wait: false,
                    },
                    rate: {
                      quantity: 10,
                      delay: 0.1,
                    },
                    shape: "square",
                    startCount: 0,
                    size: {
                      mode: "percent",
                      height: 0,
                      width: 0,
                    },
                    position: {
                      x: 0,
                      y: 100,
                    },
                  },
                  {
                    autoPlay: true,
                    fill: true,
                    life: {
                      wait: false,
                    },
                    rate: {
                      quantity: 10,
                      delay: 0.1,
                    },
                    shape: "square",
                    startCount: 0,
                    size: {
                      mode: "percent",
                      height: 0,
                      width: 0,
                    },
                    position: {
                      x: 80,
                      y: 100,
                    },
                  },
                ],
              }}
            />
          </motion.div>
        )}
      </motion.div>
    </>
  );
}

const S05December = React.memo(S05DecemberUnmemo);

export default S05December;
