import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Box, Container, Grid, Typography } from "@mui/material";
import {
  useGetCueTimeMutation,
  useCheckReadyMutation,
  useDrawRetrenchMutation,
} from "../../generated/graphql";
import Countdown from "./Countdown";
import { getServerTime } from "../../timeManagement";
import { useSnackbar } from "notistack";

export default function S03SelectCreditCard({ gameId }: { gameId: number }) {
  const [getCueTime] = useGetCueTimeMutation();
  const [checkReady] = useCheckReadyMutation();
  const [drawRetrench] = useDrawRetrenchMutation();

  const [cueTime, setcueTime] = React.useState(-1);
  const [isStarted, setisStarted] = React.useState(false);

  const [jobResult, setjobResult] = React.useState(0);
  const [selectSuccess, setselectSuccess] = React.useState(false);

  const [screenWidth, setscreenWidth] = React.useState(400);

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    (async () => {
      const response = await getCueTime({ variables: { getCueTimeId: gameId } });
      if (response.data && response.data.getCueTime !== -1) {
        setcueTime(response.data.getCueTime);
        if (cueTime > getServerTime()) {
          setisStarted(false);
        }
      } else {
        console.log("error getting cue time");
      }
      const ready = await checkReady();
      if (ready.data && ready.data.checkReady) {
        setselectSuccess(true);
      }
    })();
  }, [gameId, getCueTime, cueTime, checkReady]);

  React.useEffect(() => {
    const container = document.getElementsByClassName("mainBoxContainerMain")[0];
    setscreenWidth(container.clientWidth);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "scroll",
        position: "relative",
      }}
      className='mainBoxContainerMain'
    >
      <AnimatePresence>
        {isStarted ? (
          <motion.div
            key='1'
            style={{ width: "100%", height: "100%" }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            {selectSuccess && (
              <>
                <motion.div
                  className='drawnCard'
                  style={{
                    width: "75%",
                    aspectRatio: "2.25/3.5",
                    position: "absolute",
                    left: "12%",
                    zIndex: "100",
                  }}
                  animate={{
                    opacity: [0, 1, 1, 1, 1],
                    x: [screenWidth, screenWidth, 0, 0, 0],
                    scaleX: [1, 1, 1, 1, 0],
                  }}
                  transition={{ duration: 3, times: [0, 0.01, 0.33, 0.66, 1] }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "primary.main",
                      borderRadius: "10px",
                      border: "4px solid",
                      borderColor: "secondary.main",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src='/SHLogo.png'
                      alt='star horizon logo'
                      style={{ width: "80%", paddingBottom: "30px" }}
                    />
                  </Box>
                </motion.div>
                <motion.div
                  className='drawnCard'
                  style={{
                    width: "75%",
                    aspectRatio: "2.25/3.5",
                    position: "absolute",
                    left: "12%",
                    zIndex: "100",
                  }}
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{ delay: 3, duration: 0.5 }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "primary.main",
                      borderRadius: "10px",
                      border: "4px solid",
                      borderColor: "secondary.main",
                      color: "primary.contrastText",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant='h2' fontWeight='500' align='center'>
                      {jobResult === 0 ? "50% Pay cut" : "Retrenched"}
                    </Typography>
                  </Box>
                </motion.div>
              </>
            )}
            <Container
              sx={{
                paddingTop: "20px",
                height: "100%",
                position: "relative",
              }}
            >
              {selectSuccess ? (
                <Typography variant='h2' align='center' sx={{ marginX: "10px" }} gutterBottom>
                  Card drawn!
                </Typography>
              ) : (
                <>
                  <Typography variant='h2' align='center' sx={{ marginX: "10px" }}>
                    Pick a card!
                  </Typography>
                </>
              )}

              <Grid
                container
                spacing={8}
                sx={{ paddingX: "30px", paddingTop: "30px", paddingBottom: "80px" }}
              >
                <Grid item xs={6}>
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    animate={{ opacity: Number(!selectSuccess) }}
                    onClick={async () => {
                      if (!selectSuccess) {
                        const response = await drawRetrench();
                        if (response.data && response.data.drawRetrench !== -1) {
                          setjobResult(response.data.drawRetrench);
                          setselectSuccess(true);
                        } else {
                          enqueueSnackbar("Error", { variant: "error" });
                        }
                      }
                    }}
                  >
                    <Box
                      sx={{
                        aspectRatio: "2.25/3.5",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "primary.main",
                          borderRadius: "10px",
                          border: "4px solid",
                          borderColor: "secondary.main",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src='/SHLogo.png'
                          alt='star horizon logo'
                          style={{ width: "80%", paddingBottom: "30px" }}
                        />
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
                <Grid item xs={6}>
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    animate={{ opacity: Number(!selectSuccess) }}
                    onClick={async () => {
                      if (!selectSuccess) {
                        const response = await drawRetrench();
                        if (response.data && response.data.drawRetrench !== -1) {
                          setjobResult(response.data.drawRetrench);
                          setselectSuccess(true);
                        } else {
                          enqueueSnackbar("Error", { variant: "error" });
                        }
                      }
                    }}
                  >
                    <Box
                      sx={{
                        aspectRatio: "2.25/3.5",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "primary.main",
                          borderRadius: "10px",
                          border: "4px solid",
                          borderColor: "secondary.main",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src='/SHLogo.png'
                          alt='star horizon logo'
                          style={{ width: "80%", paddingBottom: "30px" }}
                        />
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
                <Grid item xs={6}>
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    animate={{ opacity: Number(!selectSuccess) }}
                    onClick={async () => {
                      if (!selectSuccess) {
                        const response = await drawRetrench();
                        if (response.data && response.data.drawRetrench !== -1) {
                          setjobResult(response.data.drawRetrench);
                          setselectSuccess(true);
                        } else {
                          enqueueSnackbar("Error", { variant: "error" });
                        }
                      }
                    }}
                  >
                    <Box
                      sx={{
                        aspectRatio: "2.25/3.5",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "primary.main",
                          borderRadius: "10px",
                          border: "4px solid",
                          borderColor: "secondary.main",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src='/SHLogo.png'
                          alt='star horizon logo'
                          style={{ width: "80%", paddingBottom: "30px" }}
                        />
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
                <Grid item xs={6}>
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    animate={{ opacity: Number(!selectSuccess) }}
                    onClick={async () => {
                      if (!selectSuccess) {
                        const response = await drawRetrench();
                        if (response.data && response.data.drawRetrench !== -1) {
                          setjobResult(response.data.drawRetrench);
                          setselectSuccess(true);
                        } else {
                          enqueueSnackbar("Error", { variant: "error" });
                        }
                      }
                    }}
                  >
                    <Box
                      sx={{
                        aspectRatio: "2.25/3.5",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "primary.main",
                          borderRadius: "10px",
                          border: "4px solid",
                          borderColor: "secondary.main",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src='/SHLogo.png'
                          alt='star horizon logo'
                          style={{ width: "80%", paddingBottom: "30px" }}
                        />
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
            </Container>
          </motion.div>
        ) : (
          <>
            {cueTime !== -1 && (
              <motion.div
                key='2'
                style={{ width: "100%", height: "100%" }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
              >
                <Countdown
                  setisStarted={setisStarted}
                  cueTime={cueTime}
                  overrideText='Get ready to pick a card!'
                  helperText='There is a limited number of each card!'
                />
              </motion.div>
            )}
          </>
        )}
      </AnimatePresence>
    </Box>
  );
}
