import mainAddr from "./mainAddr";

var timeOffset: number = 0;

export const syncTime = async () => {
  var lowestCallTime = 9999999999;
  var lowestCallTimeData: { callTime: number; nowTime: number; res: Response } = {
    callTime: 0,
    nowTime: performance.now(),
    res: {} as Response,
  };

  const processCallTime = (callTime: number, nowTime: number, res: Response) => {
    if (callTime < lowestCallTime) {
      lowestCallTime = callTime;
      lowestCallTimeData = { callTime, nowTime, res };
    }
  };

  for (let i = 0; i < 10; i++) {
    const startTime = performance.now();
    const res = await fetch(`${mainAddr()}/timeSync`);
    const callTime = performance.now() - startTime;
    const nowTime = Date.now();
    processCallTime(callTime, nowTime, res);
  }

  const serverTime = (await lowestCallTimeData.res.json()) as number;
  const actualServerTime = serverTime + lowestCallTimeData.callTime / 2;
  timeOffset = actualServerTime - lowestCallTimeData.nowTime;
};

export const getServerTime = () => {
  return Date.now() + timeOffset;
};
