import { Container } from "@mui/material";
import React from "react";
import ManageClassesTable from "../components/ManageClassesTable";
import { useClassesQuery } from "../generated/graphql";

export default function AdminClassManage() {
  const { data, refetch } = useClassesQuery({
    errorPolicy: "all",
    variables: { all: true },
    pollInterval: 2000,
  });
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <ManageClassesTable data={data} refetch={refetch} />
    </Container>
  );
}
