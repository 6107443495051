import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";

export default function JoinedStage() {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <motion.div
        animate={{ opacity: [0.5, 1, 0.5] }}
        transition={{ repeat: Infinity, duration: 4 }}
      >
        <Typography variant='h2' align='center' sx={{ marginX: "25px" }}>
          Waiting for game to start
        </Typography>
      </motion.div>
    </Box>
  );
}
