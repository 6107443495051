import { Backdrop, Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridRenderCellParams,
  GridSortModel,
  GridActionsCellItem,
  GridRowId,
  GridRowParams,
  GridCellParams,
  GridPagination,
  GridSelectedRowCount,
  GridCellEditCommitParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { ClassesQuery, useDeleteClassesMutation, useEditUserMutation } from "../generated/graphql";
import { getUserId } from "../accessToken";
import { useSnackbar } from "notistack";
import CustomLoadingOverlay from "./CustomLoadingOverlay";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export function ManageClassesTableUnmemo(props: {
  data: ClassesQuery | undefined;
  refetch: Function;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [editUser] = useEditUserMutation();
  const [deleteClasses] = useDeleteClassesMutation();
  const myUserId = useRef(-1);

  useEffect(() => {
    myUserId.current = getUserId() as number;
  }, []);

  const renderUserType = (params: GridRenderCellParams<number>) => {
    switch (params.value) {
      case 2:
        return (
          <Box sx={{ backgroundColor: "error.main", borderRadius: "1000px" }}>
            <Typography variant='body2' display='block' sx={{ padding: "5px 10px 5px 10px" }}>
              Ended
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ backgroundColor: "warning.main", borderRadius: "1000px" }}>
            <Typography variant='body2' display='block' sx={{ padding: "5px 10px 5px 10px" }}>
              In Progress
            </Typography>
          </Box>
        );
      case 0:
        return (
          <Box sx={{ backgroundColor: "success.main", borderRadius: "1000px" }}>
            <Typography variant='body2' display='block' sx={{ padding: "5px 10px 5px 10px" }}>
              Ready
            </Typography>
          </Box>
        );
    }
  };

  const handleCellEditCommit = async (params: GridCellEditCommitParams) => {
    const { id, value, field } = params;
    if (field === "permLevel") return;
    if (field === "username") {
      const response = await editUser({
        variables: {
          editUserId: Number(id),
          username: String(value),
        },
      });
      if (response.data && response.data.editUser) {
        enqueueSnackbar("Edit success!", { variant: "success" });
      } else {
        enqueueSnackbar("Error editing!", { variant: "error" });
        setTimeout(() => (window.location.href = "/su/admin/users/manage"), 500);
      }
    }
  };

  const getTrainerUsername = (params: GridValueGetterParams) => {
    return params.row.trainer.username;
  };

  const gridColumns = [
    { field: "name", headerName: "Name", minWidth: 150 },
    { field: "nGroups", headerName: "Groups", minWidth: 100 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: renderUserType,
    },
    {
      field: "trainerUsername",
      headerName: "Trainer",
      minWidth: 150,
      valueGetter: getTrainerUsername,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Delete",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Delete'
          onClick={openDeleteClass(params.id)}
        />,
      ],
    },
  ];

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "username",
      sort: "asc",
    },
  ]);

  const [isDeleteClassOpen, setisDeleteClassOpen] = useState(false);
  const [deleteClassIndivId, setdeleteClassIndivId] = useState(0);

  const openDeleteClass = (id: GridRowId) => async () => {
    setisDeleteClassOpen(true);
    setdeleteClassIndivId(Number(id));
  };

  const getClassNameFromId = (id: number) => {
    try {
      return props.data!.classes.find((x) => x.id === id)!.name;
    } catch {
      return "error";
    }
  };

  const deleteClass = (id: number) => async () => {
    const response = await deleteClasses({ variables: { ids: [id] } });
    if (response.data && response.data.deleteClasses) {
      enqueueSnackbar("Delete successful!", { variant: "success" });
    } else {
      enqueueSnackbar("Error deleting!", { variant: "error" });
    }
    props.refetch();
  };

  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);

  const handleSelection = (ids: GridRowId[]) => {
    setSelectedIds(ids);
  };

  const deleteSelected = async () => {
    handleConfirmDeleteClose();
    const selectedIntIds = selectedIds.map((row) => Number(row));
    const response = await deleteClasses({ variables: { ids: selectedIntIds } });
    if (response.data && response.data.deleteClasses) {
      enqueueSnackbar("Delete successful!", { variant: "success" });
    } else {
      enqueueSnackbar("Error deleting!", { variant: "error" });
    }
    props.refetch();
  };

  const [confirmDeleteOpen, setconfirmDeleteOpen] = useState(false);

  const handleConfirmDeleteClose = () => setconfirmDeleteOpen(false);

  const CustomFooter = () => {
    return (
      <Grid container alignItems='center'>
        <Grid item xs>
          <Stack direction='row'>
            {selectedIds.length !== 0 ? (
              <>
                <GridSelectedRowCount selectedRowCount={selectedIds!.length} />
                <Button
                  variant='outlined'
                  color='error'
                  size='small'
                  onClick={() => setconfirmDeleteOpen(true)}
                >
                  Delete selected
                </Button>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
        <Grid item xs>
          <GridPagination />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Container sx={{ marginTop: "20px" }}>
        <Stack
          spacing={2}
          justifyContent='center'
          alignItems='center'
          direction='column'
          sx={{ width: "100%" }}
        >
          <Typography variant='h3' component='div'>
            Manage Classes
          </Typography>
          <Box sx={{ width: "100%", height: "80vh" }}>
            <DataGrid
              checkboxSelection
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              components={{ LoadingOverlay: CustomLoadingOverlay, Footer: CustomFooter }}
              rows={props.data ? (props.data.classes as GridRowsProp) : []}
              columns={gridColumns}
              isCellEditable={(params: GridCellParams) => params.id !== myUserId.current}
              isRowSelectable={(params: GridRowParams) => params.id !== myUserId.current}
              loading={!props.data ? true : false}
              sx={{ boxShadow: 3 }}
              onSelectionModelChange={handleSelection}
              onCellEditCommit={handleCellEditCommit}
            />
          </Box>
        </Stack>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={confirmDeleteOpen}
          onClick={handleConfirmDeleteClose}
        >
          <Box
            sx={{
              backgroundColor: "white",
              color: "black",
              padding: "50px 100px 50px 100px",
              borderRadius: "10px",
            }}
          >
            <Typography variant='h4' sx={{ paddingBottom: "30px" }}>
              Are you sure you want to delete selected classes?
            </Typography>
            <Stack direction='row' justifyContent='center' spacing={5}>
              <Button
                color='error'
                variant='outlined'
                startIcon={<CloseIcon />}
                onClick={handleConfirmDeleteClose}
              >
                Cancel
              </Button>
              <Button
                color='error'
                variant='contained'
                startIcon={<CheckIcon />}
                onClick={deleteSelected}
              >
                Delete
              </Button>
            </Stack>
          </Box>
        </Backdrop>
        {/*delete indiv confirmation */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isDeleteClassOpen}
          onClick={() => setisDeleteClassOpen(false)}
        >
          <Box
            sx={{
              backgroundColor: "white",
              color: "black",
              padding: "50px 100px 50px 100px",
              borderRadius: "10px",
            }}
          >
            <Typography variant='h4' sx={{ paddingBottom: "30px" }}>
              Are you sure you want to delete {getClassNameFromId(deleteClassIndivId)}?
            </Typography>
            <Stack direction='row' justifyContent='center' spacing={5}>
              <Button
                color='error'
                variant='outlined'
                startIcon={<CloseIcon />}
                onClick={() => setisDeleteClassOpen(false)}
              >
                Cancel
              </Button>
              <Button
                color='error'
                variant='contained'
                startIcon={<CheckIcon />}
                onClick={deleteClass(deleteClassIndivId)}
              >
                Delete
              </Button>
            </Stack>
          </Box>
        </Backdrop>
      </Container>
    </>
  );
}

const ManageClassesTable = React.memo(ManageClassesTableUnmemo);

export default ManageClassesTable;
