import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { motion } from "framer-motion";
import MoneySheet from "./MoneySheet";
import { useImReadyMutation } from "../../generated/graphql";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.mode === "light" ? grey[100] : theme.palette.background.default,
}));
const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[400] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export default function BottomDrawer({
  highlight,
  open,
  setOpen,
}: {
  highlight: boolean;
  open: boolean;
  setOpen: Function;
}) {
  const [imReady] = useImReadyMutation();

  const toggleDrawer = (newOpen: boolean) => () => {
    if (highlight) {
      imReady();
    }
    if (newOpen) {
      const scrollableDiv = document.getElementsByClassName("scrollableMoneySheet")[0];
      setTimeout(
        () => scrollableDiv.scrollTo({ top: scrollableDiv.scrollHeight, behavior: "smooth" }),
        300
      );
    }
    setOpen(newOpen);
  };

  return (
    <Root>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        anchor='bottom'
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <motion.div
          initial={{ y: Number(highlight) * 70 }}
          animate={{ y: 0 }}
          transition={{ delay: 1, type: "tween", ease: "easeOut" }}
        >
          <Box
            sx={{
              backgroundColor: "#eee",
              position: "absolute",
              top: -drawerBleeding,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              visibility: "visible",
              right: 0,
              left: 0,
              borderBottom: "1px solid #ccc",
            }}
          >
            <Puller />
            <Typography sx={{ p: 2, color: "text.primary", marginLeft: "8px" }} variant='h6'>
              My Money
            </Typography>
          </Box>
        </motion.div>
        <Box
          sx={{
            backgroundColor: "#eee",
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "scroll",
          }}
          className='scrollableMoneySheet'
        >
          <MoneySheet />
        </Box>
      </SwipeableDrawer>
    </Root>
  );
}
