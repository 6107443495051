import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  useClassesQuery,
  useCreateClassMutation,
  useEditClassMutation,
} from "../generated/graphql";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import TrainerClassList from "../components/TrainerClassList";

export default function TrainerClassManage() {
  const { enqueueSnackbar } = useSnackbar();
  const [isCreateOpen, setisCreateOpen] = React.useState(false);
  //add user form
  const [doCreateClass] = useCreateClassMutation({ errorPolicy: "all" });
  const [doEditClass] = useEditClassMutation({ errorPolicy: "all" });
  const { data, refetch } = useClassesQuery({
    errorPolicy: "all",
    pollInterval: 2000,
    variables: { all: false },
  });

  const [editId, seteditId] = React.useState(-1);

  const startEdit = (id: number) => {
    seteditId(id);
    try {
      const classToEdit = data!.classes.find((x) => x.id === id)!;
      formikEdit.values.name = classToEdit.name;
      formikEdit.values.nGroups = classToEdit.nGroups;
    } catch {
      console.error("Error getting class details.");
    }
  };

  const ClassSchema = Yup.object().shape({
    name: Yup.string().required("Name Required"),
    nGroups: Yup.number()
      .required("Number of groups is required")
      .min(1, "There has to be at least one group"),
  });

  const formikCreate = useFormik({
    initialValues: {
      name: "",
      nGroups: 1,
    },
    validationSchema: ClassSchema,
    onSubmit: async (values, actions) => {
      try {
        const response = await doCreateClass({
          variables: {
            name: values.name,
            nGroups: values.nGroups,
          },
        });
        if (response.data && response.data.createClass) {
          enqueueSnackbar("Successfully created class", { variant: "success" });
        } else {
          enqueueSnackbar("Error creating class", { variant: "error" });
        }
        actions.setSubmitting(false);
        setisCreateOpen(false);
        refetch();
        actions.resetForm();
      } catch (err) {
        actions.resetForm();
      }
    },
  });

  const formikEdit = useFormik({
    initialValues: {
      name: "",
      nGroups: 1,
    },
    validationSchema: ClassSchema,
    onSubmit: async (values, actions) => {
      try {
        const response = await doEditClass({
          variables: {
            editClassId: editId,
            name: values.name,
            nGroups: values.nGroups,
          },
        });
        if (response.data && response.data.editClass) {
          enqueueSnackbar("Successfully edited class", { variant: "success" });
        } else {
          enqueueSnackbar("Error editing class", { variant: "error" });
        }
        seteditId(-1);
        actions.setSubmitting(false);
        refetch();
        actions.resetForm();
      } catch (err) {
        actions.resetForm();
      }
    },
  });

  return (
    <>
      {data ? (
        <TrainerClassList setisCreateOpen={setisCreateOpen} data={data} startEdit={startEdit} />
      ) : (
        <></>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isCreateOpen}
        onClick={() => setisCreateOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container maxWidth='sm'>
            <Grid container>
              <Grid item xs></Grid>
              <Grid
                item
                xs={12}
                md={8}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Card sx={{ marginBottom: "40px", borderRadius: "15px" }}>
                  <CardContent>
                    <Typography
                      variant='h4'
                      sx={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}
                      gutterBottom
                    >
                      Create Class
                    </Typography>
                    <form onSubmit={formikCreate.handleSubmit}>
                      <Grid
                        container
                        spacing={3}
                        sx={{ paddingRight: "10px", paddingLeft: "10px" }}
                      >
                        <Grid item xs={12}>
                          <TextField
                            autoComplete='off'
                            required
                            fullWidth
                            id='name'
                            name='name'
                            label='Class Name'
                            value={formikCreate.values.name}
                            onChange={formikCreate.handleChange}
                            onBlur={formikCreate.handleBlur}
                            error={formikCreate.touched.name && Boolean(formikCreate.errors.name)}
                            helperText={formikCreate.touched.name && formikCreate.errors.name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            autoComplete='off'
                            required
                            fullWidth
                            id='nGroups'
                            name='nGroups'
                            type='number'
                            label='Number of Groups'
                            value={formikCreate.values.nGroups}
                            onChange={formikCreate.handleChange}
                            onBlur={formikCreate.handleBlur}
                            error={
                              formikCreate.touched.nGroups && Boolean(formikCreate.errors.nGroups)
                            }
                            helperText={formikCreate.touched.nGroups && formikCreate.errors.nGroups}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
                          <Button variant='contained' type='submit'>
                            Create
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs></Grid>
            </Grid>
          </Container>
        </Box>
      </Backdrop>
      {/*edit class */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={editId !== -1}
        onClick={() => seteditId(-1)}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container maxWidth='sm'>
            <Grid container>
              <Grid item xs></Grid>
              <Grid
                item
                xs={12}
                md={8}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Card sx={{ marginBottom: "40px", borderRadius: "15px" }}>
                  <CardContent>
                    <Typography
                      variant='h4'
                      sx={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}
                      gutterBottom
                    >
                      Edit Class
                    </Typography>
                    <form onSubmit={formikEdit.handleSubmit}>
                      <Grid
                        container
                        spacing={3}
                        sx={{ paddingRight: "10px", paddingLeft: "10px" }}
                      >
                        <Grid item xs={12}>
                          <TextField
                            autoComplete='off'
                            required
                            fullWidth
                            id='name'
                            name='name'
                            label='Class Name'
                            value={formikEdit.values.name}
                            onChange={formikEdit.handleChange}
                            onBlur={formikEdit.handleBlur}
                            error={formikEdit.touched.name && Boolean(formikEdit.errors.name)}
                            helperText={formikEdit.touched.name && formikEdit.errors.name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            autoComplete='off'
                            required
                            fullWidth
                            id='nGroups'
                            name='nGroups'
                            type='number'
                            label='Number of Groups'
                            value={formikEdit.values.nGroups}
                            onChange={formikEdit.handleChange}
                            onBlur={formikEdit.handleBlur}
                            error={formikEdit.touched.nGroups && Boolean(formikEdit.errors.nGroups)}
                            helperText={formikEdit.touched.nGroups && formikEdit.errors.nGroups}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
                          <Button variant='contained' type='submit'>
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs></Grid>
            </Grid>
          </Container>
        </Box>
      </Backdrop>
    </>
  );
}
