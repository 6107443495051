import React from "react";
import { useImReadyMutation } from "../../generated/graphql";
import { motion } from "framer-motion";
import { Box, Container, Typography } from "@mui/material";

export default function S00Intro() {
  const [imReady] = useImReadyMutation();
  const [cardImageOffset, setcardImageOffset] = React.useState(-550);

  const calcCardImageOffset = () => {
    const mainText = document.getElementsByClassName("textContainerMain")[0];
    const ccImage = document.getElementsByClassName("creditCardImageMain")[0];

    const offsetHeight =
      ccImage.getBoundingClientRect().height -
      (window.screen.height - mainText.getBoundingClientRect().bottom);

    if (offsetHeight < 0) {
      setcardImageOffset(0);
    } else {
      setcardImageOffset(-offsetHeight - 50);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Container>
        <motion.div style={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "10px",
              marginTop: "15px",
              boxShadow: 3,
              backgroundColor: "#ffffffde",
            }}
            className='textContainerMain'
          >
            <Box sx={{ padding: "10px 15px 12px 15px" }}>
              <motion.div
                style={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.1 }}
              >
                <Typography variant='h2' align='center' gutterBottom>
                  All about credit cards
                </Typography>
              </motion.div>
              <motion.div
                style={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                onAnimationComplete={() => {
                  calcCardImageOffset();
                  imReady();
                }}
              >
                <Typography variant='body1'>
                  Welcome to all about credit cards! <br />
                  Today, you will be learning about credit cards and spending! <br />
                  You will assume the identity of Sam, a university graduate starting work. Follow
                  him through his daily life, experience the ups and downs, and battle it out with
                  your friends to be the best!
                </Typography>
              </motion.div>
            </Box>

            <motion.img
              src='/game/0introCard.png'
              style={{ position: "absolute", bottom: "-100%", zIndex: "-1000" }}
              animate={{ bottom: cardImageOffset }}
              drag='y'
              dragConstraints={{ top: 0, bottom: 0 }}
              className='creditCardImageMain'
            />
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
}
