import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Assets = {
  __typename?: 'Assets';
  cakeOne: Scalars['String'];
  cakeTwo: Scalars['String'];
  cardOne: Scalars['String'];
  cardTwo: Scalars['String'];
  dinnerOne: Scalars['String'];
  dinnerTwo: Scalars['String'];
  id: Scalars['Int'];
};

export type Class = {
  __typename?: 'Class';
  code: Scalars['String'];
  dateCreated: Scalars['String'];
  game: Game;
  id: Scalars['Int'];
  nGroups: Scalars['Float'];
  name: Scalars['String'];
  status: Scalars['Float'];
  students?: Maybe<Array<User>>;
  trainer: User;
};

export type Game = {
  __typename?: 'Game';
  cardOneAvailable: Scalars['Float'];
  cardTwoAvailable: Scalars['Float'];
  cueTime: Scalars['Float'];
  id: Scalars['Int'];
  stage: Scalars['Float'];
  studentClass: Class;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  checkInvalidation: Scalars['Boolean'];
  checkReady: Scalars['Boolean'];
  createClass: Scalars['Boolean'];
  deleteClasses: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteUsers: Scalars['Boolean'];
  drawEmployment: Scalars['Float'];
  drawRetrench: Scalars['Float'];
  editClass: Scalars['Boolean'];
  editLabelText: Scalars['Boolean'];
  editUser: Scalars['Boolean'];
  getCueTime: Scalars['Float'];
  getLabelText: Assets;
  imReady: Scalars['Boolean'];
  invalidate: Scalars['Boolean'];
  login: LoginResponse;
  logout: Scalars['Boolean'];
  myClassId: Scalars['Float'];
  nextStage: Scalars['Boolean'];
  prevStage: Scalars['Boolean'];
  readyUser: Scalars['Boolean'];
  register: Scalars['Boolean'];
  registerStudent: LoginResponse;
  revokeRefreshTokensForUser: Scalars['Boolean'];
  selectCake: Scalars['Boolean'];
  selectCard: Scalars['Boolean'];
  selectDinner: Scalars['Boolean'];
  selectFlowers: Scalars['Boolean'];
  selectWatch: Scalars['Boolean'];
  signUrl: Scalars['String'];
};


export type MutationCreateClassArgs = {
  nGroups: Scalars['Float'];
  name: Scalars['String'];
};


export type MutationDeleteClassesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUsersArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationEditClassArgs = {
  id: Scalars['Float'];
  nGroups?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationEditLabelTextArgs = {
  fieldId: Scalars['Float'];
  text: Scalars['String'];
};


export type MutationEditUserArgs = {
  id: Scalars['Int'];
  isReady?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  permLevel?: InputMaybe<Scalars['Float']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationGetCueTimeArgs = {
  id: Scalars['Float'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationNextStageArgs = {
  id: Scalars['Float'];
};


export type MutationPrevStageArgs = {
  id: Scalars['Float'];
};


export type MutationReadyUserArgs = {
  id: Scalars['Int'];
  isReady: Scalars['Boolean'];
};


export type MutationRegisterArgs = {
  password: Scalars['String'];
  permLevel: Scalars['Float'];
  username: Scalars['String'];
};


export type MutationRegisterStudentArgs = {
  code: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRevokeRefreshTokensForUserArgs = {
  userId: Scalars['Int'];
};


export type MutationSelectCakeArgs = {
  cakeNumber: Scalars['Int'];
};


export type MutationSelectCardArgs = {
  cardNumber: Scalars['Int'];
  gameId: Scalars['Int'];
};


export type MutationSelectDinnerArgs = {
  dinnerNumber: Scalars['Int'];
};


export type MutationSelectFlowersArgs = {
  selectFlowers: Scalars['Boolean'];
};


export type MutationSelectWatchArgs = {
  selectWatch: Scalars['Boolean'];
};


export type MutationSignUrlArgs = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  bye: Scalars['String'];
  checkClass: Scalars['Boolean'];
  classes: Array<Class>;
  getClass: Class;
  getGame: Game;
  getGameStage: Scalars['Float'];
  getRankings: Array<User>;
  me?: Maybe<User>;
  users: Array<User>;
};


export type QueryCheckClassArgs = {
  code: Scalars['String'];
};


export type QueryClassesArgs = {
  all: Scalars['Boolean'];
};


export type QueryGetClassArgs = {
  id: Scalars['Float'];
};


export type QueryGetGameArgs = {
  id: Scalars['Float'];
};


export type QueryGetGameStageArgs = {
  id: Scalars['Float'];
};


export type QueryGetRankingsArgs = {
  classId: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  finalCCBal: Scalars['Float'];
  finalSavings: Scalars['Float'];
  id: Scalars['Int'];
  isReady: Scalars['Boolean'];
  moneyData: Scalars['String'];
  permLevel: Scalars['Float'];
  salary: Scalars['Float'];
  selectedCard: Scalars['Float'];
  studentClass: Class;
  totalInterest: Scalars['Float'];
  trainerClasses: Array<Class>;
  username: Scalars['String'];
};

export type CheckClassQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type CheckClassQuery = { __typename?: 'Query', checkClass: boolean };

export type CheckInvalidationMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckInvalidationMutation = { __typename?: 'Mutation', checkInvalidation: boolean };

export type CheckReadyMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckReadyMutation = { __typename?: 'Mutation', checkReady: boolean };

export type ClassesQueryVariables = Exact<{
  all: Scalars['Boolean'];
}>;


export type ClassesQuery = { __typename?: 'Query', classes: Array<{ __typename?: 'Class', id: number, name: string, status: number, code: string, dateCreated: string, nGroups: number, trainer: { __typename?: 'User', username: string }, game: { __typename?: 'Game', id: number, stage: number } }> };

export type CreateClassMutationVariables = Exact<{
  nGroups: Scalars['Float'];
  name: Scalars['String'];
}>;


export type CreateClassMutation = { __typename?: 'Mutation', createClass: boolean };

export type DeleteClassesMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteClassesMutation = { __typename?: 'Mutation', deleteClasses: boolean };

export type DeleteUserMutationVariables = Exact<{
  deleteUserId: Scalars['Int'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type DeleteUsersMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteUsersMutation = { __typename?: 'Mutation', deleteUsers: boolean };

export type DrawEmploymentMutationVariables = Exact<{ [key: string]: never; }>;


export type DrawEmploymentMutation = { __typename?: 'Mutation', drawEmployment: number };

export type DrawRetrenchMutationVariables = Exact<{ [key: string]: never; }>;


export type DrawRetrenchMutation = { __typename?: 'Mutation', drawRetrench: number };

export type EditClassMutationVariables = Exact<{
  editClassId: Scalars['Float'];
  nGroups?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
}>;


export type EditClassMutation = { __typename?: 'Mutation', editClass: boolean };

export type EditLabelTextMutationVariables = Exact<{
  text: Scalars['String'];
  fieldId: Scalars['Float'];
}>;


export type EditLabelTextMutation = { __typename?: 'Mutation', editLabelText: boolean };

export type EditUserMutationVariables = Exact<{
  editUserId: Scalars['Int'];
  isReady?: Maybe<Scalars['Boolean']>;
  permLevel?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
}>;


export type EditUserMutation = { __typename?: 'Mutation', editUser: boolean };

export type GetCardAvailabilityQueryVariables = Exact<{
  getGameId: Scalars['Float'];
}>;


export type GetCardAvailabilityQuery = { __typename?: 'Query', getGame: { __typename?: 'Game', cardOneAvailable: number, cardTwoAvailable: number } };

export type GetCueTimeMutationVariables = Exact<{
  getCueTimeId: Scalars['Float'];
}>;


export type GetCueTimeMutation = { __typename?: 'Mutation', getCueTime: number };

export type GetGameStageQueryVariables = Exact<{
  getGameStageId: Scalars['Float'];
}>;


export type GetGameStageQuery = { __typename?: 'Query', getGameStage: number };

export type GetLabelTextMutationVariables = Exact<{ [key: string]: never; }>;


export type GetLabelTextMutation = { __typename?: 'Mutation', getLabelText: { __typename?: 'Assets', id: number, cardOne: string, cardTwo: string, cakeOne: string, cakeTwo: string, dinnerOne: string, dinnerTwo: string } };

export type GetMyClassIdMutationVariables = Exact<{ [key: string]: never; }>;


export type GetMyClassIdMutation = { __typename?: 'Mutation', myClassId: number };

export type GetMyMoneyDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyMoneyDataQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: number, moneyData: string } | null | undefined };

export type GetMyScoresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyScoresQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: number, finalCCBal: number, finalSavings: number } | null | undefined };

export type GetOneClassQueryVariables = Exact<{
  getClassId: Scalars['Float'];
}>;


export type GetOneClassQuery = { __typename?: 'Query', getClass: { __typename?: 'Class', id: number, name: string, nGroups: number, status: number, code: string, dateCreated: string, trainer: { __typename?: 'User', username: string }, students?: Array<{ __typename?: 'User', id: number, username: string }> | null | undefined, game: { __typename?: 'Game', id: number, stage: number } } };

export type GetRankingsQueryVariables = Exact<{
  classId: Scalars['Float'];
}>;


export type GetRankingsQuery = { __typename?: 'Query', getRankings: Array<{ __typename?: 'User', id: number, username: string, finalCCBal: number, finalSavings: number, totalInterest: number, moneyData: string }> };

export type GetStudentsQueryVariables = Exact<{
  getClassId: Scalars['Float'];
}>;


export type GetStudentsQuery = { __typename?: 'Query', getClass: { __typename?: 'Class', students?: Array<{ __typename?: 'User', id: number, username: string, isReady: boolean }> | null | undefined } };

export type ImReadyMutationVariables = Exact<{ [key: string]: never; }>;


export type ImReadyMutation = { __typename?: 'Mutation', imReady: boolean };

export type InvalidateMutationVariables = Exact<{ [key: string]: never; }>;


export type InvalidateMutation = { __typename?: 'Mutation', invalidate: boolean };

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'User', id: number, username: string } } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: number } | null | undefined };

export type NextStageMutationVariables = Exact<{
  nextStageId: Scalars['Float'];
}>;


export type NextStageMutation = { __typename?: 'Mutation', nextStage: boolean };

export type PrevStageMutationVariables = Exact<{
  prevStageId: Scalars['Float'];
}>;


export type PrevStageMutation = { __typename?: 'Mutation', prevStage: boolean };

export type ReadyUserMutationVariables = Exact<{
  isReady: Scalars['Boolean'];
  readyUserId: Scalars['Int'];
}>;


export type ReadyUserMutation = { __typename?: 'Mutation', readyUser: boolean };

export type RegisterMutationVariables = Exact<{
  permLevel: Scalars['Float'];
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: boolean };

export type RegisterStudentMutationVariables = Exact<{
  code: Scalars['String'];
  username: Scalars['String'];
}>;


export type RegisterStudentMutation = { __typename?: 'Mutation', registerStudent: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'User', id: number, username: string } } };

export type SelectCakeMutationVariables = Exact<{
  optionNumber: Scalars['Int'];
}>;


export type SelectCakeMutation = { __typename?: 'Mutation', selectCake: boolean };

export type SelectCardMutationVariables = Exact<{
  gameId: Scalars['Int'];
  cardNumber: Scalars['Int'];
}>;


export type SelectCardMutation = { __typename?: 'Mutation', selectCard: boolean };

export type SelectDinnerMutationVariables = Exact<{
  optionNumber: Scalars['Int'];
}>;


export type SelectDinnerMutation = { __typename?: 'Mutation', selectDinner: boolean };

export type SelectFlowersMutationVariables = Exact<{
  optionNumber: Scalars['Boolean'];
}>;


export type SelectFlowersMutation = { __typename?: 'Mutation', selectFlowers: boolean };

export type SelectWatchMutationVariables = Exact<{
  selectWatch: Scalars['Boolean'];
}>;


export type SelectWatchMutation = { __typename?: 'Mutation', selectWatch: boolean };

export type SignUrlMutationVariables = Exact<{
  fileType: Scalars['String'];
  fileName: Scalars['String'];
}>;


export type SignUrlMutation = { __typename?: 'Mutation', signUrl: string };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, username: string, permLevel: number }> };


export const CheckClassDocument = gql`
    query CheckClass($code: String!) {
  checkClass(code: $code)
}
    `;

/**
 * __useCheckClassQuery__
 *
 * To run a query within a React component, call `useCheckClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckClassQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCheckClassQuery(baseOptions: Apollo.QueryHookOptions<CheckClassQuery, CheckClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckClassQuery, CheckClassQueryVariables>(CheckClassDocument, options);
      }
export function useCheckClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckClassQuery, CheckClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckClassQuery, CheckClassQueryVariables>(CheckClassDocument, options);
        }
export type CheckClassQueryHookResult = ReturnType<typeof useCheckClassQuery>;
export type CheckClassLazyQueryHookResult = ReturnType<typeof useCheckClassLazyQuery>;
export type CheckClassQueryResult = Apollo.QueryResult<CheckClassQuery, CheckClassQueryVariables>;
export const CheckInvalidationDocument = gql`
    mutation CheckInvalidation {
  checkInvalidation
}
    `;
export type CheckInvalidationMutationFn = Apollo.MutationFunction<CheckInvalidationMutation, CheckInvalidationMutationVariables>;

/**
 * __useCheckInvalidationMutation__
 *
 * To run a mutation, you first call `useCheckInvalidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInvalidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInvalidationMutation, { data, loading, error }] = useCheckInvalidationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckInvalidationMutation(baseOptions?: Apollo.MutationHookOptions<CheckInvalidationMutation, CheckInvalidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInvalidationMutation, CheckInvalidationMutationVariables>(CheckInvalidationDocument, options);
      }
export type CheckInvalidationMutationHookResult = ReturnType<typeof useCheckInvalidationMutation>;
export type CheckInvalidationMutationResult = Apollo.MutationResult<CheckInvalidationMutation>;
export type CheckInvalidationMutationOptions = Apollo.BaseMutationOptions<CheckInvalidationMutation, CheckInvalidationMutationVariables>;
export const CheckReadyDocument = gql`
    mutation CheckReady {
  checkReady
}
    `;
export type CheckReadyMutationFn = Apollo.MutationFunction<CheckReadyMutation, CheckReadyMutationVariables>;

/**
 * __useCheckReadyMutation__
 *
 * To run a mutation, you first call `useCheckReadyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckReadyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkReadyMutation, { data, loading, error }] = useCheckReadyMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckReadyMutation(baseOptions?: Apollo.MutationHookOptions<CheckReadyMutation, CheckReadyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckReadyMutation, CheckReadyMutationVariables>(CheckReadyDocument, options);
      }
export type CheckReadyMutationHookResult = ReturnType<typeof useCheckReadyMutation>;
export type CheckReadyMutationResult = Apollo.MutationResult<CheckReadyMutation>;
export type CheckReadyMutationOptions = Apollo.BaseMutationOptions<CheckReadyMutation, CheckReadyMutationVariables>;
export const ClassesDocument = gql`
    query Classes($all: Boolean!) {
  classes(all: $all) {
    id
    name
    status
    code
    dateCreated
    nGroups
    trainer {
      username
    }
    game {
      id
      stage
    }
  }
}
    `;

/**
 * __useClassesQuery__
 *
 * To run a query within a React component, call `useClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesQuery({
 *   variables: {
 *      all: // value for 'all'
 *   },
 * });
 */
export function useClassesQuery(baseOptions: Apollo.QueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
      }
export function useClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
        }
export type ClassesQueryHookResult = ReturnType<typeof useClassesQuery>;
export type ClassesLazyQueryHookResult = ReturnType<typeof useClassesLazyQuery>;
export type ClassesQueryResult = Apollo.QueryResult<ClassesQuery, ClassesQueryVariables>;
export const CreateClassDocument = gql`
    mutation CreateClass($nGroups: Float!, $name: String!) {
  createClass(nGroups: $nGroups, name: $name)
}
    `;
export type CreateClassMutationFn = Apollo.MutationFunction<CreateClassMutation, CreateClassMutationVariables>;

/**
 * __useCreateClassMutation__
 *
 * To run a mutation, you first call `useCreateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassMutation, { data, loading, error }] = useCreateClassMutation({
 *   variables: {
 *      nGroups: // value for 'nGroups'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateClassMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassMutation, CreateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassMutation, CreateClassMutationVariables>(CreateClassDocument, options);
      }
export type CreateClassMutationHookResult = ReturnType<typeof useCreateClassMutation>;
export type CreateClassMutationResult = Apollo.MutationResult<CreateClassMutation>;
export type CreateClassMutationOptions = Apollo.BaseMutationOptions<CreateClassMutation, CreateClassMutationVariables>;
export const DeleteClassesDocument = gql`
    mutation DeleteClasses($ids: [Int!]!) {
  deleteClasses(ids: $ids)
}
    `;
export type DeleteClassesMutationFn = Apollo.MutationFunction<DeleteClassesMutation, DeleteClassesMutationVariables>;

/**
 * __useDeleteClassesMutation__
 *
 * To run a mutation, you first call `useDeleteClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassesMutation, { data, loading, error }] = useDeleteClassesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteClassesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassesMutation, DeleteClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassesMutation, DeleteClassesMutationVariables>(DeleteClassesDocument, options);
      }
export type DeleteClassesMutationHookResult = ReturnType<typeof useDeleteClassesMutation>;
export type DeleteClassesMutationResult = Apollo.MutationResult<DeleteClassesMutation>;
export type DeleteClassesMutationOptions = Apollo.BaseMutationOptions<DeleteClassesMutation, DeleteClassesMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($deleteUserId: Int!) {
  deleteUser(id: $deleteUserId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deleteUserId: // value for 'deleteUserId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteUsersDocument = gql`
    mutation DeleteUsers($ids: [Int!]!) {
  deleteUsers(ids: $ids)
}
    `;
export type DeleteUsersMutationFn = Apollo.MutationFunction<DeleteUsersMutation, DeleteUsersMutationVariables>;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUsersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersMutation, DeleteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument, options);
      }
export type DeleteUsersMutationHookResult = ReturnType<typeof useDeleteUsersMutation>;
export type DeleteUsersMutationResult = Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<DeleteUsersMutation, DeleteUsersMutationVariables>;
export const DrawEmploymentDocument = gql`
    mutation DrawEmployment {
  drawEmployment
}
    `;
export type DrawEmploymentMutationFn = Apollo.MutationFunction<DrawEmploymentMutation, DrawEmploymentMutationVariables>;

/**
 * __useDrawEmploymentMutation__
 *
 * To run a mutation, you first call `useDrawEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDrawEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [drawEmploymentMutation, { data, loading, error }] = useDrawEmploymentMutation({
 *   variables: {
 *   },
 * });
 */
export function useDrawEmploymentMutation(baseOptions?: Apollo.MutationHookOptions<DrawEmploymentMutation, DrawEmploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DrawEmploymentMutation, DrawEmploymentMutationVariables>(DrawEmploymentDocument, options);
      }
export type DrawEmploymentMutationHookResult = ReturnType<typeof useDrawEmploymentMutation>;
export type DrawEmploymentMutationResult = Apollo.MutationResult<DrawEmploymentMutation>;
export type DrawEmploymentMutationOptions = Apollo.BaseMutationOptions<DrawEmploymentMutation, DrawEmploymentMutationVariables>;
export const DrawRetrenchDocument = gql`
    mutation DrawRetrench {
  drawRetrench
}
    `;
export type DrawRetrenchMutationFn = Apollo.MutationFunction<DrawRetrenchMutation, DrawRetrenchMutationVariables>;

/**
 * __useDrawRetrenchMutation__
 *
 * To run a mutation, you first call `useDrawRetrenchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDrawRetrenchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [drawRetrenchMutation, { data, loading, error }] = useDrawRetrenchMutation({
 *   variables: {
 *   },
 * });
 */
export function useDrawRetrenchMutation(baseOptions?: Apollo.MutationHookOptions<DrawRetrenchMutation, DrawRetrenchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DrawRetrenchMutation, DrawRetrenchMutationVariables>(DrawRetrenchDocument, options);
      }
export type DrawRetrenchMutationHookResult = ReturnType<typeof useDrawRetrenchMutation>;
export type DrawRetrenchMutationResult = Apollo.MutationResult<DrawRetrenchMutation>;
export type DrawRetrenchMutationOptions = Apollo.BaseMutationOptions<DrawRetrenchMutation, DrawRetrenchMutationVariables>;
export const EditClassDocument = gql`
    mutation EditClass($editClassId: Float!, $nGroups: Float, $name: String) {
  editClass(id: $editClassId, nGroups: $nGroups, name: $name)
}
    `;
export type EditClassMutationFn = Apollo.MutationFunction<EditClassMutation, EditClassMutationVariables>;

/**
 * __useEditClassMutation__
 *
 * To run a mutation, you first call `useEditClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editClassMutation, { data, loading, error }] = useEditClassMutation({
 *   variables: {
 *      editClassId: // value for 'editClassId'
 *      nGroups: // value for 'nGroups'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditClassMutation(baseOptions?: Apollo.MutationHookOptions<EditClassMutation, EditClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditClassMutation, EditClassMutationVariables>(EditClassDocument, options);
      }
export type EditClassMutationHookResult = ReturnType<typeof useEditClassMutation>;
export type EditClassMutationResult = Apollo.MutationResult<EditClassMutation>;
export type EditClassMutationOptions = Apollo.BaseMutationOptions<EditClassMutation, EditClassMutationVariables>;
export const EditLabelTextDocument = gql`
    mutation EditLabelText($text: String!, $fieldId: Float!) {
  editLabelText(text: $text, fieldId: $fieldId)
}
    `;
export type EditLabelTextMutationFn = Apollo.MutationFunction<EditLabelTextMutation, EditLabelTextMutationVariables>;

/**
 * __useEditLabelTextMutation__
 *
 * To run a mutation, you first call `useEditLabelTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLabelTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLabelTextMutation, { data, loading, error }] = useEditLabelTextMutation({
 *   variables: {
 *      text: // value for 'text'
 *      fieldId: // value for 'fieldId'
 *   },
 * });
 */
export function useEditLabelTextMutation(baseOptions?: Apollo.MutationHookOptions<EditLabelTextMutation, EditLabelTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditLabelTextMutation, EditLabelTextMutationVariables>(EditLabelTextDocument, options);
      }
export type EditLabelTextMutationHookResult = ReturnType<typeof useEditLabelTextMutation>;
export type EditLabelTextMutationResult = Apollo.MutationResult<EditLabelTextMutation>;
export type EditLabelTextMutationOptions = Apollo.BaseMutationOptions<EditLabelTextMutation, EditLabelTextMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($editUserId: Int!, $isReady: Boolean, $permLevel: Float, $password: String, $username: String) {
  editUser(
    id: $editUserId
    isReady: $isReady
    permLevel: $permLevel
    password: $password
    username: $username
  )
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      editUserId: // value for 'editUserId'
 *      isReady: // value for 'isReady'
 *      permLevel: // value for 'permLevel'
 *      password: // value for 'password'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const GetCardAvailabilityDocument = gql`
    query GetCardAvailability($getGameId: Float!) {
  getGame(id: $getGameId) {
    cardOneAvailable
    cardTwoAvailable
  }
}
    `;

/**
 * __useGetCardAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetCardAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardAvailabilityQuery({
 *   variables: {
 *      getGameId: // value for 'getGameId'
 *   },
 * });
 */
export function useGetCardAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<GetCardAvailabilityQuery, GetCardAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardAvailabilityQuery, GetCardAvailabilityQueryVariables>(GetCardAvailabilityDocument, options);
      }
export function useGetCardAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardAvailabilityQuery, GetCardAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardAvailabilityQuery, GetCardAvailabilityQueryVariables>(GetCardAvailabilityDocument, options);
        }
export type GetCardAvailabilityQueryHookResult = ReturnType<typeof useGetCardAvailabilityQuery>;
export type GetCardAvailabilityLazyQueryHookResult = ReturnType<typeof useGetCardAvailabilityLazyQuery>;
export type GetCardAvailabilityQueryResult = Apollo.QueryResult<GetCardAvailabilityQuery, GetCardAvailabilityQueryVariables>;
export const GetCueTimeDocument = gql`
    mutation GetCueTime($getCueTimeId: Float!) {
  getCueTime(id: $getCueTimeId)
}
    `;
export type GetCueTimeMutationFn = Apollo.MutationFunction<GetCueTimeMutation, GetCueTimeMutationVariables>;

/**
 * __useGetCueTimeMutation__
 *
 * To run a mutation, you first call `useGetCueTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCueTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCueTimeMutation, { data, loading, error }] = useGetCueTimeMutation({
 *   variables: {
 *      getCueTimeId: // value for 'getCueTimeId'
 *   },
 * });
 */
export function useGetCueTimeMutation(baseOptions?: Apollo.MutationHookOptions<GetCueTimeMutation, GetCueTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCueTimeMutation, GetCueTimeMutationVariables>(GetCueTimeDocument, options);
      }
export type GetCueTimeMutationHookResult = ReturnType<typeof useGetCueTimeMutation>;
export type GetCueTimeMutationResult = Apollo.MutationResult<GetCueTimeMutation>;
export type GetCueTimeMutationOptions = Apollo.BaseMutationOptions<GetCueTimeMutation, GetCueTimeMutationVariables>;
export const GetGameStageDocument = gql`
    query GetGameStage($getGameStageId: Float!) {
  getGameStage(id: $getGameStageId)
}
    `;

/**
 * __useGetGameStageQuery__
 *
 * To run a query within a React component, call `useGetGameStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameStageQuery({
 *   variables: {
 *      getGameStageId: // value for 'getGameStageId'
 *   },
 * });
 */
export function useGetGameStageQuery(baseOptions: Apollo.QueryHookOptions<GetGameStageQuery, GetGameStageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameStageQuery, GetGameStageQueryVariables>(GetGameStageDocument, options);
      }
export function useGetGameStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameStageQuery, GetGameStageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameStageQuery, GetGameStageQueryVariables>(GetGameStageDocument, options);
        }
export type GetGameStageQueryHookResult = ReturnType<typeof useGetGameStageQuery>;
export type GetGameStageLazyQueryHookResult = ReturnType<typeof useGetGameStageLazyQuery>;
export type GetGameStageQueryResult = Apollo.QueryResult<GetGameStageQuery, GetGameStageQueryVariables>;
export const GetLabelTextDocument = gql`
    mutation GetLabelText {
  getLabelText {
    id
    cardOne
    cardTwo
    cakeOne
    cakeTwo
    dinnerOne
    dinnerTwo
  }
}
    `;
export type GetLabelTextMutationFn = Apollo.MutationFunction<GetLabelTextMutation, GetLabelTextMutationVariables>;

/**
 * __useGetLabelTextMutation__
 *
 * To run a mutation, you first call `useGetLabelTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetLabelTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getLabelTextMutation, { data, loading, error }] = useGetLabelTextMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetLabelTextMutation(baseOptions?: Apollo.MutationHookOptions<GetLabelTextMutation, GetLabelTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetLabelTextMutation, GetLabelTextMutationVariables>(GetLabelTextDocument, options);
      }
export type GetLabelTextMutationHookResult = ReturnType<typeof useGetLabelTextMutation>;
export type GetLabelTextMutationResult = Apollo.MutationResult<GetLabelTextMutation>;
export type GetLabelTextMutationOptions = Apollo.BaseMutationOptions<GetLabelTextMutation, GetLabelTextMutationVariables>;
export const GetMyClassIdDocument = gql`
    mutation GetMyClassId {
  myClassId
}
    `;
export type GetMyClassIdMutationFn = Apollo.MutationFunction<GetMyClassIdMutation, GetMyClassIdMutationVariables>;

/**
 * __useGetMyClassIdMutation__
 *
 * To run a mutation, you first call `useGetMyClassIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetMyClassIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getMyClassIdMutation, { data, loading, error }] = useGetMyClassIdMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetMyClassIdMutation(baseOptions?: Apollo.MutationHookOptions<GetMyClassIdMutation, GetMyClassIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetMyClassIdMutation, GetMyClassIdMutationVariables>(GetMyClassIdDocument, options);
      }
export type GetMyClassIdMutationHookResult = ReturnType<typeof useGetMyClassIdMutation>;
export type GetMyClassIdMutationResult = Apollo.MutationResult<GetMyClassIdMutation>;
export type GetMyClassIdMutationOptions = Apollo.BaseMutationOptions<GetMyClassIdMutation, GetMyClassIdMutationVariables>;
export const GetMyMoneyDataDocument = gql`
    query GetMyMoneyData {
  me {
    id
    moneyData
  }
}
    `;

/**
 * __useGetMyMoneyDataQuery__
 *
 * To run a query within a React component, call `useGetMyMoneyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyMoneyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyMoneyDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyMoneyDataQuery(baseOptions?: Apollo.QueryHookOptions<GetMyMoneyDataQuery, GetMyMoneyDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyMoneyDataQuery, GetMyMoneyDataQueryVariables>(GetMyMoneyDataDocument, options);
      }
export function useGetMyMoneyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyMoneyDataQuery, GetMyMoneyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyMoneyDataQuery, GetMyMoneyDataQueryVariables>(GetMyMoneyDataDocument, options);
        }
export type GetMyMoneyDataQueryHookResult = ReturnType<typeof useGetMyMoneyDataQuery>;
export type GetMyMoneyDataLazyQueryHookResult = ReturnType<typeof useGetMyMoneyDataLazyQuery>;
export type GetMyMoneyDataQueryResult = Apollo.QueryResult<GetMyMoneyDataQuery, GetMyMoneyDataQueryVariables>;
export const GetMyScoresDocument = gql`
    query GetMyScores {
  me {
    id
    finalCCBal
    finalSavings
  }
}
    `;

/**
 * __useGetMyScoresQuery__
 *
 * To run a query within a React component, call `useGetMyScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyScoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyScoresQuery(baseOptions?: Apollo.QueryHookOptions<GetMyScoresQuery, GetMyScoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyScoresQuery, GetMyScoresQueryVariables>(GetMyScoresDocument, options);
      }
export function useGetMyScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyScoresQuery, GetMyScoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyScoresQuery, GetMyScoresQueryVariables>(GetMyScoresDocument, options);
        }
export type GetMyScoresQueryHookResult = ReturnType<typeof useGetMyScoresQuery>;
export type GetMyScoresLazyQueryHookResult = ReturnType<typeof useGetMyScoresLazyQuery>;
export type GetMyScoresQueryResult = Apollo.QueryResult<GetMyScoresQuery, GetMyScoresQueryVariables>;
export const GetOneClassDocument = gql`
    query GetOneClass($getClassId: Float!) {
  getClass(id: $getClassId) {
    id
    name
    nGroups
    status
    code
    dateCreated
    trainer {
      username
    }
    students {
      id
      username
    }
    game {
      id
      stage
    }
  }
}
    `;

/**
 * __useGetOneClassQuery__
 *
 * To run a query within a React component, call `useGetOneClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneClassQuery({
 *   variables: {
 *      getClassId: // value for 'getClassId'
 *   },
 * });
 */
export function useGetOneClassQuery(baseOptions: Apollo.QueryHookOptions<GetOneClassQuery, GetOneClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneClassQuery, GetOneClassQueryVariables>(GetOneClassDocument, options);
      }
export function useGetOneClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneClassQuery, GetOneClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneClassQuery, GetOneClassQueryVariables>(GetOneClassDocument, options);
        }
export type GetOneClassQueryHookResult = ReturnType<typeof useGetOneClassQuery>;
export type GetOneClassLazyQueryHookResult = ReturnType<typeof useGetOneClassLazyQuery>;
export type GetOneClassQueryResult = Apollo.QueryResult<GetOneClassQuery, GetOneClassQueryVariables>;
export const GetRankingsDocument = gql`
    query GetRankings($classId: Float!) {
  getRankings(classId: $classId) {
    id
    username
    finalCCBal
    finalSavings
    totalInterest
    moneyData
  }
}
    `;

/**
 * __useGetRankingsQuery__
 *
 * To run a query within a React component, call `useGetRankingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRankingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRankingsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetRankingsQuery(baseOptions: Apollo.QueryHookOptions<GetRankingsQuery, GetRankingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRankingsQuery, GetRankingsQueryVariables>(GetRankingsDocument, options);
      }
export function useGetRankingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRankingsQuery, GetRankingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRankingsQuery, GetRankingsQueryVariables>(GetRankingsDocument, options);
        }
export type GetRankingsQueryHookResult = ReturnType<typeof useGetRankingsQuery>;
export type GetRankingsLazyQueryHookResult = ReturnType<typeof useGetRankingsLazyQuery>;
export type GetRankingsQueryResult = Apollo.QueryResult<GetRankingsQuery, GetRankingsQueryVariables>;
export const GetStudentsDocument = gql`
    query GetStudents($getClassId: Float!) {
  getClass(id: $getClassId) {
    students {
      id
      username
      isReady
    }
  }
}
    `;

/**
 * __useGetStudentsQuery__
 *
 * To run a query within a React component, call `useGetStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsQuery({
 *   variables: {
 *      getClassId: // value for 'getClassId'
 *   },
 * });
 */
export function useGetStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentsQuery, GetStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudentsDocument, options);
      }
export function useGetStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsQuery, GetStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudentsDocument, options);
        }
export type GetStudentsQueryHookResult = ReturnType<typeof useGetStudentsQuery>;
export type GetStudentsLazyQueryHookResult = ReturnType<typeof useGetStudentsLazyQuery>;
export type GetStudentsQueryResult = Apollo.QueryResult<GetStudentsQuery, GetStudentsQueryVariables>;
export const ImReadyDocument = gql`
    mutation ImReady {
  imReady
}
    `;
export type ImReadyMutationFn = Apollo.MutationFunction<ImReadyMutation, ImReadyMutationVariables>;

/**
 * __useImReadyMutation__
 *
 * To run a mutation, you first call `useImReadyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImReadyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imReadyMutation, { data, loading, error }] = useImReadyMutation({
 *   variables: {
 *   },
 * });
 */
export function useImReadyMutation(baseOptions?: Apollo.MutationHookOptions<ImReadyMutation, ImReadyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImReadyMutation, ImReadyMutationVariables>(ImReadyDocument, options);
      }
export type ImReadyMutationHookResult = ReturnType<typeof useImReadyMutation>;
export type ImReadyMutationResult = Apollo.MutationResult<ImReadyMutation>;
export type ImReadyMutationOptions = Apollo.BaseMutationOptions<ImReadyMutation, ImReadyMutationVariables>;
export const InvalidateDocument = gql`
    mutation Invalidate {
  invalidate
}
    `;
export type InvalidateMutationFn = Apollo.MutationFunction<InvalidateMutation, InvalidateMutationVariables>;

/**
 * __useInvalidateMutation__
 *
 * To run a mutation, you first call `useInvalidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateMutation, { data, loading, error }] = useInvalidateMutation({
 *   variables: {
 *   },
 * });
 */
export function useInvalidateMutation(baseOptions?: Apollo.MutationHookOptions<InvalidateMutation, InvalidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvalidateMutation, InvalidateMutationVariables>(InvalidateDocument, options);
      }
export type InvalidateMutationHookResult = ReturnType<typeof useInvalidateMutation>;
export type InvalidateMutationResult = Apollo.MutationResult<InvalidateMutation>;
export type InvalidateMutationOptions = Apollo.BaseMutationOptions<InvalidateMutation, InvalidateMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    accessToken
    user {
      id
      username
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NextStageDocument = gql`
    mutation NextStage($nextStageId: Float!) {
  nextStage(id: $nextStageId)
}
    `;
export type NextStageMutationFn = Apollo.MutationFunction<NextStageMutation, NextStageMutationVariables>;

/**
 * __useNextStageMutation__
 *
 * To run a mutation, you first call `useNextStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNextStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nextStageMutation, { data, loading, error }] = useNextStageMutation({
 *   variables: {
 *      nextStageId: // value for 'nextStageId'
 *   },
 * });
 */
export function useNextStageMutation(baseOptions?: Apollo.MutationHookOptions<NextStageMutation, NextStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NextStageMutation, NextStageMutationVariables>(NextStageDocument, options);
      }
export type NextStageMutationHookResult = ReturnType<typeof useNextStageMutation>;
export type NextStageMutationResult = Apollo.MutationResult<NextStageMutation>;
export type NextStageMutationOptions = Apollo.BaseMutationOptions<NextStageMutation, NextStageMutationVariables>;
export const PrevStageDocument = gql`
    mutation PrevStage($prevStageId: Float!) {
  prevStage(id: $prevStageId)
}
    `;
export type PrevStageMutationFn = Apollo.MutationFunction<PrevStageMutation, PrevStageMutationVariables>;

/**
 * __usePrevStageMutation__
 *
 * To run a mutation, you first call `usePrevStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrevStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prevStageMutation, { data, loading, error }] = usePrevStageMutation({
 *   variables: {
 *      prevStageId: // value for 'prevStageId'
 *   },
 * });
 */
export function usePrevStageMutation(baseOptions?: Apollo.MutationHookOptions<PrevStageMutation, PrevStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrevStageMutation, PrevStageMutationVariables>(PrevStageDocument, options);
      }
export type PrevStageMutationHookResult = ReturnType<typeof usePrevStageMutation>;
export type PrevStageMutationResult = Apollo.MutationResult<PrevStageMutation>;
export type PrevStageMutationOptions = Apollo.BaseMutationOptions<PrevStageMutation, PrevStageMutationVariables>;
export const ReadyUserDocument = gql`
    mutation ReadyUser($isReady: Boolean!, $readyUserId: Int!) {
  readyUser(isReady: $isReady, id: $readyUserId)
}
    `;
export type ReadyUserMutationFn = Apollo.MutationFunction<ReadyUserMutation, ReadyUserMutationVariables>;

/**
 * __useReadyUserMutation__
 *
 * To run a mutation, you first call `useReadyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readyUserMutation, { data, loading, error }] = useReadyUserMutation({
 *   variables: {
 *      isReady: // value for 'isReady'
 *      readyUserId: // value for 'readyUserId'
 *   },
 * });
 */
export function useReadyUserMutation(baseOptions?: Apollo.MutationHookOptions<ReadyUserMutation, ReadyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadyUserMutation, ReadyUserMutationVariables>(ReadyUserDocument, options);
      }
export type ReadyUserMutationHookResult = ReturnType<typeof useReadyUserMutation>;
export type ReadyUserMutationResult = Apollo.MutationResult<ReadyUserMutation>;
export type ReadyUserMutationOptions = Apollo.BaseMutationOptions<ReadyUserMutation, ReadyUserMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($permLevel: Float!, $username: String!, $password: String!) {
  register(permLevel: $permLevel, username: $username, password: $password)
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      permLevel: // value for 'permLevel'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RegisterStudentDocument = gql`
    mutation RegisterStudent($code: String!, $username: String!) {
  registerStudent(code: $code, username: $username) {
    accessToken
    user {
      id
      username
    }
  }
}
    `;
export type RegisterStudentMutationFn = Apollo.MutationFunction<RegisterStudentMutation, RegisterStudentMutationVariables>;

/**
 * __useRegisterStudentMutation__
 *
 * To run a mutation, you first call `useRegisterStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerStudentMutation, { data, loading, error }] = useRegisterStudentMutation({
 *   variables: {
 *      code: // value for 'code'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useRegisterStudentMutation(baseOptions?: Apollo.MutationHookOptions<RegisterStudentMutation, RegisterStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterStudentMutation, RegisterStudentMutationVariables>(RegisterStudentDocument, options);
      }
export type RegisterStudentMutationHookResult = ReturnType<typeof useRegisterStudentMutation>;
export type RegisterStudentMutationResult = Apollo.MutationResult<RegisterStudentMutation>;
export type RegisterStudentMutationOptions = Apollo.BaseMutationOptions<RegisterStudentMutation, RegisterStudentMutationVariables>;
export const SelectCakeDocument = gql`
    mutation SelectCake($optionNumber: Int!) {
  selectCake(cakeNumber: $optionNumber)
}
    `;
export type SelectCakeMutationFn = Apollo.MutationFunction<SelectCakeMutation, SelectCakeMutationVariables>;

/**
 * __useSelectCakeMutation__
 *
 * To run a mutation, you first call `useSelectCakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectCakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectCakeMutation, { data, loading, error }] = useSelectCakeMutation({
 *   variables: {
 *      optionNumber: // value for 'optionNumber'
 *   },
 * });
 */
export function useSelectCakeMutation(baseOptions?: Apollo.MutationHookOptions<SelectCakeMutation, SelectCakeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectCakeMutation, SelectCakeMutationVariables>(SelectCakeDocument, options);
      }
export type SelectCakeMutationHookResult = ReturnType<typeof useSelectCakeMutation>;
export type SelectCakeMutationResult = Apollo.MutationResult<SelectCakeMutation>;
export type SelectCakeMutationOptions = Apollo.BaseMutationOptions<SelectCakeMutation, SelectCakeMutationVariables>;
export const SelectCardDocument = gql`
    mutation SelectCard($gameId: Int!, $cardNumber: Int!) {
  selectCard(gameId: $gameId, cardNumber: $cardNumber)
}
    `;
export type SelectCardMutationFn = Apollo.MutationFunction<SelectCardMutation, SelectCardMutationVariables>;

/**
 * __useSelectCardMutation__
 *
 * To run a mutation, you first call `useSelectCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectCardMutation, { data, loading, error }] = useSelectCardMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      cardNumber: // value for 'cardNumber'
 *   },
 * });
 */
export function useSelectCardMutation(baseOptions?: Apollo.MutationHookOptions<SelectCardMutation, SelectCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectCardMutation, SelectCardMutationVariables>(SelectCardDocument, options);
      }
export type SelectCardMutationHookResult = ReturnType<typeof useSelectCardMutation>;
export type SelectCardMutationResult = Apollo.MutationResult<SelectCardMutation>;
export type SelectCardMutationOptions = Apollo.BaseMutationOptions<SelectCardMutation, SelectCardMutationVariables>;
export const SelectDinnerDocument = gql`
    mutation SelectDinner($optionNumber: Int!) {
  selectDinner(dinnerNumber: $optionNumber)
}
    `;
export type SelectDinnerMutationFn = Apollo.MutationFunction<SelectDinnerMutation, SelectDinnerMutationVariables>;

/**
 * __useSelectDinnerMutation__
 *
 * To run a mutation, you first call `useSelectDinnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectDinnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectDinnerMutation, { data, loading, error }] = useSelectDinnerMutation({
 *   variables: {
 *      optionNumber: // value for 'optionNumber'
 *   },
 * });
 */
export function useSelectDinnerMutation(baseOptions?: Apollo.MutationHookOptions<SelectDinnerMutation, SelectDinnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectDinnerMutation, SelectDinnerMutationVariables>(SelectDinnerDocument, options);
      }
export type SelectDinnerMutationHookResult = ReturnType<typeof useSelectDinnerMutation>;
export type SelectDinnerMutationResult = Apollo.MutationResult<SelectDinnerMutation>;
export type SelectDinnerMutationOptions = Apollo.BaseMutationOptions<SelectDinnerMutation, SelectDinnerMutationVariables>;
export const SelectFlowersDocument = gql`
    mutation SelectFlowers($optionNumber: Boolean!) {
  selectFlowers(selectFlowers: $optionNumber)
}
    `;
export type SelectFlowersMutationFn = Apollo.MutationFunction<SelectFlowersMutation, SelectFlowersMutationVariables>;

/**
 * __useSelectFlowersMutation__
 *
 * To run a mutation, you first call `useSelectFlowersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectFlowersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectFlowersMutation, { data, loading, error }] = useSelectFlowersMutation({
 *   variables: {
 *      optionNumber: // value for 'optionNumber'
 *   },
 * });
 */
export function useSelectFlowersMutation(baseOptions?: Apollo.MutationHookOptions<SelectFlowersMutation, SelectFlowersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectFlowersMutation, SelectFlowersMutationVariables>(SelectFlowersDocument, options);
      }
export type SelectFlowersMutationHookResult = ReturnType<typeof useSelectFlowersMutation>;
export type SelectFlowersMutationResult = Apollo.MutationResult<SelectFlowersMutation>;
export type SelectFlowersMutationOptions = Apollo.BaseMutationOptions<SelectFlowersMutation, SelectFlowersMutationVariables>;
export const SelectWatchDocument = gql`
    mutation SelectWatch($selectWatch: Boolean!) {
  selectWatch(selectWatch: $selectWatch)
}
    `;
export type SelectWatchMutationFn = Apollo.MutationFunction<SelectWatchMutation, SelectWatchMutationVariables>;

/**
 * __useSelectWatchMutation__
 *
 * To run a mutation, you first call `useSelectWatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectWatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectWatchMutation, { data, loading, error }] = useSelectWatchMutation({
 *   variables: {
 *      selectWatch: // value for 'selectWatch'
 *   },
 * });
 */
export function useSelectWatchMutation(baseOptions?: Apollo.MutationHookOptions<SelectWatchMutation, SelectWatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectWatchMutation, SelectWatchMutationVariables>(SelectWatchDocument, options);
      }
export type SelectWatchMutationHookResult = ReturnType<typeof useSelectWatchMutation>;
export type SelectWatchMutationResult = Apollo.MutationResult<SelectWatchMutation>;
export type SelectWatchMutationOptions = Apollo.BaseMutationOptions<SelectWatchMutation, SelectWatchMutationVariables>;
export const SignUrlDocument = gql`
    mutation SignUrl($fileType: String!, $fileName: String!) {
  signUrl(fileType: $fileType, fileName: $fileName)
}
    `;
export type SignUrlMutationFn = Apollo.MutationFunction<SignUrlMutation, SignUrlMutationVariables>;

/**
 * __useSignUrlMutation__
 *
 * To run a mutation, you first call `useSignUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUrlMutation, { data, loading, error }] = useSignUrlMutation({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useSignUrlMutation(baseOptions?: Apollo.MutationHookOptions<SignUrlMutation, SignUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUrlMutation, SignUrlMutationVariables>(SignUrlDocument, options);
      }
export type SignUrlMutationHookResult = ReturnType<typeof useSignUrlMutation>;
export type SignUrlMutationResult = Apollo.MutationResult<SignUrlMutation>;
export type SignUrlMutationOptions = Apollo.BaseMutationOptions<SignUrlMutation, SignUrlMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    username
    permLevel
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;