import React from "react";
import { motion } from "framer-motion";
import { Box, Container, Stack, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export default function S14EndFebruary() {
  const year = new Date().getFullYear();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Container
        sx={{ paddingTop: "20px", paddingBottom: "55px", overflow: "scroll", height: "100%" }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          style={{ height: "100%", zIndex: "100" }}
        >
          <Stack
            direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{ height: "100%", zIndex: "100" }}
          >
            <Typography variant='h2' align='center' gutterBottom>
              It is now end February {year}.
            </Typography>
            <Typography variant='h4' align='center' sx={{ paddingX: "10px" }} gutterBottom>
              Sam's savings and credit card bill have been calculated. Take a look!
            </Typography>
            <motion.div
              animate={{ y: [0, 10, 0, 10, 0, 0] }}
              transition={{ repeat: Infinity, duration: 2, times: [0, 0.175, 0.35, 0.525, 0.7, 1] }}
            >
              <ArrowDownwardIcon color='primary' fontSize='large' />
            </motion.div>
          </Stack>
        </motion.div>
      </Container>
      <motion.img
        src='/game/4pay.png'
        style={{
          width: "100%",
          paddingTop: "20px",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "-1",
        }}
        initial={{ y: -300 }}
        animate={{ y: 0 }}
      />
      <motion.img
        src='/game/4paid.png'
        style={{ width: "100%", position: "absolute", bottom: "0", left: "0", zIndex: "-100" }}
        initial={{ bottom: -300 }}
        animate={{ bottom: 0 }}
      />
    </Box>
  );
}
