import React from "react";
import { useImReadyMutation } from "../../generated/graphql";
import { motion } from "framer-motion";
import { Box, Container, Typography, Stack } from "@mui/material";

export default function S10January() {
  const [imReady] = useImReadyMutation();
  const year = new Date().getFullYear();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Container
        sx={{ paddingTop: "20px", paddingBottom: "55px", overflow: "scroll", maxHeight: "100%" }}
      >
        <Stack
          direction='column'
          spacing={2}
          alignItems='center'
          sx={{ marginX: "15px", textAlign: "center" }}
        >
          <Typography variant='h2' align='center'>
            February {year + 1}
          </Typography>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.8 }}
          >
            <Typography variant='h5'>Sam's company is not doing well.</Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 3, duration: 0.8 }}
          >
            <Typography variant='h5'>
              Sam is told to either take a 50% pay cut for the next 6 months, or get retrenched.
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 6, duration: 0.8 }}
          >
            <Typography variant='h5'>You will draw a card to determine your fate.</Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 8, duration: 0.8 }}
          >
            <Typography variant='h5'>
              Sam still has his regular monthly expenses of $1,700 to pay for in February.
            </Typography>
          </motion.div>
          <motion.img
            src='/game/12sam.png'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 9, duration: 0.8 }}
            onAnimationComplete={() => imReady()}
          />
        </Stack>
      </Container>
    </Box>
  );
}
