import { Assets } from "./generated/graphql";

let cardOne = "Altitude Card";
let cardTwo = "One Card";
let cakeOne = "Annabella Patisserie";
let cakeTwo = "Polar Cakes & Puffs";
let dinnerOne = "Bread Street";
let dinnerTwo = "han's";

export function updateLabels(data: Assets) {
  cardOne = data.cardOne;
  cardTwo = data.cardTwo;
  cakeOne = data.cakeOne;
  cakeTwo = data.cakeTwo;
  dinnerOne = data.dinnerOne;
  dinnerTwo = data.dinnerTwo;
}

export function getCardOne() {
  return cardOne;
}

export function getCardTwo() {
  return cardTwo;
}

export function getCakeOne() {
  return cakeOne;
}

export function getCakeTwo() {
  return cakeTwo;
}

export function getDinnerOne() {
  return dinnerOne;
}

export function getDinnerTwo() {
  return dinnerTwo;
}
