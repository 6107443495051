import { Box, Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { getServerTime } from "../../timeManagement";

export default function Countdown({
  setisStarted,
  cueTime,
  overrideText,
  helperText,
}: {
  setisStarted: Function;
  cueTime: number;
  overrideText?: string;
  helperText?: string;
}) {
  const [timeDisplay, settimeDisplay] = React.useState(
    Math.floor((cueTime - getServerTime()) / 1000)
  );

  React.useEffect(() => {
    const waitTime = cueTime - getServerTime();
    if (waitTime < 0) setisStarted(true);
    const waitMs = waitTime % 1000;
    setTimeout(() => setTimeDisplay(Math.floor(waitTime / 1000)), waitMs);

    const setTimeDisplay = (time: number) => {
      settimeDisplay(time);
      if (time <= 0) {
        setTimeout(() => setisStarted(true), 500);
      } else {
        setTimeout(() => setTimeDisplay(time - 1), 1000);
      }
    };
  }, [cueTime, setisStarted]);

  const timerDisplay = () => {
    return (
      <motion.div
        key={timeDisplay}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.1, delay: 0.1 }}
        style={{ position: "absolute" }}
      >
        <Typography sx={{ fontSize: "10rem", fontWeight: "700" }}>
          {timeDisplay > 0 ? timeDisplay : "GO!"}
        </Typography>
      </motion.div>
    );
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AnimatePresence>{timerDisplay()}</AnimatePresence>
      </Box>
      <Box
        sx={{ position: "absolute", top: "20px", left: 0, marginX: "30px", textAlign: "center" }}
      >
        <Typography variant='h2' color='primary.contrastText' align='center' gutterBottom>
          {overrideText ? overrideText : "Get ready to choose!"}
        </Typography>
        {helperText && (
          <Typography variant='h4' color='primary.contrastText' align='center'>
            {helperText}
          </Typography>
        )}
      </Box>
    </>
  );
}
