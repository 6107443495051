import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { Link } from "react-router-dom";

interface navControl {
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  navData: {
    primaryLabel: string;
    data: { icon: JSX.Element; label: string; link: string }[];
  }[];
}

export default function NavContent(props: navControl) {
  var openStateData: { [primaryLabel: string]: boolean } = {};
  props.navData.forEach((item) => {
    openStateData[item.primaryLabel] = false;
  });
  return (
    <Box sx={{ display: "flex" }}>
      <Paper elevation={0} sx={{ width: "100%" }}>
        <List sx={{ padding: "0" }}>
          <ListItem
            key='hello'
            sx={{ backgroundColor: "primary.main", "&:hover": { backgroundColor: "primary.main" } }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a href='/' onClick={props.toggleDrawer(false)}>
                <img src='/SHLogo.png' alt='logo' style={{ width: "130px", paddingTop: "10px" }} />
              </a>
            </Box>
          </ListItem>
          <Divider />
          {/*links*/}
          <div style={{ marginTop: "10px" }}></div>
          {props.navData.map((element, index) => (
            <div key={`elem-${element.primaryLabel}`}>
              <ListSubheader sx={{ marginTop: "5px", height: "40px" }}>
                {element.primaryLabel}
              </ListSubheader>
              {element.data.map((item) => (
                <ListItem
                  disablePadding
                  key={`elem-${element.primaryLabel}-${item.label}`}
                  sx={{ marginLeft: "15px" }}
                >
                  <ListItemButton
                    component={Link}
                    to={item.link}
                    onClick={props.toggleDrawer(false)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </div>
          ))}
        </List>
      </Paper>
    </Box>
  );
}
