import React from "react";
import { motion } from "framer-motion";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useSelectDinnerMutation } from "../../generated/graphql";
import { useSnackbar } from "notistack";
import { getDinnerOne, getDinnerTwo } from "../../labelText";

export default function S07SelectDinner() {
  const [selectOption] = useSelectDinnerMutation();
  const [selectSuccess, setselectSuccess] = React.useState(false);
  const [selectedOption, setselectedOption] = React.useState(0);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        backgroundColor: selectSuccess ? "#fff" : "#ddd",
      }}
    >
      <Container
        sx={{
          paddingTop: "20px",
          paddingBottom: "55px",
          overflow: "scroll",
          maxHeight: "100%",
        }}
      >
        {selectSuccess ? (
          <>
            <Typography variant='h2' align='center' sx={{ marginX: "10px" }} gutterBottom>
              Selection made!
            </Typography>
            {selectedOption === 1 ? (
              <motion.div initial={{ x: -400 }} animate={{ x: 0 }} transition={{ delay: 0.3 }}>
                <Typography variant='h5' sx={{ marginLeft: "10px" }}>
                  {getDinnerOne()}
                </Typography>
                <img src='/game/7dinnerOne.png' style={{ width: "100%" }} alt='option 1' />
              </motion.div>
            ) : (
              <motion.div initial={{ x: -400 }} animate={{ x: 0 }} transition={{ delay: 0.4 }}>
                <Typography variant='h5' sx={{ marginLeft: "10px" }}>
                  {getDinnerTwo()}
                </Typography>
                <img
                  src='/game/7dinnerTwo.png'
                  style={{
                    width: "100%",
                  }}
                  alt='option 2'
                />
              </motion.div>
            )}
          </>
        ) : (
          <>
            <Typography variant='h2' align='center' sx={{ marginX: "10px" }}>
              Now choose a birthday dinner!
            </Typography>
            <Grid container spacing={4} sx={{ paddingTop: "20px", paddingBottom: "75px" }}>
              <Grid item xs={12} sm={6}>
                <motion.div initial={{ x: -400 }} animate={{ x: 0 }} transition={{ delay: 0.3 }}>
                  <motion.div
                    animate={{
                      scale:
                        1 +
                        0.02 * Number(selectedOption === 1) -
                        0.1 * Number(selectedOption === 2),
                    }}
                  >
                    <motion.div whileTap={{ scale: 0.9 }}>
                      <Box
                        sx={{
                          width: "100%",
                          borderRadius: "20px",
                          overflow: "clip",
                          boxShadow: 3,
                          borderColor: "#000",
                          filter: `brightness(${1 - 0.5 * Number(selectedOption === 2)})`,
                          transitionDuration: ".2s",
                          backgroundColor: "#fff",
                        }}
                        onClick={() => {
                          setselectedOption(1);
                        }}
                      >
                        <Box sx={{ width: "100%", aspectRatio: "2" }}>
                          <img
                            src='/game/7dinnerOne.png'
                            alt='notebook'
                            style={{ objectFit: "contain", width: "100%", height: "100%" }}
                          />
                        </Box>
                        <Box sx={{ padding: "4px 20px 7px 20px" }}>
                          <Stack direction='column'>
                            <Typography variant='h5' sx={{ lineHeight: 1.3 }}>
                              {getDinnerOne()}
                            </Typography>
                            <Typography variant='h6'>
                              <strong>$120</strong> for 2 people
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <motion.div initial={{ x: -400 }} animate={{ x: 0 }} transition={{ delay: 0.3 }}>
                  <motion.div
                    animate={{
                      scale:
                        1 +
                        0.02 * Number(selectedOption === 2) -
                        0.1 * Number(selectedOption === 1),
                    }}
                  >
                    <motion.div whileTap={{ scale: 0.9 }}>
                      <Box
                        sx={{
                          width: "100%",
                          borderRadius: "20px",
                          overflow: "clip",
                          boxShadow: 3,
                          borderColor: "#000",
                          filter: `brightness(${1 - 0.5 * Number(selectedOption === 1)})`,
                          transitionDuration: ".2s",
                          backgroundColor: "#fff",
                        }}
                        onClick={() => {
                          setselectedOption(2);
                        }}
                      >
                        <Box sx={{ width: "100%", aspectRatio: "2" }}>
                          <img
                            src='/game/7dinnerTwo.png'
                            alt='notebook'
                            style={{ objectFit: "contain", width: "100%", height: "100%" }}
                          />
                        </Box>
                        <Box sx={{ padding: "4px 20px 7px 20px" }}>
                          <Stack direction='column'>
                            <Typography variant='h5' sx={{ lineHeight: 1.3 }}>
                              {getDinnerTwo()}
                            </Typography>
                            <Typography variant='h6'>
                              <strong>$40</strong> for 2 people
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </Grid>
            </Grid>

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "90%", marginBottom: "75px" }}>
                <Button
                  variant='contained'
                  fullWidth
                  sx={{ boxShadow: 3 }}
                  disabled={selectedOption === 0}
                  onClick={async () => {
                    const response = await selectOption({
                      variables: { optionNumber: selectedOption },
                    });
                    if (response.data && response.data.selectDinner) {
                      setselectSuccess(true);
                    } else {
                      enqueueSnackbar("Error!", { variant: "error" });
                    }
                  }}
                >
                  Confirm Selection
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
}
