import React from "react";
import { useImReadyMutation } from "../../generated/graphql";
import { motion } from "framer-motion";
import { Box, Container, Typography, Stack } from "@mui/material";

export default function S15March() {
  const [imReady] = useImReadyMutation();
  const year = new Date().getFullYear();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Container
        sx={{ paddingTop: "20px", paddingBottom: "55px", overflow: "scroll", maxHeight: "100%" }}
      >
        <Stack
          direction='column'
          spacing={2}
          alignItems='center'
          sx={{ marginX: "15px", textAlign: "center" }}
        >
          <Typography variant='h2' align='center'>
            March {year + 1}
          </Typography>
          <motion.div
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <Typography variant='h5'>
              Sam managed to get a deferment to pay for his study loan for the next 6 months.
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 3 }}
          >
            <Typography variant='h5'>
              He is left with $900 of monthly expenses, which is drawn against his credit card
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 5 }}
          >
            <Typography variant='h5'>Sam continues searching for a new job.</Typography>
          </motion.div>
        </Stack>
      </Container>
      <motion.img
        src='/game/15jobs.png'
        style={{ width: "100%" }}
        initial={{ opacity: 0, x: -400 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 5 }}
        onAnimationComplete={() => imReady()}
      />
    </Box>
  );
}
