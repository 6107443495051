import { Backdrop, Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridRowsProp,
  GridSortModel,
  GridActionsCellItem,
  GridRowId,
  GridRowParams,
  GridCellParams,
  GridPagination,
  GridSelectedRowCount,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import {
  GetStudentsQuery,
  useDeleteUsersMutation,
  useReadyUserMutation,
} from "../../../generated/graphql";
import { getUserId } from "../../../accessToken";
import { useSnackbar } from "notistack";
import CustomLoadingOverlay from "../../CustomLoadingOverlay";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export function MainManageStudentsTableUnmemo(props: {
  data: GetStudentsQuery | undefined;
  refetch: Function;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteUsers] = useDeleteUsersMutation();
  const [doReadyUser] = useReadyUserMutation();
  const myUserId = useRef(-1);

  useEffect(() => {
    myUserId.current = getUserId() as number;
  }, []);

  const renderReadyCell = (params: GridRenderCellParams<number>) => {
    if (params.value) return <CheckCircleIcon fontSize='medium' color='success' />;
    return <CancelIcon fontSize='medium' color='error' />;
  };

  const gridColumns = [
    { field: "username", headerName: "Username", minWidth: 150 },
    { field: "isReady", headerName: "Ready", renderCell: renderReadyCell },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Delete'
          onClick={deleteUser(params.id)}
        />,
        <GridActionsCellItem
          icon={params.row.isReady ? <CancelIcon /> : <CheckCircleIcon />}
          label='Ready'
          onClick={readyUser(params.id, !params.row.isReady)}
        />,
      ],
    },
  ];

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "username",
      sort: "asc",
    },
  ]);

  const deleteUser = (id: GridRowId) => async () => {
    if (Number(id) === getUserId()) {
      enqueueSnackbar("You cannot delete your own account.", { variant: "error" });
      return;
    }
    const response = await deleteUsers({ variables: { ids: [Number(id)] } });
    if (response.data && response.data.deleteUsers) {
      enqueueSnackbar("Delete successful!", { variant: "success" });
    } else {
      enqueueSnackbar("Error deleting!", { variant: "error" });
    }
    props.refetch();
  };

  const readyUser = (id: GridRowId, isReady: boolean) => async () => {
    const response = await doReadyUser({ variables: { readyUserId: Number(id), isReady } });
    if (response.data && response.data.readyUser) {
      enqueueSnackbar("Ready successful!", { variant: "success" });
    } else {
      enqueueSnackbar("Error readying!", { variant: "error" });
    }
    props.refetch();
  };

  const [selectedIds, setSelectedIds] = useState<GridRowId[]>([]);

  const handleSelection = (ids: GridRowId[]) => {
    setSelectedIds(ids);
  };

  const deleteSelected = async () => {
    handleConfirmDeleteClose();
    const selectedIntIds = selectedIds.map((row) => Number(row));
    const response = await deleteUsers({ variables: { ids: selectedIntIds } });
    if (response.data && response.data.deleteUsers) {
      enqueueSnackbar("Delete successful!", { variant: "success" });
    } else {
      enqueueSnackbar("Error deleting!", { variant: "error" });
    }
    props.refetch();
  };

  const [confirmDeleteOpen, setconfirmDeleteOpen] = useState(false);

  const handleConfirmDeleteClose = () => setconfirmDeleteOpen(false);

  const CustomFooter = () => {
    return (
      <Grid container alignItems='center'>
        <Grid item xs>
          <Stack direction='row'>
            {selectedIds.length !== 0 ? (
              <>
                <GridSelectedRowCount selectedRowCount={selectedIds!.length} />
                <Button
                  variant='outlined'
                  color='error'
                  size='small'
                  onClick={() => setconfirmDeleteOpen(true)}
                >
                  Delete selected
                </Button>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
        <Grid item xs>
          <GridPagination />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Stack
        spacing={2}
        justifyContent='center'
        alignItems='center'
        direction='column'
        sx={{ width: "100%" }}
      >
        <Typography variant='h4' component='div'>
          Groups
        </Typography>
        <Box sx={{ width: "100%", height: "40vh" }}>
          <DataGrid
            checkboxSelection
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            components={{ LoadingOverlay: CustomLoadingOverlay, Footer: CustomFooter }}
            rows={
              props.data && props.data.getClass.students
                ? (props.data.getClass.students as GridRowsProp)
                : []
            }
            columns={gridColumns}
            isCellEditable={(params: GridCellParams) => params.id !== myUserId.current}
            isRowSelectable={(params: GridRowParams) => params.id !== myUserId.current}
            loading={!props.data ? true : false}
            sx={{ boxShadow: 3, borderRadius: "15px" }}
            onSelectionModelChange={handleSelection}
          />
        </Box>
      </Stack>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={confirmDeleteOpen}
        onClick={handleConfirmDeleteClose}
      >
        <Box
          sx={{
            backgroundColor: "white",
            color: "black",
            padding: "50px 100px 50px 100px",
            borderRadius: "10px",
          }}
        >
          <Typography variant='h4' sx={{ paddingBottom: "30px" }}>
            Are you sure you want to delete selected users?
          </Typography>
          <Stack direction='row' justifyContent='center' spacing={5}>
            <Button
              color='error'
              variant='outlined'
              startIcon={<CloseIcon />}
              onClick={handleConfirmDeleteClose}
            >
              Cancel
            </Button>
            <Button
              color='error'
              variant='contained'
              startIcon={<CheckIcon />}
              onClick={deleteSelected}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Backdrop>
    </>
  );
}

const MainManageStudentsTable = React.memo(MainManageStudentsTableUnmemo);
export default MainManageStudentsTable;
