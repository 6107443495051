import React from "react";
import "./style/Main.css";
import ReactDOM from "react-dom";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getAccessToken, setAccessToken } from "./accessToken";
import { App } from "./App";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";
import { SnackbarProvider } from "notistack";
import { createTheme, responsiveFontSizes, ThemeProvider, Zoom } from "@mui/material";
import { syncTime } from "./timeManagement";
import mainAddr from "./mainAddr";

syncTime();
setInterval(syncTime, 60000);

const cache = new InMemoryCache({});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `bearer ${getAccessToken()}` || null,
    },
  }));

  return forward(operation);
});

interface AccessToken {
  userId: number;
  iat: number;
  exp: number;
}

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: () => {
    const token = getAccessToken();

    if (!token) {
      return true;
    }

    try {
      const { exp } = jwtDecode<AccessToken>(token);
      if (Date.now() >= (exp - 30) * 1000) {
        return false;
      } else {
        return true;
      }
    } catch {
      return false;
    }
  },
  fetchAccessToken: () => {
    return fetch(`${mainAddr()}/refresh_token`, {
      method: "POST",
      credentials: "include",
    });
  },
  handleFetch: (accessToken) => {
    setAccessToken(accessToken);
  },
  handleError: (err) => {
    console.warn("Your refresh token is invalid. Try to relogin");
    console.error(err);
  },
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({ uri: `${mainAddr()}/graphql`, credentials: "include" });

const client = new ApolloClient({
  link: from([tokenRefreshLink, errorLink, authMiddleware, httpLink]),
  cache,
});

let theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  palette: {
    primary: {
      main: "#0e143f",
      light: "#3b396a",
      dark: "#00001b",
    },
    secondary: {
      main: "#f7ca23",
      light: "#fffd5d",
      dark: "#c09a00",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
    },
    warning: {
      main: "#ffa726",
      light: "#ffb74d",
      dark: "#f57c00",
    },
    info: {
      main: "#29b6f6",
      light: "#4fc3f7",
      dark: "#0288d1",
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
    },
  },
});

theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          TransitionComponent={Zoom as React.FC}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          maxSnack={1}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
