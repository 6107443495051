import { Container, Stack, Typography, Grid, Box, Fab } from "@mui/material";
import React from "react";
import ClassCard from "../components/ClassCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ClassesQuery } from "../generated/graphql";

function TrainerClassListUnmemo({
  setisCreateOpen,
  data,
  startEdit,
}: {
  setisCreateOpen: Function;
  data: ClassesQuery | undefined;
  startEdit: Function;
}) {
  const GenCards = (data: ClassesQuery) => {
    var cards: JSX.Element[] = [];
    data.classes.forEach((c, i) => {
      cards.push(
        <Grid item xs={12} md={6} key={c.name}>
          <ClassCard
            name={c.name}
            nGroups={c.nGroups}
            status={c.status}
            created={c.dateCreated}
            id={c.id}
            startEdit={startEdit}
          />
        </Grid>
      );
    });
    return cards;
  };

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
        <Typography variant='h3' component='div'>
          My Classes
        </Typography>
        {data === undefined ? (
          <></>
        ) : (
          <Grid container spacing={4}>
            {GenCards(data)}
          </Grid>
        )}
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Fab
            variant='extended'
            color='secondary'
            sx={{ mb: 2 }}
            onClick={() => setisCreateOpen(true)}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Create New
          </Fab>
        </Box>
      </Stack>
    </Container>
  );
}

const TrainerClassList = React.memo(TrainerClassListUnmemo);
export default TrainerClassList;
