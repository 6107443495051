import * as React from "react";
import { Box, SwipeableDrawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CreateIcon from "@mui/icons-material/Create";
import GroupIcon from "@mui/icons-material/Group";

import NavContent from "./NavContent";
import { getPermLevel } from "../accessToken";

const adminNavData = [
  {
    primaryLabel: "User Management",
    data: [
      { icon: <ListAltIcon />, label: "Manage Classes", link: "/su/admin/class/manage" },
      { icon: <GroupIcon />, label: "Manage Users", link: "/su/admin/users/manage" },
    ],
  },
  {
    primaryLabel: "Game Management",
    data: [{ icon: <CreateIcon />, label: "Edit Game", link: "/su/admin/game/manage" }],
  },
];

const trainerNavData = [
  {
    primaryLabel: "Class Management",
    data: [{ icon: <ListAltIcon />, label: "My Classes", link: "/su/trainer/class/manage" }],
  },
];

export default function MainDrawer() {
  const [drawerOut, setDrawerOut] = React.useState(false);
  const navData = React.useRef(trainerNavData);

  React.useEffect(() => {
    const permLevel = getPermLevel();
    if (permLevel === 1) {
      navData.current = trainerNavData;
    }
    if (permLevel === 2) {
      navData.current = adminNavData;
    }
  }, []);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerOut(open);
  };

  const content = () => (
    <Box sx={{ width: 256 }} role='presentation' onKeyDown={toggleDrawer(false)}>
      <NavContent toggleDrawer={toggleDrawer} navData={navData.current} />
    </Box>
  );

  return (
    <div>
      <IconButton
        onClick={toggleDrawer(true)}
        size='large'
        edge='start'
        color='inherit'
        aria-label='menu'
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor='left'
        open={drawerOut}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(3px);",
          },
        }}
      >
        {content()}
      </SwipeableDrawer>
    </div>
  );
}
