import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import {
  useGetCueTimeMutation,
  useGetCardAvailabilityQuery,
  useSelectCardMutation,
  useCheckReadyMutation,
} from "../../generated/graphql";
import Countdown from "./Countdown";
import { getServerTime } from "../../timeManagement";
import { useSnackbar } from "notistack";
import { getCardOne, getCardTwo } from "../../labelText";

export default function S03SelectCreditCard({ gameId }: { gameId: number }) {
  const [getCueTime] = useGetCueTimeMutation();
  const [checkReady] = useCheckReadyMutation();
  const [selectCard] = useSelectCardMutation();

  const [cueTime, setcueTime] = React.useState(-1);
  const [isStarted, setisStarted] = React.useState(false);

  const [selectedCard, setselectedCard] = React.useState(0);
  const [selectSuccess, setselectSuccess] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const getCardAvalability = useGetCardAvailabilityQuery({
    errorPolicy: "all",
    variables: { getGameId: gameId },
    onCompleted: (data) => {
      if (data.getGame.cardOneAvailable === 0) setselectedCard(2);
      if (data.getGame.cardTwoAvailable === 0) setselectedCard(1);
    },
    pollInterval: 300,
  });

  const cardAvailable = (cardNumber: number) => {
    if (getCardAvalability.data) {
      if (cardNumber === 1) {
        return getCardAvalability.data.getGame.cardOneAvailable;
      }
      if (cardNumber === 2) {
        return getCardAvalability.data.getGame.cardTwoAvailable;
      }
    }
    return 0;
  };

  React.useEffect(() => {
    (async () => {
      const response = await getCueTime({ variables: { getCueTimeId: gameId } });
      if (response.data && response.data.getCueTime !== -1) {
        setcueTime(response.data.getCueTime);
        if (cueTime > getServerTime()) {
          setisStarted(false);
        }
      } else {
        console.log("error getting cue time");
      }
      const ready = await checkReady();
      if (ready.data && ready.data.checkReady) {
        setselectSuccess(true);
      }
    })();
  }, [gameId, getCueTime, cueTime, checkReady]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <AnimatePresence>
        {isStarted ? (
          <motion.div
            key='1'
            style={{ width: "100%", height: "100%" }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <Container
              sx={{
                paddingTop: "20px",
                paddingBottom: "55px",
                overflow: "scroll",
                maxHeight: "100%",
              }}
            >
              {selectSuccess ? (
                <>
                  <Typography variant='h2' align='center' sx={{ marginX: "10px" }} gutterBottom>
                    Selection made!
                  </Typography>
                  {selectedCard === 1 ? (
                    <motion.div
                      initial={{ x: -400 }}
                      animate={{ x: 0 }}
                      transition={{ delay: 0.3 }}
                    >
                      <Typography variant='h5' sx={{ marginLeft: "10px" }}>
                        {getCardOne()}
                      </Typography>
                      <img src='/game/2cardOne.png' style={{ width: "100%" }} alt='card 1' />
                    </motion.div>
                  ) : (
                    <motion.div
                      initial={{ x: -400 }}
                      animate={{ x: 0 }}
                      transition={{ delay: 0.4 }}
                    >
                      <Typography variant='h5' sx={{ marginLeft: "10px" }}>
                        {getCardTwo()}
                      </Typography>
                      <img
                        src='/game/2cardTwo.png'
                        style={{
                          width: "100%",
                        }}
                        alt='card 2'
                      />
                    </motion.div>
                  )}
                </>
              ) : (
                <>
                  <Typography variant='h2' align='center' sx={{ marginX: "10px" }}>
                    Make your choice!
                  </Typography>
                  {cardAvailable(1) !== 0 && cardAvailable(2) !== 0 ? (
                    <Stack
                      direction='column'
                      spacing={4}
                      sx={{
                        paddingTop: "20px",
                        "@media (min-width: 600px)": {
                          paddingX: "15%",
                        },
                      }}
                    >
                      <motion.div
                        initial={{ x: -400 }}
                        animate={{ x: 0 }}
                        transition={{ delay: 0.3 }}
                      >
                        <motion.div
                          animate={{
                            scale:
                              1 +
                              0.02 * Number(selectedCard === 1) -
                              0.1 * Number(selectedCard === 2),
                          }}
                        >
                          <motion.div whileTap={{ scale: 0.9 }}>
                            <Typography variant='h5' sx={{ marginLeft: "10px" }}>
                              {getCardOne()}
                            </Typography>
                            <img
                              src='/game/2cardOne.png'
                              style={{
                                width: "100%",
                                filter: `drop-shadow(4px 4px 5px rgba(153, 153, 153, ${Number(
                                  selectedCard === 1
                                )})) brightness(${1 - 0.5 * Number(selectedCard === 2)}) blur(${
                                  5 * Number(cardAvailable(1) === 0)
                                }px)`,
                                transitionDuration: ".2s",
                              }}
                              alt='card 1'
                              onClick={() => {
                                if (cardAvailable(1) !== 0) setselectedCard(1);
                              }}
                            />
                            <Typography variant='h6' align='right' sx={{ marginLeft: "10px" }}>
                              Remaining: {cardAvailable(1)}
                            </Typography>
                          </motion.div>
                        </motion.div>
                      </motion.div>

                      <motion.div
                        initial={{ x: -400 }}
                        animate={{ x: 0 }}
                        transition={{ delay: 0.4 }}
                      >
                        <motion.div
                          animate={{
                            scale:
                              1 +
                              0.02 * Number(selectedCard === 2) -
                              0.1 * Number(selectedCard === 1),
                          }}
                        >
                          <motion.div whileTap={{ scale: 0.9 }}>
                            <Typography variant='h5' sx={{ marginLeft: "10px" }}>
                              {getCardTwo()}
                            </Typography>
                            <img
                              src='/game/2cardTwo.png'
                              style={{
                                width: "100%",
                                filter: `drop-shadow(4px 4px 5px rgba(153, 153, 153, ${Number(
                                  selectedCard === 2
                                )})) brightness(${1 - 0.5 * Number(selectedCard === 1)}) blur(${
                                  5 * Number(cardAvailable(2) === 0)
                                }px)`,
                                transitionDuration: ".2s",
                              }}
                              alt='card 2'
                              onClick={() => {
                                if (cardAvailable(2) !== 0) setselectedCard(2);
                              }}
                            />
                            <Typography variant='h6' align='right' sx={{ marginLeft: "10px" }}>
                              Remaining: {cardAvailable(2)}
                            </Typography>
                          </motion.div>
                        </motion.div>
                      </motion.div>
                    </Stack>
                  ) : (
                    <></>
                  )}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ width: "90%", marginBottom: "20px" }}>
                      <Button
                        variant='contained'
                        fullWidth
                        sx={{ boxShadow: 3 }}
                        disabled={selectedCard === 0}
                        onClick={async () => {
                          const response = await selectCard({
                            variables: { gameId: gameId, cardNumber: selectedCard },
                          });
                          if (response.data && response.data.selectCard) {
                            setselectSuccess(true);
                          } else {
                            enqueueSnackbar("Card not available!", { variant: "error" });
                          }
                        }}
                      >
                        Confirm Selection
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
            </Container>
          </motion.div>
        ) : (
          <>
            {cueTime !== -1 && (
              <motion.div
                key='2'
                style={{ width: "100%", height: "100%" }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
              >
                <Countdown setisStarted={setisStarted} cueTime={cueTime} />
              </motion.div>
            )}
          </>
        )}
      </AnimatePresence>
    </Box>
  );
}
