import React from "react";
import { useImReadyMutation } from "../../generated/graphql";
import { motion } from "framer-motion";
import { Backdrop, Box, Container, Stack, Typography } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CloseIcon from "@mui/icons-material/Close";
import { getCardOne, getCardTwo } from "../../labelText";

export default function S02CreditCardInfo() {
  const [imReady] = useImReadyMutation();
  const [openCardOne, setopenCardOne] = React.useState(false);
  const [openCardTwo, setopenCardTwo] = React.useState(false);
  const [seenState, setseenState] = React.useState([false, false]);
  const [readyStateSet, setreadyStateSet] = React.useState(false);

  if (seenState[0] && seenState[1]) {
    if (!readyStateSet) {
      setreadyStateSet(true);
      imReady();
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Container
        sx={{ paddingTop: "20px", paddingBottom: "30px", overflow: "scroll", maxHeight: "100%" }}
      >
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Stack direction='column' spacing={2}>
            <Typography variant='h3' align='center'>
              Sam wants to apply for a credit card.
            </Typography>
            <Typography variant='h4' align='center'>
              Here are his options:
              <br />
              (tap to see details)
            </Typography>
            <Stack direction='column' spacing={4} sx={{ paddingTop: "20px" }}>
              <motion.div initial={{ x: -400 }} animate={{ x: 0 }} transition={{ delay: 0.3 }}>
                <motion.div
                  animate={{ scale: [1, 1 + 0.02 * Number(!seenState[0]), 1] }}
                  transition={{ repeat: Infinity, duration: 1.5 }}
                >
                  <motion.div whileTap={{ scale: 0.8 }}>
                    <img
                      src='/game/2cardOne.png'
                      style={{ width: "100%", filter: "drop-shadow(4px 4px 5px #999)" }}
                      alt='card 1'
                      onClick={() => {
                        setopenCardOne(true);
                        const newSeenState = seenState;
                        newSeenState[0] = true;
                        setseenState(newSeenState);
                      }}
                    />
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div initial={{ x: -400 }} animate={{ x: 0 }} transition={{ delay: 0.4 }}>
                <motion.div
                  animate={{ scale: [1, 1 + 0.02 * Number(!seenState[1]), 1] }}
                  transition={{ repeat: Infinity, duration: 1.5 }}
                >
                  <motion.div whileTap={{ scale: 0.8 }}>
                    <img
                      src='/game/2cardTwo.png'
                      style={{ width: "100%", filter: "drop-shadow(4px 4px 5px #999)" }}
                      alt='card 2'
                      onClick={() => {
                        setopenCardTwo(true);
                        const newSeenState = seenState;
                        newSeenState[1] = true;
                        setseenState(newSeenState);
                      }}
                    />
                  </motion.div>
                </motion.div>
              </motion.div>
            </Stack>
          </Stack>
        </motion.div>
      </Container>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflow: "scroll",
          height: "100%",
          backdropFilter: "blur(4px)",
        }}
        open={openCardOne}
        onClick={() => setopenCardOne(false)}
      >
        <Container sx={{ height: "100%" }}>
          {openCardOne ? (
            <motion.div initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }}>
              <Box sx={{ paddingY: "15px" }}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    color: "#000",
                    borderRadius: "15px",
                    boxShadow: 3,
                  }}
                >
                  <CloseIcon sx={{ position: "absolute", left: "27px", top: "24px" }} />
                  <Box sx={{ padding: "5px 20px 5px 20px" }}>
                    <img
                      src='/game/2logoOne.png'
                      alt=''
                      style={{
                        display: "block",
                        marginRight: "auto",
                        marginLeft: "auto",
                        width: "60%",
                        marginTop: "15px",
                        marginBottom: "20px",
                      }}
                    />
                    <Typography variant='h3' sx={{ paddingBottom: "10px", marginLeft: "4px" }}>
                      {getCardOne()}
                    </Typography>
                    <Stack direction='column' spacing={1} sx={{ paddingBottom: "25px" }}>
                      <Stack direction='row' alignItems='center'>
                        <HorizontalRuleIcon
                          fontSize='small'
                          sx={{ paddingRight: "10px", color: "#999" }}
                        />
                        <Typography variant='h5'>Minimum annual income of $30,000</Typography>
                      </Stack>
                      <Stack direction='row' alignItems='center'>
                        <HorizontalRuleIcon
                          fontSize='small'
                          sx={{ paddingRight: "10px", color: "#999" }}
                        />
                        <Typography variant='h5'>
                          Earn 3 miles for every $1 spent, miles to redeem for flights and hotel
                          stays outside Singapore
                        </Typography>
                      </Stack>
                      <Stack direction='row' alignItems='center'>
                        <HorizontalRuleIcon
                          fontSize='small'
                          sx={{ paddingRight: "10px", color: "#999" }}
                        />
                        <Typography variant='h5'>
                          Free 30,000 miles and airport lounge access around the world
                        </Typography>
                      </Stack>
                      <Stack direction='row' alignItems='center'>
                        <HorizontalRuleIcon
                          fontSize='small'
                          sx={{ paddingRight: "10px", color: "#999" }}
                        />
                        <Typography variant='h5'>Annual Membership fee: $150</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </motion.div>
          ) : (
            <></>
          )}
        </Container>
      </Backdrop>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(4px)",
        }}
        open={openCardTwo}
        onClick={() => setopenCardTwo(false)}
      >
        <Container sx={{ height: "100%" }}>
          {openCardTwo ? (
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              style={{ height: "100%" }}
            >
              <Box sx={{ paddingY: "15px" }}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    color: "#000",
                    borderRadius: "15px",
                    boxShadow: 3,
                  }}
                >
                  <CloseIcon sx={{ position: "absolute", left: "27px", top: "24px" }} />
                  <Box sx={{ padding: "5px 20px 5px 20px" }}>
                    <img
                      src='/game/2logoTwo.png'
                      alt=''
                      style={{
                        display: "block",
                        marginRight: "auto",
                        marginLeft: "auto",
                        width: "60%",
                        marginTop: "15px",
                        marginBottom: "20px",
                      }}
                    />
                    <Typography variant='h3' sx={{ paddingBottom: "10px", marginLeft: "4px" }}>
                      {getCardTwo()}
                    </Typography>
                    <Stack direction='column' spacing={1} sx={{ paddingBottom: "25px" }}>
                      <Stack direction='row' alignItems='center'>
                        <HorizontalRuleIcon
                          fontSize='small'
                          sx={{ paddingRight: "10px", color: "#999" }}
                        />
                        <Typography variant='h5'>Minimum annual income of $30,000</Typography>
                      </Stack>
                      <Stack direction='row' alignItems='center'>
                        <HorizontalRuleIcon
                          fontSize='small'
                          sx={{ paddingRight: "10px", color: "#999" }}
                        />
                        <Typography variant='h5'>
                          Earn 3 reward points for every $1 spent, reward points can be used to
                          exchange for items
                        </Typography>
                      </Stack>
                      <Stack direction='row' alignItems='center'>
                        <HorizontalRuleIcon
                          fontSize='small'
                          sx={{ paddingRight: "10px", color: "#999" }}
                        />
                        <Typography variant='h5'>
                          Annual Membership fee: Free for first 2 years
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </motion.div>
          ) : (
            <></>
          )}
        </Container>
      </Backdrop>
    </Box>
  );
}
