import MainAppBar from "../components/MainAppBar";
import { Routes as RouterRoutes, Route } from "react-router-dom";
import AdminClassManage from "./AdminClassManage";
import AdminUsersManage from "./AdminUsersManage";
import AdminGameManage from "./AdminGameManage";
import TrainerClassManage from "./TrainerClassManage";
import ClassView from "./ClassView";

export default function Su() {
  return (
    <div>
      <MainAppBar />

      <RouterRoutes>
        <Route path='admin/class/manage' element={<AdminClassManage />} />
        <Route path='admin/users/manage' element={<AdminUsersManage />} />
        <Route path='admin/game/manage' element={<AdminGameManage />} />
        <Route path='trainer/class/manage' element={<TrainerClassManage />} />
        <Route path='class/view/:cId' element={<ClassView />} />
      </RouterRoutes>
    </div>
  );
}
