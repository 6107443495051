import { Box } from "@mui/system";
import React from "react";
import { useParams } from "react-router-dom";
import { setClassId } from "../classId";
import EndScreenTrainer from "../components/TrainerGame/EndScreenTrainer";
import JoinScreenTrainer from "../components/TrainerGame/JoinScreenTrainer";
import MainManage from "../components/TrainerGame/MainManage";
import { useGetGameStageQuery } from "../generated/graphql";

export default function ClassView() {
  let { cId } = useParams();
  const classId = Number(cId);
  React.useEffect(() => {
    setClassId(classId);
  }, [classId]);
  const getGameStage = useGetGameStageQuery({
    errorPolicy: "all",
    variables: { getGameStageId: classId },
    pollInterval: 1000,
  });

  const GenGamePage = () => {
    if (getGameStage.data === undefined || getGameStage.data === null) {
      return <Box sx={{ width: "100vw", height: "100vh", backgroundColor: "primary.main" }}></Box>;
    }
    if (getGameStage.data.getGameStage === -1)
      return <JoinScreenTrainer classId={classId} getGameStage={getGameStage} />;
    if (getGameStage.data.getGameStage === 20)
      return <EndScreenTrainer classId={classId} getGameStage={getGameStage} />;
    return <MainManage classId={classId} getGameStage={getGameStage} />;
  };

  return (
    <>
      <GenGamePage />
    </>
  );
}
