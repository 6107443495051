import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useGetMyMoneyDataQuery } from "../../generated/graphql";
import { moneyInterface } from "../../moneyInterface";

const commafy = (n: number) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function MoneySheetUnmemo() {
  const [moneyData, setmoneyData] = React.useState<moneyInterface | undefined>(undefined);
  const [oldJsonData, setoldjsonData] = React.useState("");
  const year = new Date().getFullYear();

  const getMyMoneyData = useGetMyMoneyDataQuery({ errorPolicy: "all", pollInterval: 2000 });

  if (getMyMoneyData.data && getMyMoneyData.data.me && getMyMoneyData.data.me.moneyData) {
    if (getMyMoneyData.data.me.moneyData !== oldJsonData) {
      const moneyJsonData = getMyMoneyData.data.me.moneyData;
      setoldjsonData(moneyJsonData);
      setmoneyData(JSON.parse(getMyMoneyData.data.me.moneyData));
    }
  }

  return (
    <>
      {moneyData ? (
        <Stack direction='column' spacing={1} sx={{ paddingTop: "15px" }}>
          {moneyData.november.toShow && (
            <Stack direction='column'>
              <Typography variant='h4'>November {year}</Typography>
              <Divider />
              <Typography
                variant='subtitle1'
                color='primary.main'
                sx={{ paddingTop: "5px", paddingX: "5px" }}
              >
                Savings
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Income</Typography>
                  <Typography variant='h6' color='success.dark'>
                    +${commafy(moneyData.november.income)}
                  </Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Monthly Expenses</Typography>
                  <Typography variant='h6' color='error.dark'>
                    -${commafy(moneyData.november.expenses)}
                  </Typography>
                </Stack>
                {moneyData.november.savingsF !== 0 && (
                  <>
                    <Box sx={{ marginY: "3px" }}>
                      <Divider />
                    </Box>
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography variant='h6'>Savings Balance</Typography>
                      <Typography variant='h6'>${commafy(moneyData.november.savingsF)}</Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>
          )}

          {moneyData.december.toShow && (
            <Stack direction='column'>
              <Typography variant='h4'>December {year}</Typography>
              <Divider />
              <Typography
                variant='subtitle1'
                color='primary.main'
                sx={{ paddingTop: "5px", paddingX: "5px" }}
              >
                Savings
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Previous Savings</Typography>
                  <Typography variant='h6'>${commafy(moneyData.november.savingsF)}</Typography>
                </Stack>
                {moneyData.december.membershipFee && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Credit Card Membership Fee</Typography>
                    <Typography variant='h6' color='error.dark'>
                      -$150
                    </Typography>
                  </Stack>
                )}
                {moneyData.december.income !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Income</Typography>
                    <Typography variant='h6' color='success.dark'>
                      +${commafy(moneyData.december.income)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.december.expenses !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Monthly Expenses</Typography>
                    <Typography variant='h6' color='error.dark'>
                      -${commafy(moneyData.december.expenses)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.december.savingsF !== 0 && (
                  <>
                    <Box sx={{ marginY: "3px" }}>
                      <Divider />
                    </Box>
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography variant='h6'>Savings Balance</Typography>
                      <Typography variant='h6'>${commafy(moneyData.december.savingsF)}</Typography>
                    </Stack>
                  </>
                )}
              </Stack>
              <Typography variant='subtitle1' color='primary.main' sx={{ paddingLeft: "5px" }}>
                Credit Card
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                {moneyData.december.cake !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Birthday Cake</Typography>
                    <Typography variant='h6'>${commafy(moneyData.december.cake)}</Typography>
                  </Stack>
                )}
                {moneyData.december.dinner !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Birthday Dinner</Typography>
                    <Typography variant='h6'>${commafy(moneyData.december.dinner)}</Typography>
                  </Stack>
                )}
                {moneyData.december.flowers && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Flowers</Typography>
                    <Typography variant='h6'>$100</Typography>
                  </Stack>
                )}
                <Box sx={{ marginY: "3px" }}>
                  <Divider />
                </Box>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Credit Card Bill</Typography>
                  <Typography variant='h6'>
                    $
                    {commafy(
                      moneyData.december.cake +
                        moneyData.december.dinner +
                        Number(moneyData.december.flowers) * 100
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}

          {moneyData.january.toShow && (
            <Stack direction='column'>
              <Typography variant='h4'>January {year + 1}</Typography>
              <Divider />
              <Typography
                variant='subtitle1'
                color='primary.main'
                sx={{ paddingTop: "5px", paddingX: "5px" }}
              >
                Savings
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Previous Savings</Typography>
                  <Typography variant='h6'>${commafy(moneyData.december.savingsF)}</Typography>
                </Stack>
                {moneyData.january.income !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Income</Typography>
                    <Typography variant='h6' color='success.dark'>
                      +${commafy(moneyData.january.income)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.january.expenses !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Monthly Expenses</Typography>
                    <Typography variant='h6' color='error.dark'>
                      -${commafy(moneyData.january.expenses)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.january.ccBill !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>December Credit Card Bill</Typography>
                    <Typography variant='h6' color='error.dark'>
                      -${commafy(moneyData.january.ccBill)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.january.savingsF !== 0 && (
                  <>
                    <Box sx={{ marginY: "3px" }}>
                      <Divider />
                    </Box>
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography variant='h6'>Savings Balance</Typography>
                      <Typography variant='h6'>${commafy(moneyData.january.savingsF)}</Typography>
                    </Stack>
                  </>
                )}
              </Stack>
              <Typography variant='subtitle1' color='primary.main' sx={{ paddingLeft: "5px" }}>
                Credit Card
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                {moneyData.january.iWatch !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>iWatch</Typography>
                    <Typography variant='h6'>${commafy(moneyData.january.iWatch)}</Typography>
                  </Stack>
                )}
                <Box sx={{ marginY: "3px" }}>
                  <Divider />
                </Box>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Credit Card Bill</Typography>
                  <Typography variant='h6'>${commafy(moneyData.january.iWatch)}</Typography>
                </Stack>
              </Stack>
            </Stack>
          )}

          {moneyData.february.toShow && (
            <Stack direction='column'>
              <Typography variant='h4'>February {year + 1}</Typography>
              <Divider />
              <Typography
                variant='subtitle1'
                color='primary.main'
                sx={{ paddingTop: "5px", paddingX: "5px" }}
              >
                Savings
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Previous Savings</Typography>
                  <Typography variant='h6'>${commafy(moneyData.january.savingsF)}</Typography>
                </Stack>
                {moneyData.february.income !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Income</Typography>
                    <Typography variant='h6' color='success.dark'>
                      +${commafy(moneyData.february.income)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.february.expenses !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Monthly Expenses</Typography>
                    <Typography variant='h6' color='error.dark'>
                      -${commafy(moneyData.february.expenses)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.february.ccBill !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>January Credit Card Bill</Typography>
                    <Typography variant='h6' color='error.dark'>
                      -${commafy(moneyData.february.ccBill)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.february.savingsF !== 0 && (
                  <>
                    <Box sx={{ marginY: "3px" }}>
                      <Divider />
                    </Box>
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography variant='h6'>Savings Balance</Typography>
                      <Typography variant='h6'>${commafy(moneyData.february.savingsF)}</Typography>
                    </Stack>
                  </>
                )}
              </Stack>
              <Typography variant='subtitle1' color='primary.main' sx={{ paddingLeft: "5px" }}>
                Credit Card
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                {moneyData.february.rollover !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Amount rollover to March</Typography>
                    <Typography variant='h6'>${commafy(moneyData.february.rollover)}</Typography>
                  </Stack>
                )}
                {moneyData.march.interest !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Interest Incurred</Typography>
                    <Typography variant='h6'>${commafy(moneyData.march.interest)}</Typography>
                  </Stack>
                )}
                {moneyData.february.savingsDef !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Charge to Credit Card</Typography>
                    <Typography variant='h6'>${commafy(moneyData.february.savingsDef)}</Typography>
                  </Stack>
                )}
                <Box sx={{ marginY: "3px" }}>
                  <Divider />
                </Box>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Credit Card Bill</Typography>
                  <Typography variant='h6'>
                    $
                    {commafy(
                      Math.floor(
                        (moneyData.february.savingsDef +
                          moneyData.march.interest +
                          moneyData.february.rollover) *
                          100
                      ) / 100
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}

          {moneyData.march.toShow && (
            <Stack direction='column'>
              <Typography variant='h4'>March {year + 1}</Typography>
              <Divider />
              <Typography
                variant='subtitle1'
                color='primary.main'
                sx={{ paddingTop: "5px", paddingX: "5px" }}
              >
                Savings
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                {moneyData.february.savingsF !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Previous Savings</Typography>
                    <Typography variant='h6'>${commafy(moneyData.february.savingsF)}</Typography>
                  </Stack>
                )}
                {moneyData.march.income !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Income</Typography>
                    <Typography variant='h6' color='success.dark'>
                      +${commafy(moneyData.march.income)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.march.expenses !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Monthly Expenses</Typography>
                    <Typography variant='h6' color='error.dark'>
                      -${commafy(moneyData.march.expenses)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.march.ccBill !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>February Credit Card Bill</Typography>
                    <Typography variant='h6' color='error.dark'>
                      -$
                      {commafy(
                        Math.floor(
                          (moneyData.february.savingsDef +
                            moneyData.march.interest +
                            moneyData.february.rollover) *
                            100
                        ) / 100
                      )}
                    </Typography>
                  </Stack>
                )}
                {moneyData.march.savingsF !== 0 && (
                  <>
                    <Box sx={{ marginY: "3px" }}>
                      <Divider />
                    </Box>
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography variant='h6'>Savings Balance</Typography>
                      <Typography variant='h6'>${commafy(moneyData.march.savingsF)}</Typography>
                    </Stack>
                  </>
                )}
              </Stack>
              <Typography variant='subtitle1' color='primary.main' sx={{ paddingLeft: "5px" }}>
                Credit Card
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                {moneyData.march.rollover !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Amount rollover to April</Typography>
                    <Typography variant='h6'>${commafy(moneyData.march.rollover)}</Typography>
                  </Stack>
                )}
                {moneyData.april.interest !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Interest Incurred</Typography>
                    <Typography variant='h6'>${commafy(moneyData.april.interest)}</Typography>
                  </Stack>
                )}
                {moneyData.march.savingsDef !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Charge to Credit Card</Typography>
                    <Typography variant='h6'>${commafy(moneyData.march.savingsDef)}</Typography>
                  </Stack>
                )}
                <Box sx={{ marginY: "3px" }}>
                  <Divider />
                </Box>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Credit Card Bill</Typography>
                  <Typography variant='h6'>
                    $
                    {commafy(
                      Math.round(
                        (moneyData.march.savingsDef +
                          moneyData.april.interest +
                          moneyData.march.rollover) *
                          100
                      ) / 100
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}

          {moneyData.april.toShow && (
            <Stack direction='column'>
              <Typography variant='h4'>April {year + 1}</Typography>
              <Divider />
              <Typography
                variant='subtitle1'
                color='primary.main'
                sx={{ paddingTop: "5px", paddingX: "5px" }}
              >
                Savings
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                {moneyData.march.savingsF !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Previous Savings</Typography>
                    <Typography variant='h6'>${commafy(moneyData.march.savingsF)}</Typography>
                  </Stack>
                )}
                {moneyData.april.income !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Income</Typography>
                    <Typography variant='h6' color='success.dark'>
                      +${commafy(moneyData.april.income)}
                    </Typography>
                  </Stack>
                )}
                {moneyData.april.expenses !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Monthly Expenses</Typography>
                    <Typography variant='h6' color='error.dark'>
                      -${commafy(moneyData.april.expenses)}
                    </Typography>
                  </Stack>
                )}
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>March Credit Card Bill</Typography>
                  <Typography variant='h6' color='error.dark'>
                    -$
                    {commafy(
                      Math.round(
                        (moneyData.march.savingsDef +
                          moneyData.april.interest +
                          moneyData.march.rollover) *
                          100
                      ) / 100
                    )}
                  </Typography>
                </Stack>
                {moneyData.april.savingsF !== 0 && (
                  <>
                    <Box sx={{ marginY: "3px" }}>
                      <Divider />
                    </Box>
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography variant='h6'>Savings Balance</Typography>
                      <Typography variant='h6'>${commafy(moneyData.april.savingsF)}</Typography>
                    </Stack>
                  </>
                )}
              </Stack>
              <Typography variant='subtitle1' color='primary.main' sx={{ paddingLeft: "5px" }}>
                Credit Card
              </Typography>
              <Stack direction='column' sx={{ paddingBottom: "5px", paddingX: "15px" }}>
                {moneyData.april.rollover !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Amount rollover to May</Typography>
                    <Typography variant='h6'>${commafy(moneyData.april.rollover)}</Typography>
                  </Stack>
                )}
                {moneyData.april.interest2 !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Interest Incurred</Typography>
                    <Typography variant='h6'>${commafy(moneyData.april.interest2)}</Typography>
                  </Stack>
                )}
                {moneyData.april.savingsDef !== 0 && (
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Charge to Credit Card</Typography>
                    <Typography variant='h6'>${commafy(moneyData.april.savingsDef)}</Typography>
                  </Stack>
                )}
                <Box sx={{ marginY: "3px" }}>
                  <Divider />
                </Box>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h6'>Credit Card Bill</Typography>
                  <Typography variant='h6'>
                    $
                    {commafy(
                      Math.round(
                        (moneyData.april.rollover +
                          moneyData.april.savingsDef +
                          moneyData.april.interest2) *
                          100
                      ) / 100
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
}

const MoneySheet = React.memo(MoneySheetUnmemo);

export default MoneySheet;
