import { Box, Button, CircularProgress, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {
  useGetOneClassQuery,
  useGetStudentsQuery,
  useDeleteUserMutation,
  useNextStageMutation,
  Exact,
  GetGameStageQuery,
} from "../../generated/graphql";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { QueryResult } from "@apollo/client/react/types/types";
import QRCode from "react-qr-code";

export default function JoinScreenTrainer({
  classId,
  getGameStage,
}: {
  classId: number;
  getGameStage: QueryResult<
    GetGameStageQuery,
    Exact<{
      getGameStageId: number;
    }>
  >;
}) {
  const getClassQuery = useGetOneClassQuery({
    errorPolicy: "all",
    variables: { getClassId: classId },
  });
  const getStudentsQuery = useGetStudentsQuery({
    errorPolicy: "all",
    variables: { getClassId: classId },
    pollInterval: 500,
  });
  const [doDeleteUser] = useDeleteUserMutation();
  const [doNextStage] = useNextStageMutation();
  const [hoverToDelId, sethoverToDelId] = React.useState(-1);
  const { enqueueSnackbar } = useSnackbar();

  const WebsiteText = (
    <strong style={{ paddingRight: "3px", paddingLeft: "3px" }}>
      allaboutcreditcards.star-horizon.com
    </strong>
  );

  const delUser = async (id: number) => {
    const response = await doDeleteUser({ variables: { deleteUserId: id } });

    if (response.data && response.data.deleteUser) {
      getClassQuery.refetch();
      getStudentsQuery.refetch();
      enqueueSnackbar("Successfully removed user", { variant: "success" });
    } else {
      enqueueSnackbar("Error removing user", { variant: "error" });
    }
  };

  const StudentName = ({ name, id }: { name: string; id: number }) => {
    var brightness = "100";
    if (hoverToDelId === id) {
      brightness = "30";
    }
    return (
      <Grid item xs={4} md={3}>
        <Box
          sx={{ position: "relative", cursor: "pointer" }}
          onMouseOver={(event) => {
            event.stopPropagation();
            sethoverToDelId(id);
          }}
          onMouseLeave={() => sethoverToDelId(-1)}
          onClick={() => delUser(id)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "primary.dark",
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              border: "1px solid #444444",
              filter: `brightness(${brightness}%)`,
            }}
          >
            <Typography
              noWrap
              variant='h5'
              color='primary.contrastText'
              sx={{ padding: "20px 20px 20px 20px" }}
            >
              {name}
            </Typography>
          </Box>
          {hoverToDelId === id ? (
            <DeleteIcon
              sx={{
                fontSize: 40,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <></>
          )}
        </Box>
      </Grid>
    );
  };

  const RenderStudents = () => {
    if (getStudentsQuery.data === undefined) return [];
    const cards: JSX.Element[] = [];
    getStudentsQuery.data.getClass.students?.forEach((s, i) => {
      cards.push(<StudentName name={s.username} id={s.id} key={s.username} />);
    });
    return cards;
  };

  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        minHeight: "Calc(100vh - 64px);",
      }}
    >
      {getClassQuery && getClassQuery.data ? (
        <>
          <Container onMouseEnter={() => sethoverToDelId(-1)}>
            <Stack direction='column' spacing={8} alignItems='center' sx={{ pt: 10, pb: 5 }}>
              <Grid container alignItems='center'>
                <Grid item xs={2}>
                  <Stack direction='column' alignItems='center' sx={{ marginTop: "5px" }}>
                    <Stack direction='row' alignItems='flex-end'>
                      <Typography variant='h2'>
                        {getClassQuery.data.getClass.students?.length}
                      </Typography>
                      <Typography variant='h5' sx={{ marginLeft: "5px", marginBottom: "7px" }}>
                        /{getClassQuery.data.getClass.nGroups}
                      </Typography>
                    </Stack>
                    <Typography variant='h5'>Groups</Typography>
                  </Stack>
                </Grid>
                <Grid item xs />
                <Grid item xs='auto'>
                  <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        color: "secondary.contrastText",
                        p: "20px",
                        borderRadius: "10px",
                        position: "sticky",
                        width: "min-content",
                      }}
                    >
                      <QRCode
                        value={
                          "https://allaboutcreditcards.star-horizon.com/?i=" +
                          getClassQuery.data.getClass.code
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "secondary.main",
                        color: "secondary.contrastText",
                        p: "20px 50px 20px 50px",
                        borderRadius: "10px",
                        position: "sticky",
                        top: "50px",
                        width: "auto",
                      }}
                    >
                      <Stack direction='column' spacing={0} alignItems='center'>
                        <Typography variant='h5'>Or join at {WebsiteText}</Typography>
                        <Typography variant='h1'>{getClassQuery.data.getClass.code}</Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs />
                <Grid item xs={2}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", height: "100%", marginTop: "5px" }}
                  >
                    <Button
                      variant='contained'
                      color='secondary'
                      size='large'
                      endIcon={<ArrowForwardIosIcon />}
                      onClick={async () => {
                        const response = await doNextStage({
                          variables: { nextStageId: classId },
                        });
                        if (response.data && response.data.nextStage) {
                          enqueueSnackbar("Successfully started game", {
                            variant: "success",
                          });
                        } else {
                          enqueueSnackbar("Error starting game", {
                            variant: "error",
                          });
                        }
                        getGameStage.refetch();
                      }}
                    >
                      Start
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{ width: "70%", height: "1px", marginTop: "20px", backgroundColor: "#eeeeee" }}
              />
              <Grid container spacing={2}>
                {RenderStudents()}
              </Grid>
            </Stack>
          </Container>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100vw",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      )}
    </Box>
  );
}
