import React from "react";
import { useImReadyMutation, useSelectWatchMutation } from "../../generated/graphql";
import { motion } from "framer-motion";
import { Box, Container, Typography, Stack, Grid, Button } from "@mui/material";
import { useSnackbar } from "notistack";

export default function S10January() {
  const [imReady] = useImReadyMutation();
  const year = new Date().getFullYear();
  const [selectSuccess, setselectSuccess] = React.useState(false);
  const [selectedOption, setselectedOption] = React.useState(0);
  const [selectWatch] = useSelectWatchMutation();

  const { enqueueSnackbar } = useSnackbar();

  if (selectSuccess) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Container
          sx={{
            paddingTop: "20px",
            paddingBottom: "55px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant='h4'>Selection Made!</Typography>
        </Container>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Container
        sx={{
          paddingTop: "20px",
          paddingBottom: "55px",
          overflow: "scroll",
          maxHeight: "100%",
          textAlign: "center",
        }}
      >
        <Stack direction='column' spacing={2} alignItems='center' sx={{ marginX: "15px" }}>
          <Typography variant='h2' align='center'>
            January {year + 1}
          </Typography>
          <motion.div
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <Typography variant='h5'>
              Sam has his regular monthly expenses ($1,700) to pay for this month.
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 2 }}
          >
            <Typography variant='h3' align='center'>
              iWatch!
            </Typography>
          </motion.div>
          <motion.img
            src='/game/10iWatch.png'
            style={{ width: "100%", filter: "drop-shadow(5px 5px 5px #aaa)" }}
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 2 }}
          />
          <motion.div
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 2.2 }}
          >
            <Typography variant='h5'>He is interested to buy the latest iWatch!</Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 4 }}
          >
            <Typography variant='h5'>
              Unfortunately, it is only available on the Apple USA website, and the price is USD
              700.
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 5.5 }}
          >
            <Typography variant='h5'>
              His credit card has an exchange rate of 1 USD = 1.37 SGD.
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 5.5 }}
            style={{ width: "100%", paddingBottom: "80px" }}
          >
            <Typography variant='h4' sx={{ paddingBottom: "10px" }}>
              Buy the watch?
            </Typography>
            {selectedOption === 2 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: Number(selectedOption === 2) }}
              >
                <Typography
                  variant='h5'
                  align='center'
                  fontWeight='500'
                  color='error.main'
                  sx={{ paddingBottom: "20px", paddingTop: "5px" }}
                >
                  Are you sure? Your girlfriend says you will look cool with it!
                </Typography>
              </motion.div>
            )}
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  animate={{ scale: 1 + Number(selectedOption === 1) * 0.1 }}
                  style={{
                    width: "75%",
                    aspectRatio: "1",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "success.main",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: Number(selectedOption === 1) * 10,
                    }}
                    onClick={() => {
                      setselectedOption(1);
                    }}
                  >
                    <Typography variant='h3'>Yes!</Typography>
                  </Box>
                </motion.div>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  animate={{ scale: 1 + Number(selectedOption === 2) * 0.1 }}
                  style={{
                    width: "75%",
                    aspectRatio: "1",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "error.main",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: Number(selectedOption === 2) * 10,
                    }}
                    onClick={() => {
                      setselectedOption(2);
                    }}
                  >
                    <Typography variant='h3'>No</Typography>
                  </Box>
                </motion.div>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "90%", marginBottom: "75px" }}>
                <Button
                  variant='contained'
                  fullWidth
                  sx={{ boxShadow: 3 }}
                  disabled={selectedOption === 0}
                  onClick={async () => {
                    const response = await selectWatch({
                      variables: { selectWatch: selectedOption === 1 },
                    });
                    if (response.data && response.data.selectWatch) {
                      setselectSuccess(true);
                      imReady();
                    } else {
                      enqueueSnackbar("Error!", { variant: "error" });
                    }
                  }}
                >
                  Confirm Selection
                </Button>
              </Box>
            </Box>
          </motion.div>
        </Stack>
      </Container>
    </Box>
  );
}
