import React from "react";
import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Button,
  Typography,
  Fab,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  getCakeOne,
  getCakeTwo,
  getCardOne,
  getCardTwo,
  getDinnerOne,
  getDinnerTwo,
} from "../labelText";
import SendIcon from "@mui/icons-material/Send";
import {
  EditLabelTextMutation,
  useCheckInvalidationMutation,
  useEditLabelTextMutation,
  useInvalidateMutation,
  useSignUrlMutation,
} from "../generated/graphql";
import { FetchResult } from "@apollo/client/link/core/types";
import { useSnackbar } from "notistack";

interface Labels {
  cardOne: string;
  cardTwo: string;
  cakeOne: string;
  cakeTwo: string;
  dinnerOne: string;
  dinnerTwo: string;
}

interface Files {
  cardOne: File | undefined;
  cardTwo: File | undefined;
  bankOne: File | undefined;
  bankTwo: File | undefined;
  cakeOne: File | undefined;
  cakeTwo: File | undefined;
  dinnerOne: File | undefined;
  dinnerTwo: File | undefined;
}

interface FileUrls {
  cardOne: string;
  cardTwo: string;
  bankOne: string;
  bankTwo: string;
  cakeOne: string;
  cakeTwo: string;
  dinnerOne: string;
  dinnerTwo: string;
}

interface FilesUploaded {
  cardOne: boolean;
  cardTwo: boolean;
  bankOne: boolean;
  bankTwo: boolean;
  cakeOne: boolean;
  cakeTwo: boolean;
  dinnerOne: boolean;
  dinnerTwo: boolean;
}

interface fileNamesInterface {
  cardOne: string;
  cardTwo: string;
  bankOne: string;
  bankTwo: string;
  cakeOne: string;
  cakeTwo: string;
  dinnerOne: string;
  dinnerTwo: string;
}

const fileNames: fileNamesInterface = {
  cardOne: "2cardOne.png",
  cardTwo: "2cardTwo.png",
  bankOne: "2logoOne.png",
  bankTwo: "2logoTwo.png",
  cakeOne: "6cakeOne.png",
  cakeTwo: "6cakeTwo.png",
  dinnerOne: "7dinnerOne.png",
  dinnerTwo: "7dinnerTwo.png",
};

const Input = styled("input")({
  display: "none",
});

export default function AdminGameManage() {
  const [showSaveDialog, setShowSaveDialog] = React.useState(false);

  const [signUrl] = useSignUrlMutation();
  const [invalidate] = useInvalidateMutation();
  const [checkInvalidations] = useCheckInvalidationMutation();

  const handleSaveChanges = async () => {
    setShowSaveDialog(true);
    for (const key in files) {
      const fKey = key as keyof Files;
      if (files[fKey] !== undefined) {
        const fnKey = key as keyof typeof fileNames;

        const response = await signUrl({
          variables: { fileName: fileNames[fnKey], fileType: "png" },
        });

        if (response.data) {
          fetch(response.data.signUrl, { method: "PUT", body: files[fKey] }).then(() => {
            setFiles({ ...files, [fKey]: undefined });
          });
        }
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 10000));
    await invalidate();

    const checkReady = async () => {
      const response = await checkInvalidations();
      if (response.data && response.data.checkInvalidation) {
        setFilesUploaded({
          cardOne: false,
          cardTwo: false,
          bankOne: false,
          bankTwo: false,
          cakeOne: false,
          cakeTwo: false,
          dinnerOne: false,
          dinnerTwo: false,
        });
        setShowSaveDialog(false);
      } else {
        setTimeout(checkReady, 8000);
      }
    };

    setTimeout(checkReady, 8000);
  };

  const [labels, setLabels] = React.useState<Labels>({
    cardOne: getCardOne(),
    cardTwo: getCardTwo(),
    cakeOne: getCakeOne(),
    cakeTwo: getCakeTwo(),
    dinnerOne: getDinnerOne(),
    dinnerTwo: getDinnerTwo(),
  });

  const [fileUrls, setFileUrls] = React.useState<FileUrls>({
    cardOne: "/game/2cardOne.png",
    cardTwo: "/game/2cardTwo.png",
    bankOne: "/game/2logoOne.png",
    bankTwo: "/game/2logoTwo.png",
    cakeOne: "/game/6cakeOne.png",
    cakeTwo: "/game/6cakeTwo.png",
    dinnerOne: "/game/7dinnerOne.png",
    dinnerTwo: "/game/7dinnerTwo.png",
  });

  const [files, setFiles] = React.useState<Files>({
    cardOne: undefined,
    cardTwo: undefined,
    bankOne: undefined,
    bankTwo: undefined,
    cakeOne: undefined,
    cakeTwo: undefined,
    dinnerOne: undefined,
    dinnerTwo: undefined,
  });

  const [filesUploaded, setFilesUploaded] = React.useState<FilesUploaded>({
    cardOne: false,
    cardTwo: false,
    bankOne: false,
    bankTwo: false,
    cakeOne: false,
    cakeTwo: false,
    dinnerOne: false,
    dinnerTwo: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [editLabelText] = useEditLabelTextMutation();

  const submitLabel = async (fieldId: number) => {
    let response: FetchResult<EditLabelTextMutation, Record<string, any>, Record<string, any>> = {};
    switch (fieldId) {
      case 0:
        response = await editLabelText({ variables: { fieldId: fieldId, text: labels.cardOne } });
        break;
      case 1:
        response = await editLabelText({ variables: { fieldId: fieldId, text: labels.cardTwo } });
        break;
      case 2:
        response = await editLabelText({ variables: { fieldId: fieldId, text: labels.cakeOne } });
        break;
      case 3:
        response = await editLabelText({ variables: { fieldId: fieldId, text: labels.cakeTwo } });
        break;
      case 4:
        response = await editLabelText({ variables: { fieldId: fieldId, text: labels.dinnerOne } });
        break;
      case 5:
        response = await editLabelText({ variables: { fieldId: fieldId, text: labels.dinnerTwo } });
        break;
    }

    if (response.data && response.data.editLabelText) {
      enqueueSnackbar("Edit Success!", { variant: "success" });
    } else {
      enqueueSnackbar("Error editing!", { variant: "error" });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fieldId: number) => {
    if (event.target.files && event.target.files[0]) {
      const newFile = event.target.files[0];
      switch (fieldId) {
        case 0:
          setFiles({ ...files, cardOne: newFile });
          setFileUrls({ ...fileUrls, cardOne: URL.createObjectURL(newFile) });
          setFilesUploaded({ ...filesUploaded, cardOne: true });
          break;
        case 1:
          setFiles({ ...files, cardTwo: newFile });
          setFileUrls({ ...fileUrls, cardTwo: URL.createObjectURL(newFile) });
          setFilesUploaded({ ...filesUploaded, cardTwo: true });
          break;
        case 2:
          setFiles({ ...files, bankOne: newFile });
          setFileUrls({ ...fileUrls, bankOne: URL.createObjectURL(newFile) });
          setFilesUploaded({ ...filesUploaded, bankOne: true });
          break;
        case 3:
          setFiles({ ...files, bankTwo: newFile });
          setFileUrls({ ...fileUrls, bankTwo: URL.createObjectURL(newFile) });
          setFilesUploaded({ ...filesUploaded, bankTwo: true });
          break;
        case 4:
          setFiles({ ...files, cakeOne: newFile });
          setFileUrls({ ...fileUrls, cakeOne: URL.createObjectURL(newFile) });
          setFilesUploaded({ ...filesUploaded, cakeOne: true });
          break;
        case 5:
          setFiles({ ...files, cakeTwo: newFile });
          setFileUrls({ ...fileUrls, cakeTwo: URL.createObjectURL(newFile) });
          setFilesUploaded({ ...filesUploaded, cakeTwo: true });
          break;
        case 6:
          setFiles({ ...files, dinnerOne: newFile });
          setFileUrls({ ...fileUrls, dinnerOne: URL.createObjectURL(newFile) });
          setFilesUploaded({ ...filesUploaded, dinnerOne: true });
          break;
        case 7:
          setFiles({ ...files, dinnerTwo: newFile });
          setFileUrls({ ...fileUrls, dinnerTwo: URL.createObjectURL(newFile) });
          setFilesUploaded({ ...filesUploaded, dinnerTwo: true });
          break;
      }
    }
  };

  return (
    <>
      <Container sx={{ paddingTop: "20px", paddingBottom: "55px" }}>
        <Typography variant='h3' align='center'>
          Edit Game Assets
        </Typography>
        <Stack direction='column' sx={{ marginY: "20px" }} spacing={8}>
          <Box
            sx={{
              border: "1px solid",
              borderRadius: "10px",
              borderColor: "primary.main",
              paddingX: "20px",
              paddingY: "10px",
            }}
          >
            <Typography variant='h4'>Credit Cards</Typography>
            <Grid container spacing={4} sx={{ paddingY: "15px" }}>
              <Grid item xs={6}>
                <Box sx={{ height: "100%" }}>
                  <Stack direction='column' spacing={2}>
                    <FormControl variant='outlined'>
                      <InputLabel htmlFor='cardOneText'>Card One Name</InputLabel>
                      <OutlinedInput
                        id='cardOneText'
                        value={labels.cardOne}
                        onChange={(elem) => setLabels({ ...labels, cardOne: elem.target.value })}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='confirm'
                              onClick={() => submitLabel(0)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge='end'
                            >
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Card One Name'
                      />
                    </FormControl>
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid",
                        borderRadius: "10px",
                        borderColor: "primary.main",
                      }}
                    >
                      <Box
                        sx={{
                          marginX: "20px",
                          width: "fill-available",
                          display: "flex",
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Stack direction='column' sx={{ width: "100%", display: "flex" }}>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{ paddingBottom: "10px" }}
                          >
                            <Typography variant='h6'>Card One Image</Typography>
                            <label htmlFor='cardOneImg'>
                              <Input
                                accept='image/png'
                                id='cardOneImg'
                                type='file'
                                onChange={(event) => handleFileChange(event, 0)}
                              />
                              <Button variant='outlined' component='span'>
                                Change Image
                              </Button>
                            </label>
                          </Stack>
                          <img
                            src={fileUrls.cardOne}
                            alt='card 1'
                            style={{ width: "80%", alignSelf: "center" }}
                          />
                        </Stack>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid",
                        borderRadius: "10px",
                        borderColor: "primary.main",
                      }}
                    >
                      <Box
                        sx={{
                          marginX: "20px",
                          width: "fill-available",
                          display: "flex",
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Stack direction='column' sx={{ width: "100%", display: "flex" }}>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{ paddingBottom: "10px" }}
                          >
                            <Typography variant='h6'>Bank One Image</Typography>
                            <label htmlFor='bankOneImg'>
                              <Input
                                accept='image/png'
                                id='bankOneImg'
                                type='file'
                                onChange={(event) => handleFileChange(event, 2)}
                              />
                              <Button variant='outlined' component='span'>
                                Change Image
                              </Button>
                            </label>
                          </Stack>
                          <img
                            src={fileUrls.bankOne}
                            alt='card 1'
                            style={{ width: "80%", alignSelf: "center" }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box sx={{ height: "100%" }}>
                  <Stack direction='column' spacing={2}>
                    <FormControl variant='outlined'>
                      <InputLabel htmlFor='cardTwoText'>Card Two Name</InputLabel>
                      <OutlinedInput
                        id='cardTwoText'
                        value={labels.cardTwo}
                        onChange={(elem) => setLabels({ ...labels, cardTwo: elem.target.value })}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='confirm'
                              onClick={() => submitLabel(1)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge='end'
                            >
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Card Two Name'
                      />
                    </FormControl>
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid",
                        borderRadius: "10px",
                        borderColor: "primary.main",
                      }}
                    >
                      <Box
                        sx={{
                          marginX: "20px",
                          width: "fill-available",
                          display: "flex",
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Stack direction='column' sx={{ width: "100%", display: "flex" }}>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{ paddingBottom: "10px" }}
                          >
                            <Typography variant='h6'>Card Two Image</Typography>
                            <label htmlFor='cardTwoImg'>
                              <Input
                                accept='image/png'
                                id='cardTwoImg'
                                type='file'
                                onChange={(event) => handleFileChange(event, 1)}
                              />
                              <Button variant='outlined' component='span'>
                                Change Image
                              </Button>
                            </label>
                          </Stack>
                          <img
                            src={fileUrls.cardTwo}
                            alt='card 2'
                            style={{ width: "80%", alignSelf: "center" }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid",
                        borderRadius: "10px",
                        borderColor: "primary.main",
                      }}
                    >
                      <Box
                        sx={{
                          marginX: "20px",
                          width: "fill-available",
                          display: "flex",
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Stack direction='column' sx={{ width: "100%", display: "flex" }}>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{ paddingBottom: "10px" }}
                          >
                            <Typography variant='h6'>Bank Two Image</Typography>
                            <label htmlFor='bankTwoImg'>
                              <Input
                                accept='image/png'
                                id='bankTwoImg'
                                type='file'
                                onChange={(event) => handleFileChange(event, 3)}
                              />
                              <Button variant='outlined' component='span'>
                                Change Image
                              </Button>
                            </label>
                          </Stack>
                          <img
                            src={fileUrls.bankTwo}
                            alt='bank 2'
                            style={{ width: "80%", alignSelf: "center" }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/*TWOOO
        














        
        */}

          <Box
            sx={{
              border: "1px solid",
              borderRadius: "10px",
              borderColor: "primary.main",
              paddingX: "20px",
              paddingY: "10px",
            }}
          >
            <Typography variant='h4'>Cakes</Typography>
            <Grid container spacing={4} sx={{ paddingY: "15px" }}>
              <Grid item xs={6}>
                <Box sx={{ height: "100%" }}>
                  <Stack direction='column' spacing={2}>
                    <FormControl variant='outlined'>
                      <InputLabel htmlFor='cakeOneText'>Cake One Brand</InputLabel>
                      <OutlinedInput
                        id='cakeOneText'
                        value={labels.cakeOne}
                        onChange={(elem) => setLabels({ ...labels, cakeOne: elem.target.value })}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='confirm'
                              onClick={() => submitLabel(2)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge='end'
                            >
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Cake One Brand'
                      />
                    </FormControl>
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid",
                        borderRadius: "10px",
                        borderColor: "primary.main",
                      }}
                    >
                      <Box
                        sx={{
                          marginX: "20px",
                          width: "fill-available",
                          display: "flex",
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Stack direction='column' sx={{ width: "100%", display: "flex" }}>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{ paddingBottom: "10px" }}
                          >
                            <Typography variant='h6'>Cake One Image</Typography>
                            <label htmlFor='cakeOneImg'>
                              <Input
                                accept='image/png'
                                id='cakeOneImg'
                                type='file'
                                onChange={(event) => handleFileChange(event, 4)}
                              />
                              <Button variant='outlined' component='span'>
                                Change Image
                              </Button>
                            </label>
                          </Stack>
                          <img
                            src={fileUrls.cakeOne}
                            alt='card 1'
                            style={{ width: "80%", alignSelf: "center" }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box sx={{ height: "100%" }}>
                  <Stack direction='column' spacing={2}>
                    <FormControl variant='outlined'>
                      <InputLabel htmlFor='cakeTwoText'>Cake Two Brand</InputLabel>
                      <OutlinedInput
                        id='cakeTwoText'
                        value={labels.cakeTwo}
                        onChange={(elem) => setLabels({ ...labels, cakeTwo: elem.target.value })}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='confirm'
                              onClick={() => submitLabel(3)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge='end'
                            >
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Cake Two Brand'
                      />
                    </FormControl>
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid",
                        borderRadius: "10px",
                        borderColor: "primary.main",
                      }}
                    >
                      <Box
                        sx={{
                          marginX: "20px",
                          width: "fill-available",
                          display: "flex",
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Stack direction='column' sx={{ width: "100%", display: "flex" }}>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{ paddingBottom: "10px" }}
                          >
                            <Typography variant='h6'>Cake Two Image</Typography>
                            <label htmlFor='cakeTwoImg'>
                              <Input
                                accept='image/png'
                                id='cakeTwoImg'
                                type='file'
                                onChange={(event) => handleFileChange(event, 5)}
                              />
                              <Button variant='outlined' component='span'>
                                Change Image
                              </Button>
                            </label>
                          </Stack>
                          <img
                            src={fileUrls.cakeTwo}
                            alt='card 2'
                            style={{ width: "80%", alignSelf: "center" }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/*Three
        














        
        */}

          <Box
            sx={{
              border: "1px solid",
              borderRadius: "10px",
              borderColor: "primary.main",
              paddingX: "20px",
              paddingY: "10px",
            }}
          >
            <Typography variant='h4'>Dinners</Typography>
            <Grid container spacing={4} sx={{ paddingY: "15px" }}>
              <Grid item xs={6}>
                <Box sx={{ height: "100%" }}>
                  <Stack direction='column' spacing={2}>
                    <FormControl variant='outlined'>
                      <InputLabel htmlFor='dinnerOneText'>Dinner One Name</InputLabel>
                      <OutlinedInput
                        id='dinnerOneText'
                        value={labels.dinnerOne}
                        onChange={(elem) => setLabels({ ...labels, dinnerOne: elem.target.value })}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='confirm'
                              onClick={() => submitLabel(4)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge='end'
                            >
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='dinner One Name'
                      />
                    </FormControl>
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid",
                        borderRadius: "10px",
                        borderColor: "primary.main",
                      }}
                    >
                      <Box
                        sx={{
                          marginX: "20px",
                          width: "fill-available",
                          display: "flex",
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Stack direction='column' sx={{ width: "100%", display: "flex" }}>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{ paddingBottom: "10px" }}
                          >
                            <Typography variant='h6'>Dinner One Logo</Typography>
                            <label htmlFor='dinnerOneImg'>
                              <Input
                                accept='image/png'
                                id='dinnerOneImg'
                                type='file'
                                onChange={(event) => handleFileChange(event, 6)}
                              />
                              <Button variant='outlined' component='span'>
                                Change Image
                              </Button>
                            </label>
                          </Stack>
                          <img
                            src={fileUrls.dinnerOne}
                            alt='card 1'
                            style={{ width: "80%", alignSelf: "center" }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box sx={{ height: "100%" }}>
                  <Stack direction='column' spacing={2}>
                    <FormControl variant='outlined'>
                      <InputLabel htmlFor='dinnerTwoName'>Dinner Two Name</InputLabel>
                      <OutlinedInput
                        id='dinnerTwoName'
                        value={labels.dinnerTwo}
                        onChange={(elem) => setLabels({ ...labels, dinnerTwo: elem.target.value })}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='confirm'
                              onClick={() => submitLabel(5)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge='end'
                            >
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Dinner Two Name'
                      />
                    </FormControl>
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid",
                        borderRadius: "10px",
                        borderColor: "primary.main",
                      }}
                    >
                      <Box
                        sx={{
                          marginX: "20px",
                          width: "fill-available",
                          display: "flex",
                          paddingTop: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Stack direction='column' sx={{ width: "100%", display: "flex" }}>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{ paddingBottom: "10px" }}
                          >
                            <Typography variant='h6'>Dinner Two Logo</Typography>
                            <label htmlFor='dinnerTwoImg'>
                              <Input
                                accept='image/png'
                                id='dinnerTwoImg'
                                type='file'
                                onChange={(event) => handleFileChange(event, 7)}
                              />
                              <Button variant='outlined' component='span'>
                                Change Image
                              </Button>
                            </label>
                          </Stack>
                          <img
                            src={fileUrls.dinnerTwo}
                            alt='card 2'
                            style={{ width: "80%", alignSelf: "center" }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* End */}
        </Stack>
      </Container>
      {Object.values(filesUploaded).includes(true) && (
        <Box sx={{ position: "fixed", bottom: "30px", right: "30px" }}>
          <Stack direction='column'>
            <Alert variant='filled' severity='warning' sx={{ borderRadius: "15px" }}>
              Press button to save changed images
            </Alert>
            <Box sx={{ display: "flex", justifyContent: "end", paddingTop: "15px" }}>
              <Fab
                variant='extended'
                color='primary'
                sx={{ justifySelf: "right" }}
                onClick={handleSaveChanges}
              >
                Save Image Changes <SendIcon sx={{ paddingLeft: "8px" }} />
              </Fab>
            </Box>
          </Stack>
        </Box>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSaveDialog}
      >
        <Stack direction='column' spacing={2} justifyContent='center' alignItems='center'>
          <CircularProgress color='inherit' />
          <Alert variant='filled' severity='info' sx={{ borderRadius: "15px" }}>
            This will take approximately 1 minute.
          </Alert>
        </Stack>
      </Backdrop>
    </>
  );
}
