import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useRegisterMutation, useUsersQuery } from "../generated/graphql";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";
import SplitButton from "../components/SplitButton";
import { ManageUsersTable } from "../components/ManageUsersTable";

export default function AdminUsersManage() {
  const { enqueueSnackbar } = useSnackbar();
  const [addUserOpen, setaddUserOpen] = useState(false);
  const { data, refetch } = useUsersQuery({ errorPolicy: "all", pollInterval: 5000 });

  //add user form
  const [doRegister] = useRegisterMutation({ errorPolicy: "all" });

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Username Required"),
    password: Yup.string().min(2, "Password too short!").required("Password Required"),
    permLevel: Yup.number()
      .required("Account type is required")
      .min(1, "Account type must be either admin or trainer")
      .max(2, "Account type must be either admin or trainer"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      permLevel: 1,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, actions) => {
      try {
        const response = await doRegister({
          variables: {
            username: values.username,
            password: values.password,
            permLevel: values.permLevel,
          },
        });
        if (response.data && response.data.register) {
          refetch();
          enqueueSnackbar("Successfully added user", { variant: "success" });
          if (selectedIndex === 0) {
            setaddUserOpen(false);
            actions.resetForm();
          }
          if (selectedIndex === 1) {
            actions.resetForm();
          }
        } else {
          enqueueSnackbar("Error adding user", { variant: "error" });
        }
        actions.setSubmitting(false);
      } catch (err) {
        actions.resetForm();
      }
    },
  });

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleAddUserClose = () => setaddUserOpen(false);

  return (
    <>
      {data ? (
        <ManageUsersTable setaddUserOpen={setaddUserOpen} data={data} refetch={refetch} />
      ) : (
        <></>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={addUserOpen}
        onClick={handleAddUserClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            maxWidth='sm'
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Grid container>
              <Grid item xs></Grid>
              <Grid item xs={12} md={8}>
                <Card sx={{ marginBottom: "40px", borderRadius: "15px" }}>
                  <CardContent>
                    <Typography
                      variant='h4'
                      sx={{ textAlign: "center", paddingY: "10px" }}
                      gutterBottom
                    >
                      Create User
                    </Typography>
                    <form>
                      <Grid
                        container
                        spacing={3}
                        sx={{ paddingRight: "10px", paddingLeft: "10px" }}
                      >
                        <Grid item xs={12}>
                          <TextField
                            autoComplete='off'
                            required
                            fullWidth
                            id='username'
                            name='username'
                            label='Username'
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            autoComplete='off'
                            required
                            fullWidth
                            id='password'
                            name='password'
                            type='password'
                            label='Password'
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id='permLevelId'>Account Type</InputLabel>
                            <Select
                              required
                              labelId='permLevelId'
                              id='permLevel'
                              name='permLevel'
                              value={formik.values.permLevel}
                              label='Account Type'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched.permLevel && Boolean(formik.errors.permLevel)}
                            >
                              <MenuItem value={1}>Trainer</MenuItem>
                              <MenuItem value={2}>Admin</MenuItem>
                            </Select>
                            <FormHelperText>
                              {formik.touched.permLevel && formik.errors.permLevel}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
                          <SplitButton
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            submitButtonPressed={() => formik.handleSubmit()}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs></Grid>
            </Grid>
          </Container>
        </Box>
      </Backdrop>
    </>
  );
}
